//@ts-check
import React, { useCallback, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import MuiPhoneNumber from 'material-ui-phone-number'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid
} from '@material-ui/core'
import { dateTimeToString } from 'utils/date'
import LoadingButton from '../../elements/LoadingButton/LoadingButton'
import AccessTypeSelector from '../../elements/AccessTypeSelector/AccessTypeSelector'
import SubjectSelector from '../../elements/SubjectSelector/SubjectSelector'
import AcademicBackgroundSelect from '../../elements/AcademicBackgroundSelect/AcademicBackgroundSelect'
import useFormManager from '../../hooks/common/useFormManager'
import DefaultSelect from '../../shared/DefaultSelect'
import { AccessTypes } from '../../shared/constants'
import './StudentForm.css'

const STUDENT_REQUIRED_FIELDS = [
  'name',
  'email',
  'dni',
  'phoneNumber.cleanedPhone',
  'dateOfBirth'
]
const TRIAL_REQUIRED_FIELDS = ['name', 'email']
const INTERNAL_REQUIRED_FIELDS = ['name', 'email']
function StudentForm({
  mainTitle = 'Nuevo alumno',
  student = null,
  isLoading = false,
  onSubmit,
  isEditMode = false
}) {
  const {
    handleFormChange,
    handlePhoneNumberChange,
    handleDateChange,
    handleCheckChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    clearRequiredFields,
    form
  } = useFormManager(
    [],
    student || {
      name: '',
      email: '',
      accessType: AccessTypes.STUDENT,
      active: true,
      dni: '',
      description: ''
    }
  )
  const handleSubmit = useCallback(() => {
    onSubmit(form)
  }, [form, onSubmit])

  useEffect(() => {
    switch (form.accessType) {
      case AccessTypes.STUDENT: {
        clearRequiredFields(STUDENT_REQUIRED_FIELDS)
        break
      }
      case AccessTypes.TRIAL: {
        clearRequiredFields(TRIAL_REQUIRED_FIELDS)
        break
      }
      case AccessTypes.INTERNAL: {
        clearRequiredFields(INTERNAL_REQUIRED_FIELDS)
        break
      }
      default:
        break
    }
  }, [clearRequiredFields, form.accessType])

  const isStudentType = form.accessType === AccessTypes.STUDENT
  return (
    <Card>
      <CardContent className={getMainClassName(isEditMode, isStudentType)}>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ width: '70%', margin: '0 auto' }}
          spacing={2}
        >
          <Grid style={{ width: '100%' }} item>
            <Card variant='outlined'>
              <CardHeader
                title={mainTitle}
                subheader={
                  form.enrolledUntil && (
                    <span
                      className={
                        new Date() >= new Date(form.enrolledUntil)
                          ? 'enrollment-alert'
                          : ''
                      }
                    >
                      {'Fin matrícula: ' + dateTimeToString(form.enrolledUntil)}
                    </span>
                  )
                }
                action={
                  isEditMode && form.leadId ? (
                    <Link to={'/leads/' + form.leadId} className='p_spacing'>
                      <Button color='primary' size='large'>
                        Ver lead
                      </Button>
                    </Link>
                  ) : (
                    isStudentType && (
                      <p className='p_spacing'>Sin lead asignado</p>
                    )
                  )
                }
              />
              <CardContent>
                <TextField
                  name='name'
                  label='Nombre'
                  value={form.name || ''}
                  onChange={handleFormChange}
                  fullWidth
                  {...getErrorPropsByRequiredField('name')}
                />
                <div className='inner_grid'>
                  <TextField
                    name='email'
                    margin='normal'
                    label='Correo electrónico'
                    value={form.email || ''}
                    onChange={handleFormChange}
                    fullWidth
                    {...getErrorPropsByRequiredField('email')}
                  />
                  {isStudentType && (
                    <>
                      <TextField
                        name='dni'
                        margin='normal'
                        label='DNI'
                        value={form.dni || ''}
                        onChange={handleFormChange}
                        {...getErrorPropsByRequiredField('dni')}
                      />

                      <MuiPhoneNumber
                        label='Teléfono'
                        defaultCountry='es'
                        margin='normal'
                        value={form.phoneNumber?.fullPhoneNumber || ''}
                        // @ts-ignore
                        localization={{ Spain: 'España' }}
                        onChange={(fullPhoneNumber, details) =>
                          handlePhoneNumberChange('phoneNumber', {
                            fullPhoneNumber,
                            ...details
                          })
                        }
                        {...getErrorPropsByRequiredField(
                          'phoneNumber.cleanedPhone'
                        )}
                      />
                      <KeyboardDatePicker
                        label='Fecha de nacimiento'
                        margin='normal'
                        format='dd/MM/yyyy'
                        value={form.dateOfBirth || null}
                        onChange={value =>
                          handleDateChange('dateOfBirth', value)
                        }
                        {...getErrorPropsByRequiredField('dateOfBirth')}
                      />
                      <TextField
                        name='postalCode'
                        margin='normal'
                        label='Código postal'
                        value={form.postalCode || ''}
                        onChange={handleFormChange}
                      />
                    </>
                  )}
                  <AccessTypeSelector
                    name='accessType'
                    value={form.accessType || ''}
                    onChange={handleFormChange}
                    disabled={isEditMode}
                    filterOptions={option => {
                      if (isEditMode) return option
                      return option.value !== AccessTypes.TRIAL
                    }}
                    fullWidth
                  />
                </div>
                {isEditMode && (
                  <TextField
                    name='password' //to manage in back
                    margin='dense'
                    label='Modificar contraseña'
                    value={form.password || ''}
                    onChange={handleFormChange}
                    fullWidth
                  />
                )}
                <TextField
                  name='description'
                  margin='normal'
                  label='Observaciones'
                  value={form.description || ''}
                  onChange={handleFormChange}
                  fullWidth
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='active'
                      checked={!!form.active}
                      onChange={handleCheckChange}
                    />
                  }
                  label='Activo'
                />
              </CardContent>
            </Card>
          </Grid>
          {isStudentType && (
            <>
              <Grid item xs>
                <Card>
                  <CardHeader title='Datos tutor'></CardHeader>
                  <CardContent>
                    <TextField
                      name='firstTutor.name'
                      margin='dense'
                      label='Nombre'
                      value={form.firstTutor?.name || ''}
                      onChange={handleFormChange}
                      fullWidth
                    />
                    <TextField
                      name='firstTutor.email'
                      margin='dense'
                      label='Correo electrónico'
                      value={form.firstTutor?.email || ''}
                      onChange={handleFormChange}
                      fullWidth
                    />
                    <TextField
                      name='firstTutor.dni'
                      margin='dense'
                      label='DNI'
                      value={form.firstTutor?.dni || ''}
                      onChange={handleFormChange}
                      fullWidth
                    />
                    <DefaultSelect
                      name='firstTutor.role'
                      margin='dense'
                      title='Relación con el alumno'
                      options={[
                        { id: 'Madre', name: 'Madre' },
                        { id: 'Padre', name: 'Padre' },
                        { id: 'Familiar', name: 'Familiar' },
                        { id: 'Otro', name: 'Otro' }
                      ]}
                      value={form.firstTutor?.role || ''}
                      onChange={handleFormChange}
                      fullWidth
                    />
                    <MuiPhoneNumber
                      label='Teléfono'
                      defaultCountry='es'
                      margin='dense'
                      value={
                        form.firstTutor?.phoneNumber?.fullPhoneNumber || ''
                      }
                      // @ts-ignore
                      localization={{ Spain: 'España' }}
                      onChange={(fullPhoneNumber, details) =>
                        handlePhoneNumberChange('firstTutor.phoneNumber', {
                          fullPhoneNumber,
                          ...details
                        })
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card>
                  <CardHeader title='Datos tutor' />
                  <CardContent>
                    <TextField
                      name='secondTutor.name'
                      margin='dense'
                      label='Nombre'
                      value={form.secondTutor?.name || ''}
                      onChange={handleFormChange}
                      fullWidth
                    />
                    <TextField
                      name='secondTutor.email'
                      margin='dense'
                      label='Correo electrónico'
                      value={form.secondTutor?.email || ''}
                      onChange={handleFormChange}
                      fullWidth
                    />
                    <TextField
                      name='secondTutor.dni'
                      margin='dense'
                      label='DNI'
                      value={form.secondTutor?.dni || ''}
                      onChange={handleFormChange}
                      fullWidth
                    />
                    <DefaultSelect
                      name='secondTutor.role'
                      margin='dense'
                      title='Relación con el alumno'
                      options={[
                        { id: 'Madre', name: 'Madre' },
                        { id: 'Padre', name: 'Padre' },
                        { id: 'Familiar', name: 'Familiar' },
                        { id: 'Otro', name: 'Otro' }
                      ]}
                      value={form.secondTutor?.role || ''}
                      onChange={handleFormChange}
                      fullWidth
                    />
                    <MuiPhoneNumber
                      label='Teléfono'
                      defaultCountry='es'
                      margin='dense'
                      value={
                        form.secondTutor?.phoneNumber?.fullPhoneNumber || ''
                      }
                      // @ts-ignore
                      localization={{ Spain: 'España' }}
                      onChange={(fullPhoneNumber, details) =>
                        handlePhoneNumberChange('secondTutor.phoneNumber', {
                          fullPhoneNumber,
                          ...details
                        })
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
          {form?.trial && (
            <Grid style={{ width: '100%' }} item>
              <Card variant='outlined'>
                <CardHeader title='Datos de alta' subheader='Solo lectura' />
                <CardContent>
                  <AcademicBackgroundSelect
                    name='trial?.academicBackground'
                    value={form.trial?.academicBackground || ''}
                    fullWidth
                    disabled
                  />
                  <SubjectSelector
                    name='trial.subjectIds'
                    margin='dense'
                    label='Asignaturas seleccionadas'
                    value={form.trial?.subjectIds || []}
                    multiple
                    disabled
                    fullWidth
                  />
                  <TextField
                    margin='normal'
                    name='trial.description'
                    label='Descripción'
                    value={form.trial?.description || ''}
                    disabled
                    fullWidth
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions className='student_card_action'>
        <LoadingButton
          disabled={hasPendingRequiredFields}
          label='Guardar'
          isLoading={isLoading}
          onClick={handleSubmit}
          startIcon={<i className='material-icons'>save</i>}
        />
      </CardActions>
    </Card>
  )
}

export default StudentForm

function getMainClassName(isEditMode, isStudentType) {
  let className = 'main_card_content_student_'
  if (isEditMode) {
    if (!isStudentType) return 'main_card_content_trial'
    className += 'edit'
  } else {
    className += 'create'
  }
  return className
}

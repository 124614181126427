//@ts-check
import React, { useState } from 'react'
import { Grid, Tab, Tabs } from '@material-ui/core'
import SubjectCard from './SubjectCard'
import { SubjectLevels } from '../../../../shared/constants'

const TabPanel = ({ active, children }) => {
  if (!active) return null
  return children
}

function PackSubjects({
  firstTab,
  suplementSubjects,
  selectedSupplementIds,
  onSelectSupplement
}) {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
      >
        <Tab label='Asignaturas' />
        <Tab label='Suplementos' />
      </Tabs>
      <TabPanel active={activeTab === 0}>{firstTab}</TabPanel>
      <TabPanel active={activeTab === 1}>
        {suplementSubjects && (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{
              width: '95%',
              margin: '10px auto',
              cursor: 'pointer'
            }}
            spacing={2}
          >
            {suplementSubjects.map(subject => (
              <SubjectCard
                key={subject.id}
                title={
                  subject.level === SubjectLevels.IDIOMAS && subject.sublevel
                    ? `${subject.name} (${subject.sublevel})`
                    : subject.name
                }
                isSelected={selectedSupplementIds.includes(subject.id)}
                subheader={`${subject.type} (${subject.level})`}
                onSelectSubject={() => onSelectSupplement(subject.id)}
              />
            ))}
          </Grid>
        )}
      </TabPanel>
    </>
  )
}

export default PackSubjects

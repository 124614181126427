import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const options = [
  { value: '--', label: '[No aplicable]' },
  { value: 'Aprobar una asignatura', label: 'Aprobar una asignatura' },
  { value: 'Aprobar un curso escolar', label: 'Aprobar un curso escolar' },
  { value: 'Aprobar selectividad', label: 'Aprobar selectividad' },
  { value: 'Mejorar un idioma', label: 'Mejorar un idioma' },
  { value: 'Mejorar en una asignatura', label: 'Mejorar en una asignatura' },
  {
    value: 'Aprobar un exámen específico',
    label: 'Aprobar un exámen específico'
  },
  { value: 'Otros', label: 'Otros' }
]

function LeadGoalsSelect({
  value = '',
  onChange,
  label = 'Objetivos',
  name,
  fullWidth,
  margin = 'dense'
}) {
  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LeadGoalsSelect

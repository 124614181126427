//@ts-check
import React from 'react'
import { Checkbox } from '@material-ui/core'
import InformationTable from '../../../../components/InformationTable/InformationTable'
import { useSubscriptionManager } from '../context/SubscriptionManagerProvider'
import { SubscriptionConfigTypes } from '../../../../shared/constants'

function PacksTable() {
  const { selectedConfig, selectedSubscriptionConfigRow, handleCheckConfig } =
    useSubscriptionManager()
  const priceDetails =
    selectedConfig.type === SubscriptionConfigTypes.extraHours
      ? []
      : [
          { title: 'Precio/hora', key: 'hourPrice' },
          { title: 'Precio (€)', key: 'calculatedPrice' }
        ]
  return (
    <InformationTable
      details={[
        { title: ' ', key: 'action' },
        // { title: 'Código', key: 'code' },
        { title: 'Nº de horas', key: 'hours' },
        ...priceDetails,
        { title: 'Límite de asignaturas', key: 'subjectsLimit' },
        { title: 'Días disfrute', key: 'daysLimit' }
      ]}
      data={Object.values(selectedConfig?.config ?? []).map(c => ({
        ...c,
        action: (
          <Checkbox
            checked={selectedSubscriptionConfigRow?.code === c.code}
            onClick={() => handleCheckConfig(c)}
          />
        )
      }))}
      size='small'
    />
  )
}

export default PacksTable

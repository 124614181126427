import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { propEq, sortBy } from 'ramda'
import { findSubjectsWithTeacherCount } from 'api/subjects'
import { textFilter } from 'utils/table'
import InformationTable from '../../components/InformationTable/InformationTable'
import SearchInput from '../../elements/SearchInput/SearchInput'
import styles from './ListadoAsignaturas.module.css'

const StatusIcon = ({ active }) => {
  if (active)
    return (
      <i title='Activo' className='material-icons' style={{ color: '#8cc63f' }}>
        done
      </i>
    )
  else
    return (
      <i
        title='Inactivo'
        className='material-icons'
        style={{ color: '#f0513c' }}
      >
        clear
      </i>
    )
}

const ListadoAsignaturas = () => {
  const [search, setSearch] = useState('')
  const [subjects, setSubjects] = useState([])

  const filterSearch = subject => {
    return textFilter({
      object: subject,
      fields: ['name', 'type', 'subtype', 'level', 'sublevel', 'price'],
      search: search
    })
  }

  useEffect(() => {
    findSubjectsWithTeacherCount()
      .then(sortBy(list => !propEq('teacherCount', 0)(list)))
      .then(setSubjects)
      .catch(console.error)
  }, [])

  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          { title: 'Tipo', key: 'type' },
          { title: 'Subtipo', key: 'subtype' },
          { title: 'Nivel', key: 'level' },
          { title: 'Subnivel', key: 'sublevel' },
          { title: 'Precio', key: 'price' },
          { title: 'Nº profes', key: 'teacherCount' },
          { title: 'Activa', key: 'active' }
        ]}
        data={subjects.filter(filterSearch).map(subject => ({
          name: <Link to={`/subjects/${subject.id}`}>{subject.name}</Link>,
          type: subject.type,
          subtype: subject.subtype,
          level: subject.level,
          sublevel: subject.sublevel,
          price: `${subject.price} €/hora`,
          teacherCount: subject.teacherCount,
          active: <StatusIcon active={subject.active} />,
          style: !subject.teacherCount
            ? { background: 'var(--warning-color-30)' }
            : undefined
        }))}
      />
    </div>
  )
}

export default ListadoAsignaturas

import { GET, POST, PUT } from 'api'
import { makeCustomQueryAndProject } from 'utils'

export const findSubjects = (query = {}, project = []) =>
  GET({
    url: makeCustomQueryAndProject({
      query,
      project,
      url: 'admin/subjects/list'
    })
  })
export const findSubjectsWithTeacherCount = () =>
  GET({
    url: 'admin/subjects/subjects-with-teachers-count'
  })

export const createSubject = (data = {}) =>
  POST({
    url: 'admin/subjects/new',
    data
  })

export const updateSubject = ({ subjectId, update }) =>
  PUT({
    url: `admin/subjects/${subjectId}/details`,
    data: { update }
  })

export const getSubjectDetails = ({ subjectId }) =>
  GET({
    url: `admin/subjects/${subjectId}/details`
  })

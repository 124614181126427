//@ts-check
import React, { useEffect, useState } from 'react'
import { Button, Card, CardActions, CardHeader } from '@material-ui/core'
import { isEmpty } from 'ramda'
import { Link, useParams } from 'react-router-dom'
import { getBudget } from 'api/budgets'
import SubscriptionManagerProvider from './context/SubscriptionManagerProvider'
import SubscriptionManager from './SubscriptionManager'

function Index() {
  // @ts-ignore
  const { id } = useParams()
  const [budget, setBudget] = useState()
  const [isFecthing, setIsFetching] = useState(false)
  const hasToFetch = id && /([a-f0-9]{24})/.test(id)
  useEffect(() => {
    if (hasToFetch) {
      setIsFetching(true)
      getBudget(id)
        .then(setBudget)
        .catch(console.error)
        .finally(() => setIsFetching(false))
    }
  }, [hasToFetch, id])
  if (isFecthing) return null
  if (hasToFetch) {
    return budget && !isEmpty(budget) ? (
      <SubscriptionManagerProvider currentConfig={budget}>
        <SubscriptionManager />
      </SubscriptionManagerProvider>
    ) : (
      <Card>
        <CardHeader
          title='Presupuesto no encontrado'
          subheader='Puede haber sido eliminado o no existe'
        />
        <CardActions>
          <Link to='/budgets'>
            <Button variant='contained' color='primary'>
              Volver
            </Button>
          </Link>
        </CardActions>
      </Card>
    )
  }
  return (
    <SubscriptionManagerProvider>
      <SubscriptionManager />
    </SubscriptionManagerProvider>
  )
}

export default Index

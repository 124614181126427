//@ts-check
import {
  assoc,
  countBy,
  groupBy,
  head,
  indexBy,
  isEmpty,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  sortBy,
  takeLast,
  without,
  uniq
} from 'ramda'
import {
  SubjectLevels,
  SubscriptionConfigTypes,
  SubscriptionTypes
} from '../../../../shared/constants'

/**
 * @typedef SubscriptionManagerState
 * @property {string} selectedType
 * @property {string} selectedSubscriptionConfigId
 * @property {boolean} isFetching
 * @property {object} selectedSubscriptionConfigRow
 * @property {object[]} subscriptionConfigs
 * @property {number} activeStep
 * @property {object[]} subjects
 * @property {string[]} selectedSubjectIds
 * @property {string[]} selectedSupplementIds
 * @property {string[]} selectedDiscountIds
 * @property {boolean} accepted
 * @property {string} targetUserId
 * @property {string} targetUserType
 * @property {boolean} paymentWithEnrollment
 * @property {boolean} error
 * @property {boolean} showModal
 */

/**
 * @typedef FetchConfigsRequest
 * @property {'FetchConfigsRequest'} type
 */

/**
 * @typedef FetchConfigsSuccess
 * @property {'FetchConfigsSuccess'} type
 * @property {object[]} payload
 */

/**
 * @typedef FetchConfigsFailed
 * @property {'FetchConfigsFailed'} type
 */
/**
 * @typedef FetchSubjectsRequest
 * @property {'FetchSubjectsRequest'} type
 */

/**
 * @typedef FetchSubjectsSuccess
 * @property {'FetchSubjectsSuccess'} type
 * @property {object[]} payload
 */

/**
 * @typedef FetchSubjectsFailed
 * @property {'FetchSubjectsFailed'} type
 */

/**
 * @typedef SelectedTypeChanged
 * @property {'SelectedTypeChanged'} type
 * @property {string} payload
 */

/**
 * @typedef SelectedSubscriptionConfigChanged
 * @property {'SelectedSubscriptionConfigChanged'} type
 * @property {string} payload
 */
/**
 * @typedef SubscriptionConfigRowChanged
 * @property {'SubscriptionConfigRowChanged'} type
 * @property {object} payload
 */
/**
 * @typedef SelectedSubjectsChanged
 * @property {'SelectedSubjectsChanged'} type
 * @property {string} payload
 */
/**
 * @typedef ActiveStepChanged
 * @property {'ActiveStepChanged'} type
 * @property {number} payload
 */
/**
 * @typedef SupplementIdsChanged
 * @property {'SupplementIdsChanged'} type
 * @property {string} payload
 */
/**
 * @typedef ToggleShowModal
 * @property {'ToggleShowModal'} type
 */
/**
 * @typedef SelectedDiscountChanged
 * @property {'SelectedDiscountChanged'} type
 * @property {string[]} payload
 */

/**
 * @typedef {FetchConfigsRequest | FetchConfigsSuccess | FetchConfigsFailed |
 * FetchSubjectsRequest | FetchSubjectsSuccess | FetchSubjectsFailed |
 * SelectedTypeChanged | SelectedSubscriptionConfigChanged | SubscriptionConfigRowChanged |
 * SelectedSubjectsChanged | ActiveStepChanged | SupplementIdsChanged |
 * ToggleShowModal | SelectedDiscountChanged }
 *  Actions */

/**
 *
 * @param {SubscriptionManagerState} state
 * @param {Actions} action
 * @returns {SubscriptionManagerState}
 */
export default function subscriptionsReducer(state, action) {
  switch (action.type) {
    case 'FetchConfigsRequest':
      return {
        ...state,
        isFetching: true
      }

    case 'FetchConfigsSuccess':
      return {
        ...state,
        isFetching: false,
        subscriptionConfigs: action.payload
      }
    case 'FetchConfigsFailed':
      return {
        ...state,
        isFetching: false,
        error: true
      }
    case 'FetchSubjectsRequest':
      return {
        ...state,
        isFetching: true
      }

    case 'FetchSubjectsSuccess':
      return {
        ...state,
        isFetching: false,
        subjects: sortBy(prop('name'), action.payload)
      }
    case 'FetchSubjectsFailed':
      return {
        ...state,
        isFetching: false,
        error: true
      }
    case 'SelectedTypeChanged':
      return {
        ...state,
        selectedType: action.payload,
        selectedSubscriptionConfigId: '',
        selectedSubscriptionConfigRow: null,
        selectedSubjectIds: [],
        selectedSupplementIds: [],
        selectedDiscountIds: [],
        accepted: false,
        targetUserId: '',
        targetUserType: '',
        paymentWithEnrollment: false
      }
    case 'SelectedSubscriptionConfigChanged': {
      const selectedConfig = state.subscriptionConfigs.find(
        propEq('id', action.payload)
      )
      const isAgoraRate =
        selectedConfig && selectedConfig.type === SubscriptionConfigTypes.agora
      return {
        ...state,
        selectedSubscriptionConfigId: action.payload,
        selectedSubscriptionConfigRow: isAgoraRate
          ? selectedConfig.config.uniqConfig
          : null,
        selectedSubjectIds: [],
        selectedSupplementIds: [],
        selectedDiscountIds: [],
        accepted: false,
        targetUserId: '',
        targetUserType: '',
        paymentWithEnrollment: false
      }
    }

    case 'SubscriptionConfigRowChanged':
      return {
        ...state,
        selectedSubscriptionConfigRow:
          state.selectedSubscriptionConfigRow &&
          state.selectedSubscriptionConfigRow.code === action.payload.code
            ? null
            : action.payload,
        selectedSubjectIds: [],
        selectedSupplementIds: []
      }
    case 'SelectedSubjectsChanged': {
      const subjectId = action.payload
      let newSelectedSubjectIds = []
      if (state.selectedSubjectIds.includes(subjectId))
        newSelectedSubjectIds = without([subjectId], state.selectedSubjectIds)
      else newSelectedSubjectIds = state.selectedSubjectIds.concat(subjectId)
      if (canAddMoreSubjects(state, newSelectedSubjectIds))
        return { ...state, selectedSubjectIds: newSelectedSubjectIds }
      return state
    }
    case 'ActiveStepChanged':
      return assoc('activeStep', action.payload, state)
    case 'SupplementIdsChanged': {
      const subjectId = action.payload
      let newSupplementsIds = []
      if (state.selectedSupplementIds.includes(subjectId))
        newSupplementsIds = without([subjectId], state.selectedSupplementIds)
      else newSupplementsIds = state.selectedSupplementIds.concat(subjectId)
      return { ...state, selectedSupplementIds: newSupplementsIds }
    }
    case 'SelectedDiscountChanged': {
      const value = action.payload.length ? [action.payload.reverse()[0]] : []
      return { ...state, selectedDiscountIds: uniq(value) }
    }
    case 'ToggleShowModal':
      return { ...state, showModal: !state.showModal }
    default:
      return state
  }
}

/** @type {SubscriptionManagerState} */
export const initialState = {
  selectedType: '',
  selectedSubscriptionConfigId: '',
  isFetching: true,
  selectedSubscriptionConfigRow: null,
  subscriptionConfigs: [],
  activeStep: 0,
  subjects: [],
  selectedSubjectIds: [],
  selectedSupplementIds: [],
  selectedDiscountIds: [],
  accepted: false,
  targetUserId: '',
  targetUserType: '',
  paymentWithEnrollment: false,
  error: false,
  showModal: false
}

/**
 *
 * @param {object} currentConfig
 * @returns {SubscriptionManagerState}
 */
export function makeInitialState(currentConfig) {
  if (currentConfig && !isEmpty(currentConfig))
    return {
      ...initialState,
      selectedType: [
        SubscriptionConfigTypes.pack,
        SubscriptionConfigTypes.extraHours
      ].includes(currentConfig.subscriptionConfig.type)
        ? SubscriptionTypes.pack
        : SubscriptionTypes.rate,
      selectedSubscriptionConfigId: currentConfig.subscriptionConfig.id,
      selectedSubscriptionConfigRow: currentConfig.subscriptionConfig.config,
      activeStep: 2,
      selectedSubjectIds: currentConfig.selectedSubjectIds,
      selectedSupplementIds: currentConfig.selectedSupplementIds || [],
      selectedDiscountIds: currentConfig.discountIds || [],
      accepted: currentConfig.accepted,
      targetUserId: currentConfig.userId,
      targetUserType: currentConfig.userType,
      paymentWithEnrollment: currentConfig.paymentWithEnrollment
    }

  return initialState
}
/**
 *
 * @param {SubscriptionManagerState} state
 * @param {string[]} newSelectedSubjectIds
 * @returns {boolean}
 */
function canAddMoreSubjectsForReinforcesRate(state, newSelectedSubjectIds) {
  const subjectLimitTotal =
    state.selectedSubscriptionConfigRow?.subjectRestrictions.reduce(
      (acc, { subjectsLimit }) => {
        acc += subjectsLimit
        return acc
      },
      0
    )
  if (!isEmpty(newSelectedSubjectIds)) {
    const subjectsById = indexBy(prop('id'), state.subjects)
    const lastSubjectAdded =
      // @ts-ignore
      subjectsById[head(takeLast(1, newSelectedSubjectIds))]
    const limitsByType = pipe(
      subjectRestrictions => groupBy(prop('type'), subjectRestrictions),
      Object.values,
      values =>
        values.reduce((acc, group) => {
          group.forEach(restriction => {
            const { type, subjectsLimit } = restriction
            acc[type] = (acc[type] || 0) + subjectsLimit
          })
          return acc
        }, {})
    )(state.selectedSubscriptionConfigRow?.subjectRestrictions)
    const limit = propOr(
      limitsByType[SubjectLevels.UNIVERSIDAD],
      lastSubjectAdded.type,
      limitsByType
    )
    const selectedSubjectsByType = pipe(
      pick(newSelectedSubjectIds),
      Object.values,
      countBy(info => {
        if (info.level === SubjectLevels.UNIVERSIDAD)
          return SubjectLevels.UNIVERSIDAD
        return info.type
      })
    )(subjectsById)
    const currentCounter = propOr(
      selectedSubjectsByType[SubjectLevels.UNIVERSIDAD],
      lastSubjectAdded.type,
      selectedSubjectsByType
    )
    return !(currentCounter > limit)
  }

  return !(newSelectedSubjectIds.length > subjectLimitTotal)
}

/**
 *
 * @param {SubscriptionManagerState} state
 * @param {string[]} newSelectedSubjectIds
 * @returns {boolean}
 */
function canAddMoreSubjects(state, newSelectedSubjectIds) {
  const selectedConfig = state.subscriptionConfigs.find(
    propEq('id', state.selectedSubscriptionConfigId)
  )
  switch (selectedConfig.type) {
    case SubscriptionConfigTypes.reinforces: {
      return canAddMoreSubjectsForReinforcesRate(state, newSelectedSubjectIds)
    }
    case SubscriptionConfigTypes.unlimited:
    case SubscriptionConfigTypes.agora:
      return true
    default: {
      return !(
        newSelectedSubjectIds.length >
        state.selectedSubscriptionConfigRow.subjectsLimit
      )
    }
  }
}

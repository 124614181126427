//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty, assocPath, last, dissocPath } from 'ramda'
import { useParams } from 'react-router-dom'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { Alert, AlertTitle } from '@material-ui/lab'
import { getTeacherListQuery } from 'api/teachers'
import { getTeacherSchedulesByRange } from 'api/teacherSchedules'
import { findSubscriptionsByStudent } from 'api/students'
import { findSubjects } from 'api/subjects'
import { saveManyAttendances } from 'api/attendances'
import { dateTimeToString, mergeHours } from 'utils/date'
import useFormManager from '../../../hooks/common/useFormManager'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import DefaultSelect, { EMPTY_VALUE } from '../../../shared/DefaultSelect'
import ConfirmModal from '../../../shared/ConfirmModal'
import { SubjectLevels } from '../../../shared/constants'

const REQUIREDS = [
  'subscriptionId',
  'subjectId',
  'teacherId',
  'startDate',
  'endDate'
]

function Multiplanner() {
  // @ts-ignore
  const { id: studentId } = useParams()
  const [state, setState] = useState({
    days: {},
    subscriptions: [],
    subjects: [],
    teachers: [],
    teacherSchedule: [],
    isSaving: false,
    response: {}
  })
  const {
    form,
    handleFormChange,
    handleDateChange,
    clearForm,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields
  } = useFormManager(REQUIREDS)
  const { days, isSaving, teacherSchedule, response } = state
  const { setErrorMessage, setWarningMessage } = useMessageSnackbarActions()
  const handleFormSubscriptionChange = useCallback(
    e => {
      setState(state => ({ ...state, subjects: [], teachers: [] }))
      clearForm()
      handleFormChange(e)
    },
    [clearForm, handleFormChange]
  )
  const handleSubjectChange = useCallback(
    e => {
      handleFormChange(e)
      handleFormChange({ target: { name: 'teacherId', value: '' } })
      setState(assocPath(['days'], {}))
    },
    [handleFormChange]
  )
  const handleTeacherChange = useCallback(
    e => {
      handleFormChange(e)
      setState(assocPath(['days'], {}))
    },
    [handleFormChange]
  )
  const handleFormDateChange = useCallback(
    (field, value) => {
      handleDateChange(field, value)
    },
    [handleDateChange]
  )
  const handleAddRangeHours = useCallback((day, hours) => {
    const lastSelected = last(hours)
    lastSelected === EMPTY_VALUE || (hours && isEmpty(hours))
      ? setState(dissocPath(['days', day]))
      : setState(assocPath(['days', day], hours))
  }, [])
  const handleSave = useCallback(() => {
    setState(assocPath(['isSaving'], true))
    const subscription = state.subscriptions.find(
      ({ id }) => form.subscriptionId === id
    )

    saveManyAttendances({
      ...form,
      // @ts-ignore
      budgetId: subscription.budgetId,
      studentId,
      days,
      // @ts-ignore
      startDate: form.startDate || subscription.startDate
    })
      .then(data => {
        setState(assocPath(['response'], data))
      })
      .catch(e => {
        console.error('Error saving many attendances: ', e)
        setErrorMessage('Error: ' + e.message)
      })
      .finally(() => setState(assocPath(['isSaving'], false)))
  }, [state.subscriptions, form, studentId, days, setErrorMessage])
  useEffect(() => {
    findSubscriptionsByStudent(studentId)
      .then(subscriptions =>
        setState(state => ({
          ...state,
          subscriptions: subscriptions.map(s => {
            s.customLabel = `${s.subscriptionName} (${dateTimeToString(
              s.startDate
            )})`
            return s
          })
        }))
      )
      .catch(e => {
        console.error('Error getting subscriptions by student: ', e)
      })
  }, [studentId])

  useEffect(() => {
    if (form.subscriptionId) {
      // @ts-ignore
      const { selectedSubjectIds = [], selectedSupplementIds = [] } =
        state.subscriptions.find(({ id }) => id === form.subscriptionId) || {}
      findSubjects({
        _id: {
          $in: [...selectedSubjectIds, ...selectedSupplementIds]
        }
      })
        .then(filteredSubjects => {
          setState(state => ({
            ...state,
            subjects: filteredSubjects.map(s => {
              s.customLabel =
                s.level === SubjectLevels.IDIOMAS && s.sublevel
                  ? `${s.name} (${s.sublevel})`
                  : `${s.name} (${s.level})`
              return s
            })
          }))
        })
        .catch(e =>
          console.error('Error fetching subjects by selected subscription: ', e)
        )
    }
  }, [form.subscriptionId, state.subscriptions])
  useEffect(() => {
    if (form.subjectId) {
      getTeacherListQuery({ subjectId: form.subjectId })
        .then(teachers => {
          setState(state => ({ ...state, teachers }))
        })
        .catch(e => console.error('Error getting teachers by subjectId: ', e))
    }
    setState(state => ({ ...state, teachers: [] }))
  }, [form.subjectId, handleFormChange])

  useEffect(() => {
    if (form.teacherId && form.startDate && form.endDate) {
      getTeacherSchedulesByRange({
        teacherId: form.teacherId,
        startDate: new Date(form.startDate).getTime(),
        endDate: new Date(form.endDate).getTime()
      })
        .then(schedule => {
          setState(assocPath(['teacherSchedule'], schedule))
        })
        .catch(e => {
          console.error('Error getting schedule by range: ', e)
          setErrorMessage('Ha habido un error obteniendo el horario por rango')
        })
    }
    setState(state => ({ ...state, teacherSchedule: [] }))
  }, [
    form.teacherId,
    form.startDate,
    form.endDate,
    setErrorMessage,
    setWarningMessage
  ])

  return (
    <Card
      style={{
        margin: '10px 15px 0px 10px'
      }}
      variant='outlined'
    >
      <CardHeader
        title={
          <Alert severity='info'>
            <AlertTitle>Info</AlertTitle>

            <span>
              - Se utiliza el horario por defecto del profesor, si alguna
              asistencia no se ha podido generar revisar/añadir ese día en
              específico
            </span>
            <p>
              - Si no se indica una fecha de inicio o fin se tomarán los límites
              de la suscripción seleccionada
            </p>
          </Alert>
        }
      />
      <CardContent
        style={{
          padding: '0 20px',
          height: 'calc(100vh - 390px)',
          overflowY: 'auto'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 25
          }}
        >
          <DefaultSelect
            name='subscriptionId'
            value={form.subscriptionId || ''}
            title='Suscripción'
            options={state.subscriptions}
            labelKey='customLabel'
            onChange={handleFormSubscriptionChange}
            {...getErrorPropsByRequiredField('subscriptionId')}
          />
          <DefaultSelect
            name='subjectId'
            value={form.subjectId || ''}
            title='Asignatura'
            options={state.subjects}
            labelKey='customLabel'
            onChange={handleSubjectChange}
            {...getErrorPropsByRequiredField('subjectId')}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 0.5fr 0.5fr',
            gap: 25
          }}
        >
          <DefaultSelect
            name='teacherId'
            value={form.teacherId || ''}
            title='Profesor'
            options={state.teachers}
            onChange={handleTeacherChange}
            {...getErrorPropsByRequiredField('teacherId')}
          />
          <div style={{ marginTop: 10 }}>
            <KeyboardDateTimePicker
              label='Fecha inicio'
              margin='dense'
              format='dd/MM/yyyy - HH:mm'
              value={form.startDate || null}
              onChange={value => handleFormDateChange('startDate', value)}
              {...getErrorPropsByRequiredField('startDate')}
              disablePast
              clearable
              fullWidth
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <KeyboardDateTimePicker
              label='Fecha fin'
              margin='dense'
              format='dd/MM/yyyy - HH:mm'
              value={form.endDate || null}
              onChange={value => handleFormDateChange('endDate', value)}
              {...getErrorPropsByRequiredField('endDate')}
              clearable
              fullWidth
            />
          </div>
        </div>
        {!isEmpty(teacherSchedule) && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>Día</TableCell>
                  <TableCell align='center'>Horario</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teacherSchedule.map(
                  ({ day, hours = [], halfHours = [], isByDate = false }) => {
                    const allSortedHours = hours
                      .concat(halfHours)
                      .sort()
                      .reduce((acc, stringHour) => {
                        acc.push({ id: stringHour, name: stringHour })
                        return acc
                      }, [])
                    // @ts-ignore
                    const value = (days[day] || []).sort()
                    return (
                      <TableRow key={day} selected={isByDate} hover>
                        <TableCell
                          align='center'
                          title={
                            isByDate
                              ? 'Horario por fecha'
                              : 'Horario por defecto'
                          }
                        >
                          {day}
                        </TableCell>
                        <TableCell align='center'>
                          <DefaultSelect
                            name='startTime'
                            title={
                              !isEmpty(value)
                                ? `Horario: ${mergeHours(value).join('; ')}`
                                : 'Horario'
                            }
                            margin='dense'
                            options={allSortedHours}
                            value={value}
                            onChange={e =>
                              handleAddRangeHours(day, e.target.value)
                            }
                            multiple
                          />
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <CardActions className='card_wrapper_action'>
        <LoadingButton
          disabled={
            hasPendingRequiredFields ||
            isEmpty(days) ||
            (form.startDate && isNaN(Date.parse(form.startDate))) ||
            (form.endDate && isNaN(Date.parse(form.endDate)))
          }
          label='Guardar'
          isLoading={isSaving}
          onClick={handleSave}
          startIcon={<i className='material-icons'>save</i>}
        />
      </CardActions>
      {response && !isEmpty(response) && (
        <ConfirmModal
          title='Resultado ejecución'
          subtitle=''
          onOk={() => setState(assocPath(['response'], null))}
          onCancel={() => setState(assocPath(['response'], null))}
          showModal
        >
          <div
            style={{
              height: 'calc(100vh - 450px)'
            }}
          >
            {response.failed.map((f, i) => {
              const { data, day, userMessage } = f
              return (
                <p key={i}>
                  {data.classStart
                    ? `${day} ${new Date(
                        data.classStart
                      ).toLocaleDateString()}: ${userMessage}`
                    : `${day}: ${userMessage}`}
                </p>
              )
            })}
            <p>
              {response.success.length > 0 &&
                `Se han podido guardar ${response.success.length} asistencias`}
            </p>
            {response.failed.length === 0 &&
              response.success.length === 0 &&
              'No se han encontrado fechas para el rango definido'}
          </div>
        </ConfirmModal>
      )}
    </Card>
  )
}

export default Multiplanner

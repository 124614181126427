//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Link, useHistory, useParams } from 'react-router-dom'
import { assoc, isEmpty, sum, times } from 'ramda'
import { getFullBudgetInfoToCharge } from 'api/budgets'
import { createSubscription } from 'api/subscriptions'
import { makeCalculatedDiscount } from 'api/discounts'
import { MONTHS } from 'utils/date'
import Spinner from '../../../elements/Spinner/Spinner'
import ConfirmModal from '../../../shared/ConfirmModal'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import { SubscriptionTypes } from '../../../shared/constants'
import DiscountSelect from '../Maker/DiscountSelect'
import './Pay.css'

//TODO: move to .env?
const ENROLLMENT_PRICE = 45

function Index() {
  // @ts-ignore
  const { budgetId } = useParams()
  const { push } = useHistory()
  const [state, setState] = useState({
    fullBudgetInfo: {},
    isLoading: false,
    showModal: false,
    chargeDescription: '',
    startDate: null,
    error: false,
    isCharging: false,
    calculatedPrice: null
  })
  const { setErrorMessage, setSuccessMessage } = useMessageSnackbarActions()
  const {
    fullBudgetInfo,
    isLoading,
    showModal,
    chargeDescription,
    startDate,
    error,
    isCharging
  } = state

  const hasActiveEnrollment = useMemo(() => {
    if (!fullBudgetInfo?.enrolledUntil) return false
    return new Date() <= new Date(fullBudgetInfo.enrolledUntil)
  }, [fullBudgetInfo])
  const totalPaymentAmount = useMemo(() => {
    return hasActiveEnrollment
      ? [fullBudgetInfo?.totalPrice]
      : [fullBudgetInfo?.totalPrice, ENROLLMENT_PRICE]
  }, [fullBudgetInfo, hasActiveEnrollment])
  const isPack =
    fullBudgetInfo?.subscriptionConfig?.type === SubscriptionTypes.pack
  const canSave = useMemo(() => {
    if (!startDate) return false
    if (isPack) return !isNaN(Date.parse(startDate))
    if (isNaN(Date.parse(startDate))) return false
    return true
  }, [isPack, startDate])
  const toggleShowModal = useCallback(() => {
    setState(s => ({ ...s, showModal: !s.showModal }))
  }, [])
  const handleCharge = useCallback(() => {
    setState(assoc('isCharging', true))
    if (isNaN(Date.parse(startDate))) return
    createSubscription({
      studentId: fullBudgetInfo.userId,
      budgetId: fullBudgetInfo.id,
      paymentAmount: sum(totalPaymentAmount),
      promotionalCodes: [], //TODO
      includesEnrollment: !hasActiveEnrollment,
      description: chargeDescription,
      startDate
    })
      .then(res => {
        if (res.requiredAction)
          throw new Error('Esta operación necesita atención del alumno')
        setSuccessMessage('Suscripción pagada y creada correctamente')
        push('/operations')
      })
      .catch(e => {
        console.error('Error charging student:', e)
        setErrorMessage(e.message)
      })
      .finally(() => {
        setState(assoc('isCharging', false))
      })
  }, [
    fullBudgetInfo,
    hasActiveEnrollment,
    totalPaymentAmount,
    chargeDescription,
    startDate,
    setErrorMessage,
    setSuccessMessage,
    push
  ])

  const calculateDiscount = useCallback(() => {
    makeCalculatedDiscount({
      hasActiveEnrollment,
      totalPrice: fullBudgetInfo?.totalPrice,
      discountIds: fullBudgetInfo?.discountIds || []
    })
      .then(data => {
        setState(assoc('calculatedPrice', data))
      })
      .catch(e => {
        setErrorMessage(e.message)
        console.error('Error calculating price: ', e)
      })
  }, [fullBudgetInfo, hasActiveEnrollment, setErrorMessage])

  useEffect(() => {
    setState(assoc('isLoading', true))
    getFullBudgetInfoToCharge(budgetId)
      .then(fullBudgetInfo => {
        const isPack =
          fullBudgetInfo?.subscriptionConfig?.type === SubscriptionTypes.pack
        // @ts-ignore
        setState(state => ({
          ...state,
          fullBudgetInfo,
          startDate: isPack ? new Date() : null,
          isLoading: false
        }))
      })
      .catch(error => {
        console.error('Error getting full budget info:', error)
        setState(state => ({ ...state, isLoading: false, error: true }))
      })
  }, [budgetId])

  useEffect(() => {
    calculateDiscount()
  }, [calculateDiscount])

  const showRateAlert = useMemo(() => {
    if (isPack) return false
    if (!startDate || isNaN(Date.parse(startDate))) return false
    const targetDate = new Date(startDate)
    const currentDate = new Date()
    return (
      targetDate.getFullYear() === currentDate.getFullYear() &&
      targetDate.getMonth() === currentDate.getMonth()
    )
  }, [isPack, startDate])

  const rateDateOptions = useMemo(() => {
    const maxMonths = 6
    const currentDate = new Date()

    return times(i => {
      let value = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + i,
        1
      )
      return {
        value,
        label: MONTHS[value.getMonth()] + ` - ${value.getFullYear()}`
      }
    }, maxMonths)
  }, [])
  if (isLoading) return <Spinner />
  if (!fullBudgetInfo || isEmpty(fullBudgetInfo))
    return (
      <Card>
        <CardHeader
          title='Presupuesto no encontrado'
          subheader='Puede haber sido eliminado o no existe'
        />
        <CardActions>
          <Link to='/budgets'>
            <Button variant='contained' color='primary'>
              Volver
            </Button>
          </Link>
        </CardActions>
      </Card>
    )
  return (
    <>
      <Card className='card_pay'>
        <CardHeader title='Cobro de subscripción' />
        <CardContent className='card_content_pay'>
          {!fullBudgetInfo?.hasPaymentMethod && (
            <Alert className='has_not_payment_alert' severity='warning'>
              No se ha encontrado un método de pago
            </Alert>
          )}
          {!fullBudgetInfo?.studentId && (
            <Alert className='has_not_payment_alert' severity='warning'>
              No se ha encontrado un alumno asociado
            </Alert>
          )}
          <div className='pay_container'>
            <div>
              <div className='div_item'>
                <h3>Tiene matrícula activa</h3>
                <div className='chip_label'>
                  <Chip
                    label={hasActiveEnrollment ? 'SÍ' : 'NO'}
                    color={hasActiveEnrollment ? 'primary' : 'secondary'}
                  />
                </div>
              </div>
              <div className='div_item'>
                <h3>Descuento promocional aplicado</h3>
                <div className='chip_label'>
                  {fullBudgetInfo?.discountIds?.length ? (
                    <DiscountSelect
                      value={fullBudgetInfo?.discountIds}
                      showLabel={false}
                      disabled
                    />
                  ) : (
                    <Chip label='Sin descuento' color='secondary' />
                  )}
                </div>
              </div>
            </div>
            <Divider
              orientation='vertical'
              className='divider_class'
              flexItem
            />
            <div>
              <div className='div_item'>
                <h3>Alumno</h3>
                <p>
                  <Link to={`/students/${fullBudgetInfo.userId}`}>
                    <Button color='primary'>{fullBudgetInfo.userName}</Button>
                  </Link>
                </p>
              </div>
              <div className='div_item'>
                <h3>Subscripción</h3>
                <p>
                  {fullBudgetInfo.subscriptionConfig?.name} (
                  {totalPaymentAmount.join('€ + ')}€)
                </p>
              </div>
            </div>
          </div>
          <div className='subscription_total_section'>
            <div className='subscription_total_text'>
              <span>Total:</span>
              <strong> {state.calculatedPrice}€</strong>
            </div>
          </div>
        </CardContent>
        <CardActions className='card_actions_pay'>
          <Button
            color='primary'
            variant='contained'
            disabled={
              error ||
              !fullBudgetInfo?.hasPaymentMethod ||
              !fullBudgetInfo?.studentId
            }
            onClick={toggleShowModal}
          >
            Abrir pago
          </Button>
        </CardActions>
      </Card>
      <ConfirmModal
        title='Apertura de pago'
        subtitle='Se procederá al cobro de la suscripción del alumno'
        okText='Cobrar'
        onOk={handleCharge}
        onCancel={toggleShowModal}
        okDisabled={!canSave}
        style={{ width: '40%', margin: '200px auto' }}
        showModal={showModal}
        isLoading={isCharging}
        children={
          <>
            {showRateAlert && (
              <Alert severity='warning'>
                Esta tarifa solo incluye el mes natural y ningún día del mes
                siguiente
              </Alert>
            )}

            <div className='payment_content'>
              {isPack ? (
                <KeyboardDatePicker
                  label='Fecha inicio de la suscripción'
                  margin='dense'
                  format='dd/MM/yyyy'
                  value={startDate}
                  onChange={value => setState(assoc('startDate', value))}
                  fullWidth
                  disablePast
                  disableToolbar
                />
              ) : (
                <FormControl style={{ width: '100%' }}>
                  <InputLabel>Fecha de inicio</InputLabel>
                  <Select
                    value={startDate}
                    onChange={e => setState(assoc('startDate', e.target.value))}
                    fullWidth
                  >
                    {rateDateOptions.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <TextField
                label='Nota del cobro'
                onChange={e =>
                  setState(assoc('chargeDescription', e.target.value))
                }
                maxRows={4}
                multiline
                fullWidth
              />
            </div>
          </>
        }
      />
    </>
  )
}

export default Index

//@ts-check
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import {
  deleteAcademicSchedule,
  findAcademicSchedules
} from 'api/academicSchedules'
import InformationTable from '../../../components/InformationTable/InformationTable'
import SearchInput from '../../../elements/SearchInput/SearchInput'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import StatusIcon from '../../../shared/StatusIcon'
import ConfirmModal from '../../../shared/ConfirmModal'

const ScheduleList = () => {
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()
  const [schedules, setSchedules] = useState([])
  const [search, setSearch] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const fetchData = useCallback(() => {
    findAcademicSchedules()
      .then(data => setSchedules(data))
      .catch(() => setErrorMessage('No se puede mostrar el listado'))
  }, [setErrorMessage])
  const handleShowModal = useCallback(({ show, id }) => {
    setShowConfirmModal(show)
    setSelectedId(id)
  }, [])
  const handleRemove = useCallback(() => {
    deleteAcademicSchedule(selectedId)
      .then(() => {
        setSuccessMessage('Eliminado correctamente')
        setSelectedId(null)
        setShowConfirmModal(false)
        fetchData()
      })
      .catch(e => {
        console.error('Error deleting schedule: ', e)
        setErrorMessage(e.message)
      })
  }, [fetchData, selectedId, setErrorMessage, setSuccessMessage])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <>
      <div className='common_list_filter'>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <Link to='/schedules/create'>
          <LoadingButton
            label='Nuevo horario'
            startIcon={<i className='material-icons'>add</i>}
          />
        </Link>
      </div>
      <InformationTable
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          {
            title: 'Creado por',
            key: 'createdBy'
          },
          {
            title: 'Actualizado por',
            key: 'updatedBy'
          },
          {
            title: 'Activo',
            key: 'active'
          },
          { title: 'Acciones', key: 'actions' }
        ]}
        data={schedules
          .filter(schedule => {
            if (!search) return true
            return schedule.name.toLowerCase().includes(search.toLowerCase())
          })
          .map(schedule => ({
            name: <Link to={`/schedules/${schedule.id}`}>{schedule.name}</Link>,
            createdBy: schedule.createdBy,
            updatedBy: schedule.updatedBy,
            active: <StatusIcon active={schedule.active} />,
            actions: (
              <Button
                onClick={() => handleShowModal({ show: true, id: schedule.id })}
              >
                <i className='material-icons'>delete</i>
              </Button>
            )
          }))}
      />
      <ConfirmModal
        showModal={showConfirmModal}
        onOk={handleRemove}
        onCancel={() => handleShowModal({ show: false, id: null })}
      />
    </>
  )
}

export default ScheduleList

import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { LeadLabelByStatus } from '../../shared/constants'

function LeadStatusSelect({
  value = '',
  onChange,
  label = 'Estado',
  name = 'status',
  fullWidth,
  margin = 'dense',
  options = Object.keys(LeadLabelByStatus),
  disabled = false
}) {
  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} disabled={disabled} onChange={onChange}>
        {options.map(value => (
          <MenuItem key={value} value={value}>
            {LeadLabelByStatus[value]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LeadStatusSelect

//@ts-check
import React, { useCallback, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core/'
import { identity, last } from 'ramda'
import { KeyboardDatePicker } from '@material-ui/pickers'
import MuiPhoneNumber from 'material-ui-phone-number'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import EducationalCentreSelect from '../../../elements/EducationalCentreSelect/EducationalCentreSelect'
import AcademicBackgroundSelect from '../../../elements/AcademicBackgroundSelect/AcademicBackgroundSelect'
import CourseSelect from '../../../elements/CourseSelect/CourseSelect'
import SubjectSelector from '../../../elements/SubjectSelector/SubjectSelector'
import MetUsInSelect from '../../../elements/MetUsInSelect/MetUsInSelect'
import LeadGoalsSelect from '../../../elements/LeadGoalsSelect/LeadGoalsSelect'
import DefaultSelect from '../../../shared/DefaultSelect'
import useFormManager from '../../../hooks/common/useFormManager'
import ContactFormByLeadStatus from './ContactFormByLeadStatus'
import { ContentByLastStatus } from './LeadContacts'
import './LeadForm.css'

const leadStatus = [
  {
    label: 'Se lo piensa',
    value: 'lead'
  },
  {
    label: 'Viene a prueba',
    value: 'test'
  },
  {
    label: 'Cualificado',
    value: 'trained'
  },
  {
    label: 'No le interesa',
    value: 'lost'
  },
  {
    label: 'Lead inválido',
    value: 'invalid'
  },
  { label: 'Captado', value: 'converted' }
]
const REQUIRED_FIELDS = [
  'name',
  'email',
  'phoneNumber.cleanedPhone',
  'contactPerson.name',
  'contactPerson.email',
  'contactPerson.phoneNumber.cleanedPhone',
  ['contacts', 0, 'date']
]

function LeadForm({
  lead = {},
  isSaving,
  isChecking = false,
  isCreateMode = false,
  onSubmit,
  onCheckLead = identity
}) {
  const [selectedStatus, setSelectedStatus] = useState('')
  const [showModal, setShowModal] = useState(false)
  const {
    handleFormChange,
    handlePhoneNumberChange,
    handleDateChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(REQUIRED_FIELDS, lead)

  const handleSubmit = useCallback(() => {
    onSubmit(form)
  }, [form, onSubmit])
  const handleShowModal = useCallback(newStatus => {
    setSelectedStatus(newStatus)
    setShowModal(true)
  }, [])
  const handleSetContact = useCallback(
    contactForm => {
      handleFormChange({
        target: { name: ['contacts', 0], value: contactForm }
      })
      handleFormChange({
        target: { name: 'status', value: contactForm.lastStatus }
      })
      setShowModal(false)
    },
    [handleFormChange]
  )
  return (
    <Card>
      <CardContent
        className={
          isCreateMode ? 'main_card_content_create' : 'main_card_content_edit'
        }
      >
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ width: '85%', margin: '0 auto' }}
          spacing={2}
        >
          <Grid item xs>
            <Card
              className='bordered_shadow lead_card_wrapper'
              variant='outlined'
            >
              <CardHeader title='Datos del alumno (lead)' />
              <CardContent>
                <TextField
                  name='name'
                  margin='dense'
                  label='Nombre'
                  value={form.name || ''}
                  onChange={handleFormChange}
                  fullWidth
                  {...getErrorPropsByRequiredField('name')}
                />
                <TextField
                  name='email'
                  margin='dense'
                  label='Correo electrónico'
                  value={form.email || ''}
                  onChange={handleFormChange}
                  fullWidth
                  {...getErrorPropsByRequiredField('email')}
                />
                <div className='inner_grid'>
                  <KeyboardDatePicker
                    label='Fecha de nacimiento'
                    margin='dense'
                    format='dd/MM/yyyy'
                    value={form.dateOfBirth || null}
                    onChange={value => handleDateChange('dateOfBirth', value)}
                  />
                  <TextField
                    name='country'
                    margin='dense'
                    label='País'
                    value={form.country || ''}
                    onChange={handleFormChange}
                  />
                  <TextField
                    name='postalCode'
                    margin='dense'
                    label='Código postal'
                    value={form.postalCode || ''}
                    onChange={handleFormChange}
                  />
                  <MuiPhoneNumber
                    label='Teléfono'
                    defaultCountry='es'
                    margin='dense'
                    value={form.phoneNumber?.fullPhoneNumber || ''}
                    // @ts-ignore
                    localization={{ Spain: 'España' }}
                    onChange={(fullPhoneNumber, details) =>
                      handlePhoneNumberChange('phoneNumber', {
                        fullPhoneNumber,
                        ...details
                      })
                    }
                    {...getErrorPropsByRequiredField(
                      'phoneNumber.cleanedPhone'
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs>
            <Card
              className='bordered_shadow lead_card_wrapper'
              variant='outlined'
            >
              <CardHeader title='Datos del contacto' />
              <CardContent>
                <TextField
                  name='contactPerson.name'
                  margin='dense'
                  label='Nombre'
                  value={form.contactPerson?.name || ''}
                  onChange={handleFormChange}
                  fullWidth
                  {...getErrorPropsByRequiredField('contactPerson.name')}
                />
                <TextField
                  name='contactPerson.email'
                  margin='dense'
                  label='Correo electrónico'
                  value={form.contactPerson?.email || ''}
                  onChange={handleFormChange}
                  onBlur={() =>
                    isCreateMode &&
                    onCheckLead({
                      email: form?.contactPerson?.email,
                      phoneNumber:
                        form?.contactPerson?.phoneNumber?.cleanedPhone
                    })
                  }
                  fullWidth
                  {...getErrorPropsByRequiredField('contactPerson.email')}
                />
                <DefaultSelect
                  name='contactPerson.relationship'
                  margin='dense'
                  title='Relación con el lead'
                  options={[
                    { id: 'Madre', name: 'Madre' },
                    { id: 'Padre', name: 'Padre' },
                    { id: 'Familiar', name: 'Familiar' },
                    { id: 'Otro', name: 'Otro' }
                  ]}
                  value={form.contactPerson?.relationship || ''}
                  onChange={handleFormChange}
                  fullWidth
                />
                <MuiPhoneNumber
                  label='Teléfono'
                  defaultCountry='es'
                  margin='dense'
                  value={form.contactPerson?.phoneNumber?.fullPhoneNumber || ''}
                  // @ts-ignore
                  localization={{ Spain: 'España' }}
                  onBlur={() =>
                    isCreateMode &&
                    onCheckLead({
                      email: form?.contactPerson?.email,
                      phoneNumber:
                        form?.contactPerson?.phoneNumber?.cleanedPhone
                    })
                  }
                  onChange={(fullPhoneNumber, details) =>
                    handlePhoneNumberChange('contactPerson.phoneNumber', {
                      fullPhoneNumber,
                      ...details
                    })
                  }
                  {...getErrorPropsByRequiredField(
                    'contactPerson.phoneNumber.cleanedPhone'
                  )}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs>
            <Card
              className='bordered_shadow lead_card_wrapper'
              variant='outlined'
            >
              <CardHeader title='Detalles académicos' />
              <CardContent>
                <EducationalCentreSelect
                  name='educationalCentre'
                  value={form.educationalCentre || ''}
                  onChange={handleFormChange}
                  fullWidth
                />
                <AcademicBackgroundSelect
                  name='academicBackground'
                  value={form.academicBackground || ''}
                  onChange={handleFormChange}
                  fullWidth
                />
                <CourseSelect
                  name='course'
                  value={form.course || ''}
                  onChange={handleFormChange}
                  fullWidth
                />
                <SubjectSelector
                  name='subjects'
                  margin='dense'
                  label='Asignaturas de interés'
                  value={form.subjects || []}
                  onChange={handleFormChange}
                  multiple
                  fullWidth
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs>
            <Card
              className='bordered_shadow lead_card_wrapper'
              variant='outlined'
            >
              <CardHeader title='Detalles de marketing' />
              <CardContent>
                <MetUsInSelect
                  name='metUsIn'
                  value={form.metUsIn || ''}
                  onChange={handleFormChange}
                  fullWidth
                />
                <LeadGoalsSelect
                  name='goals'
                  value={form.goals || ''}
                  onChange={handleFormChange}
                  fullWidth
                />
                <MuiPhoneNumber
                  label='Llamada entrante'
                  defaultCountry='es'
                  margin='dense'
                  value={form.incomingPhoneCall?.fullPhoneNumber || ''}
                  // @ts-ignore
                  localization={{ Spain: 'España' }}
                  onChange={(fullPhoneNumber, details) =>
                    handlePhoneNumberChange('incomingPhoneCall', {
                      fullPhoneNumber,
                      ...details
                    })
                  }
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              className='bordered_shadow full_width_card_wrapper'
              variant='outlined'
            >
              <CardHeader title='Resultado lead' />
              <CardContent style={{ textAlign: 'center' }}>
                <FormControl component='fieldset' required>
                  <RadioGroup name='status' value={form.status || ''} row>
                    {leadStatus.map(({ label, value }, i) => (
                      <FormControlLabel
                        key={i}
                        value={value}
                        control={<Radio />}
                        label={label}
                        onClick={() => isCreateMode && handleShowModal(value)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <TextField
                  name='description'
                  margin='dense'
                  label='Observaciones'
                  value={form.description || ''}
                  onChange={handleFormChange}
                  fullWidth
                />
                {last(form?.contacts || [])?.lastStatus && (
                  <>
                    <p>Resumen último contacto</p>
                    <ContentByLastStatus
                      contact={last(form?.contacts || []) || {}}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className='card_wrapper_action'>
        {isCreateMode && (
          <LoadingButton
            label='Verificar lead'
            color='secondary'
            onClick={() =>
              onCheckLead({
                email: form?.contactPerson?.email,
                phoneNumber: form?.contactPerson?.phoneNumber?.cleanedPhone
              })
            }
            isLoading={isChecking}
            disabled={!form?.contactPerson?.phoneNumber?.cleanedPhone}
            startIcon={<i className='material-icons'>search</i>}
          />
        )}
        <LoadingButton
          label='Guardar'
          onClick={handleSubmit}
          isLoading={isSaving}
          disabled={hasPendingRequiredFields}
          startIcon={<i className='material-icons'>save</i>}
        />
      </CardActions>
      {showModal && (
        <ContactFormByLeadStatus
          contact={{ ...form?.contacts?.[0], lastStatus: selectedStatus }}
          onClose={() => setShowModal(false)}
          onSave={handleSetContact}
        />
      )}
    </Card>
  )
}

export default LeadForm

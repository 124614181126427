import { GET, POST, PUT, DELETE } from 'api'

export const findAcademicSchedules = () =>
  GET({
    url: 'admin/academic-schedules/'
  })

export const getAcademicSchedule = id =>
  GET({
    url: `admin/academic-schedules/${id}`
  })

export const createAcademicSchedule = (data = {}) =>
  POST({
    url: 'admin/academic-schedules/',
    data
  })

export const updateAcademicSchedule = (id, data = {}) =>
  PUT({
    url: `admin/academic-schedules/${id}`,
    data
  })

export const deleteAcademicSchedule = id =>
  DELETE({
    url: `admin/academic-schedules/${id}`
  })

//@ts-check
import React from 'react'
import { Checkbox } from '@material-ui/core'
import InformationTable from '../../../../components/InformationTable/InformationTable'
import { useSubscriptionManager } from '../context/SubscriptionManagerProvider'

function PrepareMorningsTable() {
  const {
    selectedConfig,
    selectedSubscriptionConfigRow,
    getSupplementsInfo,
    handleCheckConfig
  } = useSubscriptionManager()
  return (
    <>
      <InformationTable
        details={[
          { title: ' ', key: 'action' },
          // { title: 'Código', key: 'code' },
          { title: 'Nº de horas/semana', key: 'hoursLimitByWeek' },
          { title: 'Precio (€)', key: 'uniqPrice' },
          { title: 'Límite de asignaturas', key: 'subjectsLimit' }
        ]}
        data={Object.values(selectedConfig?.config ?? []).map(c => ({
          ...c,
          action: (
            <Checkbox
              checked={selectedSubscriptionConfigRow?.code === c.code}
              onClick={() => handleCheckConfig(c)}
            />
          )
        }))}
        size='small'
      />
      {getSupplementsInfo(selectedConfig.supplements).map(
        ({ name, level, price }, i) => (
          <p
            key={i}
          >{`* La asignatura ${name} (${level}) tiene un suplemento de ${price}€`}</p>
        )
      )}
    </>
  )
}

export default PrepareMorningsTable

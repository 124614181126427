//@ts-check
import { AppBar, Tab, Tabs } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { assocPath, pipe } from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import {
  createLeadContact,
  deleteLeadContact,
  editLeadContact,
  getLead,
  updateLead
} from 'api/leads'
import Spinner from '../../../elements/Spinner/Spinner'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import LeadForm from '../ui/LeadForm'
import LeadContacts from '../ui/LeadContacts'

const TabPanel = ({ active, children }) => {
  if (!active) return null
  return children
}
const initialState = {
  tab: 0,
  lead: null,
  isLoading: true,
  error: false,
  isSaving: false
}

const LeadEdit = () => {
  // @ts-ignore
  const { id } = useParams()
  const { push } = useHistory()
  const [state, setState] = useState(initialState)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()
  const handleChange = useCallback(
    (_e, newValue) => setState(assocPath(['tab'], newValue)),
    []
  )
  useEffect(() => {
    if (!id) return
    getLead(id)
      .then(lead => {
        setState({ ...initialState, lead, isLoading: false })
      })
      .catch(e => {
        console.error('Error getting lead: ' + e)
        setErrorMessage('Error al obtener el lead')
        setState({ ...initialState, isLoading: false, error: true })
      })
  }, [id, setErrorMessage])

  const handleSave = useCallback(
    updatedLead => {
      setState(assocPath(['isSaving'], true))
      updateLead(id, updatedLead)
        .then(() => {
          setSuccessMessage()
          push('/leads')
        })
        .catch(e => {
          console.error('Error updating lead: ' + e)
          setErrorMessage(e.message)
        })
        .finally(() => setState(assocPath(['isSaving'], false)))
    },
    [id, push, setErrorMessage, setSuccessMessage]
  )

  const handleCreateContact = useCallback(
    async contact => {
      try {
        setState(assocPath(['isSaving'], true))
        const updatedLead = await createLeadContact(id, contact)
        setState(
          pipe(assocPath(['lead'], updatedLead), assocPath(['isSaving'], false))
        )
        setSuccessMessage()
        return true
      } catch (e) {
        console.error('Error adding contact to lead: ', e)
        setErrorMessage(e.message || 'Compruebe los campos requeridos')
        setState(assocPath(['isSaving'], false))
        return false
      }
    },
    [id, setErrorMessage, setSuccessMessage]
  )
  const handleEditContact = useCallback(
    async (pos, contact) => {
      try {
        setState(assocPath(['isSaving'], true))
        const updatedLead = await editLeadContact(id, pos, contact)
        setState(
          pipe(assocPath(['lead'], updatedLead), assocPath(['isSaving'], false))
        )
        setSuccessMessage()
        return true
      } catch (e) {
        console.error('Error in edit contact: ', e)
        setErrorMessage(e.message || 'Compruebe los campos requeridos')
        setState(assocPath(['isSaving'], false))
        return false
      }
    },
    [id, setErrorMessage, setSuccessMessage]
  )
  const handleRemoveContact = useCallback(
    async pos => {
      try {
        setState(assocPath(['isSaving'], true))
        const updatedLead = await deleteLeadContact(id)
        setState(
          pipe(assocPath(['lead'], updatedLead), assocPath(['isSaving'], false))
        )
        setSuccessMessage('Eliminado correctamente')
      } catch (e) {
        console.error('Error deleting contact: ', e)
        if (e.message.includes('at least one contact'))
          setErrorMessage('El primer contacto no se puede eliminar')
        else setErrorMessage(e.message)
        setState(assocPath(['isSaving'], false))
      }
    },
    [id, setErrorMessage, setSuccessMessage]
  )

  //TODO: create a common component to show error
  if (state.error) return <p>Se ha producido un error</p>
  if (!state.lead || state.isLoading) return <Spinner />
  return (
    <>
      <AppBar color='secondary' position='static'>
        <Tabs
          value={state.tab}
          onChange={handleChange}
          variant='fullWidth'
          indicatorColor='primary'
        >
          <Tab label='Ficha' />
          <Tab label='Contactos' />
        </Tabs>
      </AppBar>

      <TabPanel active={state.tab === 0}>
        <LeadForm
          lead={state.lead}
          isSaving={state.isSaving}
          onSubmit={handleSave}
        />
      </TabPanel>
      <TabPanel active={state.tab === 1}>
        <LeadContacts
          contacts={state.lead?.contacts}
          onCreate={handleCreateContact}
          onEdit={handleEditContact}
          onRemove={handleRemoveContact}
          isSaving={state.isSaving}
        />
      </TabPanel>
    </>
  )
}

export default LeadEdit

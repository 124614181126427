//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { assoc } from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import {
  getAcademicSchedule,
  updateAcademicSchedule
} from 'api/academicSchedules'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import ScheduleTable from '../ui/ScheduleTable'
import Spinner from '../../../elements/Spinner/Spinner'

const initialState = {
  isLoading: true,
  isSaving: false,
  data: null
}

function ScheduleEdit() {
  // @ts-ignore
  const { id } = useParams()
  const history = useHistory()
  const [state, setState] = useState(initialState)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleUpdate = useCallback(
    schedule => {
      setState(assoc('isSaving', true))
      updateAcademicSchedule(id, schedule)
        .then(() => {
          setState(assoc('isSaving', false))
          history.push('/schedules')
          setSuccessMessage()
        })
        .catch(e => {
          setState(assoc('isSaving', false))
          console.error('Error updating schedule: ', e)
          setErrorMessage(e.message)
        })
    },
    [history, id, setErrorMessage, setSuccessMessage]
  )
  useEffect(() => {
    getAcademicSchedule(id)
      .then(data => {
        setState(state => ({ ...state, isLoading: false, data }))
      })
      .catch(e => {
        console.error('Error getting schedule: ' + e)
        setErrorMessage(e.message)
        setState(state => ({ ...state, isLoading: false, error: true }))
      })
  }, [id, setErrorMessage])
  if (state.error) return <p>Se ha producido un error</p>
  if (!state.data || state.isLoading) return <Spinner />
  return (
    <ScheduleTable
      title='Editar horario'
      onSave={handleUpdate}
      isSaving={state.isSaving}
      data={state.data}
    />
  )
}

export default ScheduleEdit

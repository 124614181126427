import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const options = [
  { value: '', label: 'Sin valor' },
  { value: 'recomendacion', label: 'Recomendación' },
  { value: 'antiguo_alumno', label: 'Antiguo Alumno' },
  { value: 'familiar', label: 'Familiar' },
  { value: 'folleto', label: 'Folleto' },
  { value: 'google', label: 'Buscador de Google' },
  { value: 'google_maps', label: 'Google Maps' },
  { value: 'radio', label: 'Radio' },
  { value: 'redes_sociales_facebook', label: 'Redes Sociales - Facebook' },
  { value: 'redes_sociales_instagram', label: 'Redes Sociales - Instagram' },
  { value: 'calle', label: 'Tienda de calle' },
  { value: 'cartel_fijo_exterior', text: 'Cartel Fijo Exterior' },
  { value: 'outbound', label: 'Outbound' },
  { value: 'acuerdos_corporativos', label: 'Acuerdos corporativos' },
  { value: 'prescriptores', label: 'Prescriptores' },
  { value: 'otra', label: 'Otra (especificar cual)' }
]
function MetUsInSelect({
  value = '',
  onChange,
  label = 'Cómo nos ha conocido',
  name,
  fullWidth,
  margin = 'dense'
}) {
  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MetUsInSelect

//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { assoc, indexBy, pipe, prop, sort } from 'ramda'
import { DragDropContext } from 'react-beautiful-dnd'
import { createLeadContact, findCRMLeads } from 'api/leads'
import { dateToString, isSameDay, toTimeString } from 'utils/date'
import { useMessageSnackbarActions } from '../../elements/MessageContext/MessageContext'
import { LeadStatus } from '../../shared/constants'
import Spinner from '../../elements/Spinner/Spinner'
import Column from './Column/Column'
import ContactFormByLeadStatus from '../Leads/ui/ContactFormByLeadStatus'

const CLOSED = [LeadStatus.lost, LeadStatus.invalid, LeadStatus.converted]
const TWO_DAYS_AGO = 86400 * 1000 * 2
const DAY_AND_HALF_LATER = 86400 * 1500

function addSomeInfo(lead) {
  const today = new Date()
  const yesterday = new Date(today.getTime() - 86400 * 1000)
  const tomorrow = new Date(today.getTime() + 86400 * 1000)
  let date = new Date(lead.date)
  let dateValue = `El ${dateToString(date)}`
  if (isSameDay(date, today)) dateValue = 'Hoy'
  if (isSameDay(date, yesterday)) dateValue = 'Ayer'
  if (isSameDay(date, tomorrow)) dateValue = 'Mañana'
  let hourValue = toTimeString(date)
  if (hourValue !== '00:00') lead.date = `${dateValue} a las ${hourValue}`
  else lead.date = dateValue
  //Add state to change css
  const red = new Date(today.getTime() - TWO_DAYS_AGO)
  const green = new Date(today.getTime() + DAY_AND_HALF_LATER)
  if (date < red) lead.cssState = 'critical'
  else if (date > red && date < today) lead.cssState = 'warning'
  else if (date > today && date < green) lead.cssState = 'healthy'
  else lead.cssState = 'future'
}

function diff(lead1, lead2) {
  if (!lead1.date || !lead2.date) return
  return new Date(lead1.date).getTime() - new Date(lead2.date).getTime()
}

function makeDNDContext(leads) {
  const leadsById = indexBy(prop('_id'), leads)
  const leadIdsByStatus = sort(diff, leads).reduce(
    (acc = {}, lead) => {
      const { lastStatus } = lead
      switch (lastStatus) {
        case 'lead':
          addSomeInfo(lead)
          acc.lead.push(lead._id)
          break
        case 'test':
          addSomeInfo(lead)
          acc.test.push(lead._id)
          break
        case 'trained':
          addSomeInfo(lead)
          acc.trained.push(lead._id)
          break
        default: {
          if (CLOSED.includes(lastStatus)) {
            lead.date = null
            acc.closed.push(lead._id)
          }
        }
      }
      return acc
    },
    { lead: [], test: [], trained: [], closed: [] }
  )
  return {
    columns: {
      lead: { id: 'lead', title: 'Nuevo lead', leadIds: leadIdsByStatus.lead },
      test: {
        id: 'test',
        title: 'Lead en prueba',
        leadIds: leadIdsByStatus.test
      },
      trained: {
        id: 'trained',
        title: 'Cualificado',
        leadIds: leadIdsByStatus.trained
      },
      closed: {
        id: 'closed',
        title: 'Cerrado',
        leadIds: leadIdsByStatus.closed
      }
    },
    leadsById,
    columnOrder: ['lead', 'test', 'trained', 'closed']
  }
}
const initialState = {
  isLoading: true,
  DNDContext: {},
  error: false,
  showModal: false,
  isSaving: false,
  formProps: {
    title: '',
    changeStatusDisabled: false,
    statusOptions: undefined,
    selectedLead: null
  }
}
function CRM() {
  const [state, setState] = useState(initialState)
  const { DNDContext, formProps } = state
  const { setErrorMessage, setSuccessMessage } = useMessageSnackbarActions()

  const fetchData = useCallback(() => {
    findCRMLeads()
      .then(leadDTOs => {
        setState(assoc('DNDContext', makeDNDContext(leadDTOs)))
      })
      .catch(e => {
        setState(assoc('error', true))
        console.error('Error finding contacts: ', e)
        setErrorMessage()
      })
      .finally(() => setState(assoc('isLoading', false)))
  }, [setErrorMessage])
  const handleDragEnd = useCallback(
    async result => {
      const { destination, draggableId: leadId } = result
      if (!destination) return

      let formProps = {
        selectedLead: leadId,
        statusOptions: undefined
      }
      const currentLead = DNDContext.leadsById[leadId]
      switch (destination.droppableId) {
        case 'lead':
          formProps = {
            ...formProps,
            title: `Mover a ${currentLead.name} a nuevo lead`,
            changeStatusDisabled: true
          }
          break
        case 'test':
          formProps = {
            ...formProps,
            title: `Mover a ${currentLead.name} a lead en prueba`,
            changeStatusDisabled: true,
            statusOptions: [LeadStatus.test]
          }
          break
        case 'trained':
          formProps = {
            ...formProps,
            title: `Mover a ${currentLead.name} a cualificado`,
            changeStatusDisabled: true,
            statusOptions: [LeadStatus.trained]
          }
          break
        case 'closed':
          formProps = {
            ...formProps,
            title: `Mover a ${currentLead.name} a cerrado`,
            changeStatusDisabled: false,
            statusOptions: [
              LeadStatus.converted,
              LeadStatus.lost,
              LeadStatus.invalid
            ]
          }
          break
        default:
          break
      }
      // @ts-ignore
      setState(pipe(assoc('showModal', true), assoc('formProps', formProps)))
    },
    [DNDContext]
  )

  const handleCreateContact = useCallback(
    async contact => {
      try {
        setState(assoc('isSaving', true))
        await createLeadContact(formProps.selectedLead, contact)
        fetchData()
        setState(pipe(assoc('isSaving', false), assoc('showModal', false)))
      } catch (e) {
        console.error('Error adding contact: ', e)
        setErrorMessage(e.message || 'Compruebe los campos requeridos')
        setState(assoc('isSaving', false))
      }
    },
    [fetchData, formProps.selectedLead, setErrorMessage]
  )
  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (state.error) return <span>Se ha producido un error</span>
  if (state.isLoading) return <Spinner />
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div style={{ display: 'flex' }}>
        {DNDContext.columnOrder.map(columnId => {
          const column = DNDContext.columns[columnId]
          const leads = column.leadIds.map(
            leadId => DNDContext.leadsById[leadId]
          )
          return <Column key={columnId} column={column} leads={leads} />
        })}
      </div>
      {state.showModal && (
        <ContactFormByLeadStatus
          {...formProps}
          onClose={() => setState(assoc('showModal', false))}
          onSave={handleCreateContact}
          isSaving={state.isSaving}
        />
      )}
    </DragDropContext>
  )
}

export default CRM

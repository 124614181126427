//@ts-check
import React from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core'
import { useSubscriptionManager } from './context/SubscriptionManagerProvider'
import SubscriptionSelector from './SubscriptionSelector'
import SubjectSubscriptionSelector from './SubjectSubscriptionSelector'
import ResumeInfo from './ResumeInfo'
import BudgetModal from './BudgetModal'
import { SubscriptionConfigTypes } from '../../../shared/constants'
import './SubscriptionManager.css'

function SubscriptionManager() {
  const {
    targetUserId,
    showModal,
    selectedConfig,
    selectedSubscriptionConfigRow,
    activeStep,
    selectedSubjectIds,
    handleSetActiveStep,
    toggleShowModal
  } = useSubscriptionManager()
  return (
    <>
      <Card className='card_manager'>
        <Stepper activeStep={activeStep}>
          {[
            {
              label: 'Seleccione una suscripción',
              secondaryText: selectedConfig?.name
            },
            { label: 'Asignaturas disponibles' },
            { label: 'Resumen' }
          ].map(({ label, secondaryText }, index) => (
            <Step key={label}>
              <StepLabel
                onClick={() => !targetUserId && handleSetActiveStep(index)}
                optional={secondaryText}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <CardContent className='card_content_manager'>
          <div>
            {activeStep === 0 && <SubscriptionSelector />}
            {activeStep === 1 && <SubjectSubscriptionSelector />}
            {activeStep === 2 && <ResumeInfo />}
          </div>
        </CardContent>
        <CardActions className='card_actions_manager'>
          {activeStep !== 0 && !targetUserId && (
            <Button
              color='secondary'
              variant='contained'
              onClick={() => handleSetActiveStep(activeStep - 1)}
            >
              Atrás
            </Button>
          )}
          {activeStep !== 2 && (
            <Button
              color='primary'
              variant='contained'
              onClick={() => handleSetActiveStep(activeStep + 1)}
              disabled={canContinue({
                activeStep,
                selectedSubscriptionConfigRow,
                selectedSubjectIds,
                isAgoraRate:
                  selectedConfig.type === SubscriptionConfigTypes.agora
              })}
            >
              Siguiente
            </Button>
          )}
          {activeStep === 2 && (
            <Button
              color='primary'
              variant='contained'
              onClick={toggleShowModal}
            >
              {targetUserId ? 'Detalles' : 'Presupuestar'}
            </Button>
          )}
        </CardActions>
      </Card>
      {showModal && <BudgetModal />}
    </>
  )
}

export default SubscriptionManager

function canContinue({
  activeStep,
  selectedSubscriptionConfigRow,
  selectedSubjectIds,
  isAgoraRate
}) {
  if (isAgoraRate) return false
  return activeStep === 0
    ? !selectedSubscriptionConfigRow
    : selectedSubjectIds.length <= 0
}

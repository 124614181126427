//@ts-check
import React from 'react'
import { Checkbox } from '@material-ui/core'
import InformationTable from '../../../../components/InformationTable/InformationTable'
import { useSubscriptionManager } from '../context/SubscriptionManagerProvider'

function UnlimitedTable() {
  const { selectedConfig, selectedSubscriptionConfigRow, handleCheckConfig } =
    useSubscriptionManager()
  return (
    <InformationTable
      details={[
        { title: ' ', key: 'action' },
        { title: 'Código', key: 'code' },
        { title: 'Nº de horas/día', key: 'hoursLimitByDay' },
        { title: 'Precio (€)', key: 'uniqPrice' },
        { title: 'Límite de asignaturas', key: 'subjectsLimit' }
      ]}
      data={Object.values(selectedConfig?.config ?? []).map(c => ({
        ...c,
        subjectsLimit: 'Sin límite',
        action: (
          <Checkbox
            checked={selectedSubscriptionConfigRow?.code === c.code}
            onClick={() => handleCheckConfig(c)}
          />
        )
      }))}
      size='small'
    />
  )
}

export default UnlimitedTable

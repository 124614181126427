//@ts-check
import React, { useEffect, useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { findEducationalCentres } from 'api/educationalCentres'

function EducationalCentreSelect({
  value = '',
  onChange,
  label = 'Centro educativo',
  name,
  fullWidth,
  margin = 'dense'
}) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    findEducationalCentres()
      .then(setOptions)
      .catch(e => {
        console.error('Error fetching educational centres: ', e)
      })
  }, [])
  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        <MenuItem key='empty' value=''>
          Sin valor
        </MenuItem>
        {options.map(centre => (
          <MenuItem key={centre._id} value={centre.name}>
            {centre.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default EducationalCentreSelect

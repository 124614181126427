//@ts-check
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Chip } from '@material-ui/core'
import { findLeads } from 'api/leads'
import InformationTable from '../../../components/InformationTable/InformationTable'
import SearchInput from '../../../elements/SearchInput/SearchInput'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import { LeadLabelByStatus } from '../../../shared/constants'

const LeadList = () => {
  const { setErrorMessage } = useMessageSnackbarActions()
  const [leads, setLeads] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    findLeads()
      .then(setLeads)
      .catch(() => setErrorMessage('No se puede mostrar el listado'))
  }, [setErrorMessage])

  return (
    <>
      <div className='common_buttons_container'>
        <Link to='/leads/create'>
          <LoadingButton
            label='Nuevo lead'
            startIcon={<i className='material-icons'>add</i>}
          />
        </Link>
      </div>
      <div className='common_list_filter'>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </div>
      <InformationTable
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email'
          },
          {
            title: 'Estado',
            key: 'status'
          }
        ]}
        data={leads
          .filter(lead => {
            if (!search) return true
            const validName = lead.name
              .toLowerCase()
              .includes(search.toLowerCase())
            const validEmail = lead.email
              .toLowerCase()
              .includes(search.toLowerCase())
            return validName || validEmail
          })
          .map(lead => ({
            _id: lead._id,
            email: lead.email,
            name: <Link to={`/leads/${lead._id}`}>{lead.name}</Link>,
            status: (
              <Chip
                label={LeadLabelByStatus[lead.status] || '-'}
                size='small'
              />
            )
          }))}
      />
    </>
  )
}

export default LeadList

//@ts-check
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Fab,
  Popper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper
} from '@material-ui/core'
import { head, isEmpty, not } from 'ramda'
import { Link } from 'react-router-dom'
import { findSubjects } from 'api/subjects'
import { findStudents } from 'api/students'
import { dateTimeToString, dateToString, toTimeString } from 'utils/date'
import { LeadLabelByStatus } from '../../../shared/constants'
import ContactFormByLeadStatus from './ContactFormByLeadStatus'
import './LeadContacts.css'

const LeadLink = ({ converted }) => {
  const [studentName, setStudentName] = useState(null)
  useEffect(() => {
    if (converted?.studentId)
      findStudents({ _id: converted.studentId }, ['name'])
        .then(({ students }) => students)
        .then(head)
        // @ts-ignore
        .then((s = {}) => setStudentName(s.name))
        .catch(console.error)
  }, [converted])

  return studentName && converted?.studentId ? (
    <GridRow
      label='Alumno'
      data={
        <Link to={`/students/${converted?.studentId}`}>
          <Button color='primary'>{studentName}</Button>
        </Link>
      }
    />
  ) : null
}
const SubjectNameItem = ({ id }) => {
  const [subjectName, setSubjectName] = useState(null)
  useEffect(() => {
    if (id)
      findSubjects({ _id: id }, ['name'])
        .then(head)
        // @ts-ignore
        .then((s = {}) => setSubjectName(s.name))
        .catch(console.error)
  }, [id])
  return subjectName && id ? (
    <GridRow
      label='Asignatura de prueba'
      data={
        <Link to={`/subjects/${id}`}>
          <Button color='primary'>{subjectName}</Button>
        </Link>
      }
    />
  ) : null
}

const GridRow = ({ label, data }) =>
  data ? (
    <>
      <Grid item xs={6}>
        {label}:
      </Grid>
      <Grid item xs={6}>
        {data}
      </Grid>
    </>
  ) : null

const CommonContactData = ({
  date,
  nextDate,
  attendedBy,
  contactType,
  description,
  children = null
}) => {
  return (
    <CardContent>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{ width: '95%', margin: '0 auto' }}
        spacing={2}
      >
        <GridRow label='Fecha de contacto' data={dateToString(date)} />
        <GridRow label='Próximo contacto' data={dateTimeToString(nextDate)} />
        <GridRow label='Atendido por' data={attendedBy} />
        <GridRow label='Tipo de contacto' data={contactType} />
        {children && children}
        <GridRow label='Observaciones' data={description} />
      </Grid>
    </CardContent>
  )
}

export function ContentByLastStatus({ contact }) {
  switch (contact.lastStatus) {
    case 'lead':
      return <CommonContactData {...contact} />
    case 'test': {
      const { test = {} } = contact
      return (
        <CommonContactData {...contact}>
          <GridRow label='Fecha de la prueba' data={dateToString(test.date)} />
          <GridRow
            label='Hora de la asistencia'
            data={toTimeString(test.date)}
          />
          <SubjectNameItem id={test.subject} />
          <GridRow
            label='Prueba confirmada'
            data={test.confirmed ? 'Sí' : ' No'}
          />
        </CommonContactData>
      )
    }
    case 'trained': {
      const { trained = {} } = contact
      return (
        <CommonContactData {...contact}>
          <GridRow label='Presupuesto' data={trained.budget} />
          <GridRow
            label='Fecha de incorporación'
            data={dateTimeToString(trained.startDate)}
          />
        </CommonContactData>
      )
    }
    case 'lost': {
      const { lost = {} } = contact
      return (
        <CommonContactData {...contact}>
          <GridRow label='Causa' data={lost.cause} />
        </CommonContactData>
      )
    }
    case 'invalid': {
      const { invalid = {} } = contact
      return (
        <CommonContactData {...contact}>
          <GridRow label='Causa' data={invalid.cause} />
        </CommonContactData>
      )
    }
    case 'converted': {
      const { converted } = contact
      return (
        <CommonContactData {...contact}>
          <LeadLink converted={converted} />
        </CommonContactData>
      )
    }
    default: {
      console.warn('Unknown lead status: ' + contact.status)
      return null
    }
  }
}

function Contact({ index, contact, onEdit, onRemove, isEditable, anchorRef }) {
  const hideRemoveButton = index === 0
  return (
    <Card className='bordered_shadow card_wrapper' variant='outlined'>
      <CardHeader
        title={`${index + 1}º Contacto - ${
          LeadLabelByStatus[contact.lastStatus]
        }`}
        action={
          isEditable && (
            <div className={!hideRemoveButton ? 'action_buttons' : ''}>
              {!hideRemoveButton && (
                <Fab
                  ref={anchorRef}
                  size='small'
                  color='secondary'
                  onClick={onRemove}
                >
                  <i className='material-icons'>delete</i>
                </Fab>
              )}
              <Fab size='small' color='primary' onClick={onEdit}>
                <i className='material-icons'>edit</i>
              </Fab>
            </div>
          )
        }
      />
      <ContentByLastStatus contact={contact} />
    </Card>
  )
}

function LeadContacts({ contacts = [], onCreate, onEdit, onRemove, isSaving }) {
  const [selectedContact, setSelectedContact] = useState({})
  const [showDialog, setShowDialog] = useState(false)
  const anchorRef = useRef(null)
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)
  const toggleShowDialog = useCallback(() => {
    setShowDialog(not)
    setSelectedContact({})
  }, [])
  const handleSaveContact = useCallback(
    async contact => {
      if (isEmpty(selectedContact)) {
        const success = await onCreate(contact)
        success && toggleShowDialog()
        return
      }
      const success = await onEdit(contacts.length - 1, contact)
      success && toggleShowDialog()
    },
    [contacts.length, onCreate, onEdit, selectedContact, toggleShowDialog]
  )

  const handleSetContactToEdit = useCallback(() => {
    setSelectedContact(contacts[contacts.length - 1])
    setShowDialog(true)
  }, [contacts])

  const handleShowConfirmRemove = useCallback(() => {
    setShowConfirmRemove(true)
  }, [])

  const handleRemove = useCallback(() => {
    onRemove()
    setShowConfirmRemove(false)
  }, [onRemove])

  return (
    <Card>
      <CardContent className='main_card_content'>
        {contacts.map((contact, index) => (
          <Contact
            key={index}
            index={index}
            contact={contact}
            onEdit={handleSetContactToEdit}
            onRemove={handleShowConfirmRemove}
            isEditable={contacts.length - 1 === index}
            anchorRef={anchorRef}
          />
        ))}
        <Fab
          color='primary'
          onClick={toggleShowDialog}
          style={{
            position: 'fixed',
            bottom: '60px',
            right: '40px'
          }}
        >
          <i className='material-icons'>add</i>
        </Fab>
        {showDialog && (
          <ContactFormByLeadStatus
            title={!isEmpty(selectedContact) ? 'Editar contacto' : undefined}
            onSave={handleSaveContact}
            onClose={toggleShowDialog}
            contact={selectedContact}
            isSaving={isSaving}
          />
        )}
        <Popper
          open={showConfirmRemove}
          placement='left-end'
          disablePortal={false}
          anchorEl={anchorRef.current}
          modifiers={{
            flip: {
              enabled: true
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent'
            },
            arrow: {}
          }}
        >
          <Paper>
            <DialogTitle>¿Está seguro?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Esta operación no puede deshacerse
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowConfirmRemove(false)}
                color='secondary'
              >
                Cancelar
              </Button>
              <Button onClick={handleRemove} color='primary'>
                Aceptar
              </Button>
            </DialogActions>
          </Paper>
        </Popper>
      </CardContent>
    </Card>
  )
}

export default LeadContacts

//@ts-check
import React from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { Switch, Route, Link, useHistory } from 'react-router-dom'
import useNotifications from 'hooks/common/useNotifications'
import ListadoProfesores from 'sections/ListadoProfesores/ListadoProfesores'
import ListadoAlumnos from 'sections/ListadoAlumnos/ListadoAlumnos'
import ListadoAdministradores from 'sections/ListadoAdministradores/ListadoAdministradores'
import CrearAlumno from 'sections/CrearAlumno/CrearAlumno'
import AlumnosConectados from 'sections/AlumnosConectados/AlumnosConectados'
import ListadoAsignaturas from 'sections/ListadoAsignaturas/ListadoAsignaturas'
import FichaAsignatura from '../FichaAsignatura/FichaAsignatura'
import LeadList from 'sections/Leads/List/LeadList'
import LeadCreate from 'sections/Leads/Create/LeadCreate'
import LeadEdit from 'sections/Leads/Edit/LeadEdit'
import CRM from 'sections/CRM/CRM'
import ScheduleList from 'sections/Schedule/List/ScheduleList'
import ScheduleCreate from 'sections/Schedule/Create/ScheduleCreate'
import ScheduleEdit from 'sections/Schedule/Edit/ScheduleEdit'
import BudgetMaker from 'sections/Budgets/Maker'
import BudgetList from 'sections/Budgets/List/BudgetList'
import Operations from 'sections/Operations/'
import BudgetPay from 'sections/Budgets/Pay'
import Planning from 'sections/Planning/'
import TicketingList from 'sections/Ticketing/'
import TicketEdit from 'sections/Ticketing/Edit/TicketEdit'
import SpecialRequests from 'sections/SpecialRequest/SpecialRequests'
import FichaAlumno from '../FichaAlumno/FichaAlumno'
import FichaProfesor from '../FichaProfesor/FichaProfesor'
import FichaAdministrador from '../FichaAdministrador/FichaAdministrador'
import CollapseListItem from './CollapseListItem'
import logoClassfy from './resources/logoClassfy.png'
import { dashboardOptions } from './constants'
import styles from './Dashboard.module.css'

const countKey = {
  [dashboardOptions.top.SpecialRequests.to]: 'specialRequestCount'
}

function makeTitle({ title, showCount, count }) {
  if (!showCount || !count) return title
  return title + ` (${count})`
}

const Dashboard = () => {
  const { location } = useHistory()
  const notifications = useNotifications()
  return (
    <>
      <Drawer classes={{ paper: styles.drawer }} variant='permanent'>
        {Object.keys(dashboardOptions).map(key => {
          const items = dashboardOptions[key]
          return (
            <List
              key={key}
              className={key === 'bottom' ? styles.bottom_items : ''}
              style={{ padding: 0 }}
            >
              {Object.keys(items).map(itemKey => {
                const { to, icon, title, showCount, isCollapse, options } =
                  items[itemKey]
                if (
                  process.env.REACT_APP_HIDE_MENU_ITEMS?.split(',').includes(to)
                )
                  return null
                const selected =
                  location.pathname.split('/')[1] === to.split('/')[1]
                if (isCollapse)
                  return (
                    <CollapseListItem
                      key={itemKey}
                      title={title}
                      icon={icon}
                      options={options}
                      selected={selected}
                    />
                  )
                return (
                  <Link key={itemKey} className={styles.link} to={to}>
                    <ListItem button selected={selected} title={title}>
                      <ListItemIcon className={styles.icon}>
                        <i className='material-icons'>{icon}</i>
                      </ListItemIcon>
                      <ListItemText
                        className={styles.text}
                        primary={makeTitle({
                          title,
                          showCount,
                          count: notifications[countKey[to]] || 0
                        })}
                      />
                    </ListItem>
                  </Link>
                )
              })}
            </List>
          )
        })}
      </Drawer>
      <div className={styles.area}>
        <Switch>
          <Route
            exact
            path='/'
            component={() => (
              <div className={styles.dashboard_logo}>
                <img
                  className={styles.logo_classfy}
                  src={logoClassfy}
                  alt='classfy-logo'
                />
              </div>
            )}
          />
          <Route exact path='/students' component={ListadoAlumnos} />
          <Route exact path='/students/create' component={CrearAlumno} />
          <Route exact path='/students/:id' component={FichaAlumno} />

          <Route exact path='/staff/teachers' component={ListadoProfesores} />
          <Route exact path='/staff/teachers/:id' component={FichaProfesor} />
          <Route
            exact
            path='/staff/admins'
            component={ListadoAdministradores}
          />
          <Route
            exact
            path='/staff/admins/:id'
            component={FichaAdministrador}
          />
          <Route exact path='/subjects' component={ListadoAsignaturas} />
          <Route exact path='/subjects/:id' component={FichaAsignatura} />
          <Route
            exact
            path='/online/connections'
            component={AlumnosConectados}
          />
          <Route exact path='/leads' component={LeadList} />
          <Route exact path='/leads/create' component={LeadCreate} />
          <Route exact path='/leads/:id' component={LeadEdit} />
          <Route exact path='/crm' component={CRM} />
          <Route exact path='/schedules' component={ScheduleList} />
          <Route exact path='/schedules/create' component={ScheduleCreate} />
          <Route exact path='/schedules/:id' component={ScheduleEdit} />
          <Route exact path='/budgets' component={BudgetList} />
          <Route exact path='/budgets/:id' component={BudgetMaker} />
          <Route exact path='/budgets/create' component={BudgetMaker} />
          <Route exact path='/operations' component={Operations} />
          <Route exact path='/budgets/pay/:budgetId' component={BudgetPay} />
          <Route exact path='/planning' component={Planning} />
          <Route exact path='/ticketing' component={TicketingList} />
          <Route exact path='/ticketing/:id' component={TicketEdit} />
          <Route exact path='/special-requests' component={SpecialRequests} />
        </Switch>
      </div>
    </>
  )
}

export default Dashboard

//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { assocPath } from 'ramda'
import { useParams } from 'react-router-dom'
import {
  getDefaultTeacherSchedule,
  createTeacherSchedule,
  updateTeacherSchedule
} from 'api/teacherSchedules'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import { extractRangeHoursAndMinutes } from '../../../utils'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import Spinner from '../../../elements/Spinner/Spinner'
import ConfirmModal from '../../../shared/ConfirmModal'
import './DefaultTeacherSchedule.css'

const defaultHours = [
  '07:00 - 08:00',
  '08:00 - 09:00',
  '09:00 - 10:00',
  '10:00 - 11:00',
  '11:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 14:00',
  '14:00 - 15:00',
  '15:00 - 16:00',
  '16:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 19:00',
  '19:00 - 20:00',
  '20:00 - 21:00',
  '21:00 - 22:00',
  '22:00 - 23:00'
]

const defaultHalfHours = [
  '07:30 - 08:30',
  '08:30 - 09:30',
  '09:30 - 10:30',
  '10:30 - 11:30',
  '11:30 - 12:30',
  '12:30 - 13:30',
  '13:30 - 14:30',
  '14:30 - 15:30',
  '15:30 - 16:30',
  '16:30 - 17:30',
  '17:30 - 18:30',
  '18:30 - 19:30',
  '19:30 - 20:30',
  '20:30 - 21:30',
  '21:30 - 22:30',
  '22:30 - 23:30'
]
const defaultRows = [
  { day: 'Lunes', hours: [], halfHours: [] },
  { day: 'Martes', hours: [], halfHours: [] },
  { day: 'Miércoles', hours: [], halfHours: [] },
  { day: 'Jueves', hours: [], halfHours: [] },
  { day: 'Viernes', hours: [], halfHours: [] },
  { day: 'Sábado', hours: [], halfHours: [] },
  { day: 'Domingo', hours: [], halfHours: [] }
]
function DefaultTeacherSchedule() {
  const [teacherScheduleId, setTeacherScheduleId] = useState(null)
  const [schedule, setSchedule] = useState(defaultRows)
  const [isFetching, setIsFetching] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  // @ts-ignore
  const { id: teacherId } = useParams()
  const {
    setErrorMessage,
    setInfoMessage,
    setSuccessMessage,
    setWarningMessage
  } = useMessageSnackbarActions()

  const handleCheck = useCallback(({ dayPosition, hour, key, checked }) => {
    if (checked) {
      return setSchedule(state => {
        const { minHour: hourToCheck } = extractRangeHoursAndMinutes(hour)
        const hoursToCheck =
          state[dayPosition][key === 'hours' ? 'halfHours' : 'hours']
        for (let i = 0; i < hoursToCheck.length; i++) {
          const { minHour } = extractRangeHoursAndMinutes(hoursToCheck[i])
          if (minHour === hourToCheck) return state
        }
        return assocPath(
          [dayPosition, key],
          [...state[dayPosition][key], hour],
          state
        )
      })
    }
    // @ts-ignore
    return setSchedule(state => {
      return assocPath(
        [dayPosition, key],
        state[dayPosition][key].filter(h => h !== hour),
        state
      )
    })
  }, [])

  const handleSave = useCallback(() => {
    if (teacherScheduleId) return setShowConfirmModal(true)

    setIsSaving(true)
    createTeacherSchedule({ teacherId, schedule })
      .then(data => {
        setSuccessMessage()
        setTeacherScheduleId(data.id)
        setSchedule(data.schedule)
      })
      .catch(e => {
        console.error('Error creating default schedule: ', e)
        setErrorMessage('Error creando el horario por defecto: ' + e.message)
      })
      .finally(() => setIsSaving(false))
  }, [
    schedule,
    setErrorMessage,
    setSuccessMessage,
    teacherId,
    teacherScheduleId
  ])

  const handleConfirmEdit = useCallback(() => {
    setIsSaving(true)
    updateTeacherSchedule(teacherScheduleId, { teacherId, schedule })
      .then(data => {
        setSuccessMessage(
          data.canceledAttendancesHistoryId
            ? 'Se han cancelado asistencias'
            : undefined
        )
      })
      .catch(e => {
        console.error('Error updating default schedule: ', e)
        setErrorMessage(
          'Error actualizando el horario por defecto: ' + e.message
        )
      })
      .finally(() => {
        setIsSaving(false)
        setShowConfirmModal(false)
      })
  }, [
    schedule,
    setErrorMessage,
    setSuccessMessage,
    teacherId,
    teacherScheduleId
  ])

  useEffect(() => {
    getDefaultTeacherSchedule(teacherId)
      .then(data => {
        if (!data)
          return setWarningMessage('No se ha encontrado un horario por defecto')
        setInfoMessage('Se ha obtenido un horario por defecto')
        setTeacherScheduleId(data.id)
        setSchedule(data.schedule)
      })
      .catch(e => {
        console.error('Error getting default schedule: ', e)
        setErrorMessage(
          'Ha habido un error obteniendo el horario por defecto: ' + e.message
        )
      })
      .finally(() => setIsFetching(false))
  }, [setErrorMessage, setInfoMessage, setWarningMessage, teacherId])
  return (
    <>
      <Card>
        <CardHeader
          title={
            <Alert severity='info'>
              <AlertTitle>Info</AlertTitle>
              Si existe un horario por fecha se usará ese horario en lugar del
              horario por defecto
            </Alert>
          }
        />
        <CardContent className='teacher_schedule_card_content'>
          {isFetching && <Spinner />}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align='center'>Horas en punto</TableCell>
                  <TableCell align='center'>Horas y media</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedule.map(({ day, hours, halfHours }, dayPosition) => (
                  <TableRow key={day}>
                    <TableCell>{day}</TableCell>
                    <TableCell style={{ display: 'flex' }}>
                      <div style={{ display: 'table' }}>
                        {defaultHours.map(hour => (
                          <FormControlLabel
                            key={hour}
                            name={hour}
                            control={
                              <Checkbox
                                checked={hours.includes(hour)}
                                onChange={e =>
                                  handleCheck({
                                    dayPosition,
                                    key: 'hours',
                                    hour: e.target.name,
                                    checked: e.target.checked
                                  })
                                }
                              />
                            }
                            label={hour}
                          />
                        ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'table' }}>
                        {defaultHalfHours.map(hour => (
                          <FormControlLabel
                            key={hour}
                            name={hour}
                            control={
                              <Checkbox
                                checked={halfHours.includes(hour)}
                                onChange={e =>
                                  handleCheck({
                                    dayPosition,
                                    key: 'halfHours',
                                    hour: e.target.name,
                                    checked: e.target.checked
                                  })
                                }
                              />
                            }
                            label={hour}
                          />
                        ))}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions className='card_wrapper_action'>
          <LoadingButton
            label='Guardar'
            onClick={handleSave}
            isLoading={isSaving}
            disabled={isFetching}
            startIcon={<i className='material-icons'>save</i>}
          />
        </CardActions>
      </Card>
      {showConfirmModal && (
        <ConfirmModal
          subtitle='Se cancelarán las asistencias planificadas asociadas a las horas eliminadas, si aplicase'
          onOk={handleConfirmEdit}
          onCancel={() => setShowConfirmModal(false)}
          isLoading={isSaving}
          showModal
        />
      )}
    </>
  )
}

export default DefaultTeacherSchedule

//@ts-check
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Chip, TablePagination } from '@material-ui/core'
import { assoc } from 'ramda'
import { findStudents } from 'api/students'
import { dateToString, dateFromString } from 'utils/date'
import InformationTable from '../../components/InformationTable/InformationTable'
import SearchInput from '../../elements/SearchInput/SearchInput'
import LoadingButton from '../../elements/LoadingButton/LoadingButton'
import StatusIcon from '../../shared/StatusIcon'
import Spinner from '../../elements/Spinner/Spinner'
import { useMessageSnackbarActions } from '../../elements/MessageContext/MessageContext'
import { AccessTypeLabels } from '../../shared/constants'
import styles from './ListadoAlumnos.module.css'

const initialPagination = { page: 1, pageSize: 15 }
const project = [
  'id',
  'name',
  'email',
  'lastSuccessfullLogin',
  'active',
  'accessType'
]

const ListadoAlumnos = () => {
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState(initialPagination)
  const { setErrorMessage } = useMessageSnackbarActions()
  const query = useMemo(() => {
    if (search)
      return {
        useCount: true,
        $or: [
          { key: 'name', search: search, isRegExp: true },
          { key: 'email', search: search, isRegExp: true }
        ]
      }
    return { useCount: true }
  }, [search])
  const { isFetching, data = {} } = useQuery(
    ['student-list', search, pagination],
    () => findStudents(query, project, pagination),

    {
      refetchOnWindowFocus: false,
      onError(e) {
        setErrorMessage()
        console.error('Error fetching students: ', e)
      }
    }
  )
  const { students = [], count = 0 } = data

  const handleSearch = useCallback(e => {
    setSearch(e.target.value)
    setPagination(initialPagination)
  }, [])

  const handleChangePage = useCallback((_, page) => {
    setPagination(assoc('page', page + 1))
  }, [])

  if (isFetching) return <Spinner />
  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput
          value={search}
          onChange={handleSearch}
          useDebounced
          autoFocus
        />
        <Link to='/students/create'>
          <LoadingButton
            label='Nuevo alumno'
            // @ts-ignore
            startIcon={<i className='material-icons'>add</i>}
          />
        </Link>
      </div>

      <InformationTable
        className={styles.table}
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email',
            // @ts-ignore
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          { title: 'Tipo', key: 'accessType', sortDisabled: true },
          { title: 'Último Login', key: 'lastLogin', sortDisabled: true },
          {
            title: 'Activo',
            key: 'active',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={students.map(student => ({
          email: student.email,
          name: <Link to={`/students/${student.id}`}>{student.name}</Link>,
          accessType: (
            <Chip
              label={AccessTypeLabels[student.accessType]}
              color={student.accessType === 'student' ? 'primary' : undefined}
            />
          ),
          lastLogin: student.lastSuccessfullLogin
            ? dateToString(student.lastSuccessfullLogin)
            : '--',
          active: <StatusIcon active={student.active} />
        }))}
        size='small'
      />
      <TablePagination
        rowsPerPageOptions={[]}
        component='div'
        count={count}
        rowsPerPage={pagination.pageSize}
        page={pagination.page - 1}
        onPageChange={handleChangePage}
      />
    </div>
  )
}

export default ListadoAlumnos

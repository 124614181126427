//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { assoc, compose, pipe, prop, sortBy, toLower } from 'ramda'
import { CircularProgress } from '@material-ui/core'
import { findLeads } from 'api/leads'
import { findStudents } from 'api/students'
import ConfirmModal from '../../../shared/ConfirmModal'
import useFormManager from '../../../hooks/common/useFormManager'
import AutocompleteSelect from '../../../shared/AutocompleteSelect'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import { AccessTypes, SubscriptionConfigTypes } from '../../../shared/constants'
import { useSubscriptionManager } from './context/SubscriptionManagerProvider'
import DiscountSelect from './DiscountSelect'
import BudgetPrint from './BudgetPrint'
import './BudgetModal.css'

const REQUIRED_FIELDS = ['userType', 'userId']
const radioOptions = [
  { value: 'lead', label: 'Nuevo alumno' },
  { value: 'student', label: 'Alumno registrado' }
]
const initialState = {
  userType: radioOptions[0].value,
  userId: ''
}

function makeLabel(user) {
  return `${user.name} (${user.email})`
}
function BudgetModal() {
  const {
    accepted,
    targetUserId,
    paymentWithEnrollment,
    selectedDiscountIds,
    selectedConfig,
    handlePrint,
    handleSaveBudget,
    toggleShowModal,
    calculateDiscount,
    handleSelectDiscount
  } = useSubscriptionManager()
  const { setErrorMessage } = useMessageSnackbarActions()
  const [state, setState] = useState({
    leads: [],
    students: [],
    calculatedPrice: 0,
    isSaving: false,
    isCalculating: false
  })
  const { form, hasPendingRequiredFields, handleFormChange } = useFormManager(
    REQUIRED_FIELDS,
    initialState
  )
  const handleChangeUserType = useCallback(
    e => {
      handleFormChange(e)
      handleFormChange({ target: { name: 'userId', value: '' } })
    },
    [handleFormChange]
  )
  const handleConfirm = useCallback(async () => {
    setState(assoc('isSaving', true))
    await handleSaveBudget(form)
    setState(assoc('isSaving', false))
  }, [form, handleSaveBudget])

  const fetchUser = useCallback(async () => {
    try {
      const leads = await findLeads({ status: 'trained' }, ['name', 'email'])
      const { students } = await findStudents(
        { accessType: AccessTypes.STUDENT },
        ['name', 'email', 'enrolledUntil']
      )
      // @ts-ignore
      const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')))
      setState(
        // @ts-ignore
        pipe(
          assoc(
            'leads',
            sortByNameCaseInsensitive(leads).map(s => ({
              ...s,
              label: makeLabel(s)
            }))
          ),
          assoc(
            'students',
            sortByNameCaseInsensitive(students).map(s => ({
              ...s,
              label: makeLabel(s)
            }))
          )
        )
      )
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }, [])
  const handleCancel = useCallback(() => {
    toggleShowModal()
    !targetUserId && handleSelectDiscount([])
  }, [handleSelectDiscount, targetUserId, toggleShowModal])

  const student = useMemo(() => {
    if (targetUserId)
      return (
        state.students.find(({ id }) => id === targetUserId) ||
        state.leads.find(({ id }) => id === targetUserId) ||
        {}
      )
    return state.students.find(({ id }) => id === form.userId) || {}
  }, [form.userId, state.leads, state.students, targetUserId])

  const userName = useMemo(() => {
    if (!form.userId) return student.name
    switch (form.userType) {
      case 'lead':
        return state.leads.find(({ id }) => id === form.userId)?.name

      default:
        return state.students.find(({ id }) => id === form.userId)?.name
    }
  }, [form.userId, form.userType, state.leads, state.students, student])

  const hasActiveEnrollment = useMemo(() => {
    if (targetUserId && accepted) return !paymentWithEnrollment
    if (!student?.enrolledUntil) return false
    return new Date() <= new Date(student.enrolledUntil)
  }, [accepted, paymentWithEnrollment, student, targetUserId])

  const applyDiscount = useCallback(async () => {
    setState(assoc('isCalculating', true))
    await calculateDiscount(hasActiveEnrollment)
      .then(data => setState(assoc('calculatedPrice', data)))
      .catch(e => {
        setErrorMessage(e.message)
        console.error('Error calculating discount:', e)
      })
      .finally(() => setState(assoc('isCalculating', false)))
  }, [calculateDiscount, hasActiveEnrollment, setErrorMessage])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])
  useEffect(() => {
    applyDiscount()
  }, [applyDiscount])

  const totalResume = useMemo(() => {
    if (state.isCalculating)
      return (
        <>
          <CircularProgress />
          <p>Calculando</p>
        </>
      )
    if (selectedDiscountIds.length)
      return (
        state.calculatedPrice > 0 &&
        `Total con descuento: ${state.calculatedPrice}€`
      )

    return state.calculatedPrice > 0 && `Total: ${state.calculatedPrice}€`
  }, [selectedDiscountIds, state.calculatedPrice, state.isCalculating])
  return (
    <ConfirmModal
      title='Presupuestar'
      subtitle='Puede descargar el presupuesto antes de confirmarlo'
      onOk={handleConfirm}
      onCancel={handleCancel}
      okDisabled={
        hasPendingRequiredFields ||
        (selectedConfig.type === SubscriptionConfigTypes.extraHours &&
          !hasActiveEnrollment)
      }
      okText='Guardar presupuesto'
      isLoading={state.isSaving}
      showModal
    >
      <div className='main_container'>
        {!targetUserId && (
          <div>
            <FormControl component='fieldset'>
              <RadioGroup
                name='userType'
                value={form.userType}
                onChange={handleChangeUserType}
                row
              >
                {radioOptions.map(({ label, value }, i) => (
                  <FormControlLabel
                    key={i}
                    value={value}
                    control={<Radio />}
                    label={label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        )}
        <div className='user_selector'>
          {!targetUserId && (
            <>
              {initialState.userType === form.userType ? (
                <AutocompleteSelect
                  name='userId'
                  value={form.userId}
                  title='Lead Cualificado'
                  options={state.leads}
                  labelKey='label'
                  onChange={handleFormChange}
                  fullWidth
                />
              ) : (
                <AutocompleteSelect
                  name='userId'
                  value={form.userId}
                  title='Alumno'
                  options={state.students}
                  labelKey='label'
                  onChange={handleFormChange}
                  fullWidth
                />
              )}
            </>
          )}
          <DiscountSelect
            value={selectedDiscountIds}
            onChange={e => handleSelectDiscount(e.target.value)}
            disabled={!!targetUserId}
          />
        </div>
        {!targetUserId ? (
          <div className='payment_details'>
            <p>
              {hasActiveEnrollment
                ? 'Tiene matrícula activa'
                : 'No tiene matrícula activa'}
            </p>
            <p>{totalResume}</p>
          </div>
        ) : accepted ? (
          <div className='payment_details'>
            <p>
              {paymentWithEnrollment
                ? 'Se incluyó matrícula'
                : 'No se incluyó matrícula'}
            </p>
            <p>{totalResume}</p>
          </div>
        ) : (
          <div className='payment_details'>
            <p>
              {!hasActiveEnrollment
                ? 'Se incluye matrícula'
                : 'No se incluye matrícula'}
            </p>
            <p>{totalResume}</p>
          </div>
        )}
        <div>
          <BudgetPrint userName={userName} />
          <Button color='primary' size='small' onClick={handlePrint}>
            Previsualizar presupuesto
          </Button>
        </div>
      </div>
    </ConfirmModal>
  )
}

export default BudgetModal

export const LeadLabelByStatus = {
  lead: 'Lead',
  test: 'Prueba',
  trained: 'Cualificado',
  lost: 'Perdido',
  invalid: 'Inválido',
  converted: 'Captado'
}
export const LeadStatus = {
  lead: 'lead',
  test: 'test',
  trained: 'trained',
  lost: 'lost',
  invalid: 'invalid',
  converted: 'converted'
}

export const SubjectTypes = {
  CIENCIAS: 'Ciencias',
  LETRAS: 'Letras'
}

export const SubjectLevels = {
  PRIMARIA: 'Primaria',
  SECUNDARIA: 'Secundaria',
  BACHILLERATO: 'Bachillerato',
  FP: 'FP',
  UNIVERSIDAD: 'Universidad',
  IDIOMAS: 'Idiomas',
  OTRAS: 'Otras'
}
export const SubjectSublevels = {
  Idiomas: { A1: 'A1', A2: 'A2', B1: 'B1', B2: 'B2', C1: 'C1', C2: 'C2' },
  Secundaria: { Internacional: 'Internacional' },
  Bachillerato: { Internacional: 'Internacional' }
}

export const SubscriptionTypes = {
  pack: 'pack',
  rate: 'rate'
}
export const SubscriptionTypeLabels = {
  pack: 'Pack',
  rate: 'Tarifa'
}
export const SubscriptionConfigTypes = {
  extraHours: 'extraHours',
  pack: 'pack',
  prepareMornings: 'prepareMornings',
  unlimited: 'unlimited',
  reinforces: 'reinforces',
  agora: 'agora'
}

export const AttendanceTypes = {
  NORMAL: 'normal',
  RECOVERY: 'recovery',
  FREE: 'free',
  PROMOTION: 'promotion',
  TEST: 'test'
}
export const AttendanceTypeLabels = {
  normal: 'Normal',
  recovery: 'Recuperación',
  free: 'Gratuita',
  promotion: 'Promoción',
  test: 'Prueba'
}
export const AttendanceStatus = {
  PLANNED: 'planned',
  IMPARTED: 'imparted',
  JUSTIFIED_ABSENCE: 'justified_absence',
  ABSENCE: 'absence',
  CANCELED_BY_APP: 'canceled_by_app'
}
export const AttendanceStatusLabels = {
  planned: 'Planificada',
  imparted: 'Impartida',
  justified_absence: 'Falta jusificada',
  absence: 'Falta',
  canceled_by_app: 'Cancelado por el sistema'
}
export const AttendanceSources = {
  LEAD: 'lead',
  STUDENT: 'student'
}

export const TicketStatus = {
  OPEN: 'open',
  CLOSED: 'closed'
}

export const TicketStatusLabels = {
  open: 'Abierto',
  closed: 'Cerrado'
}

export const AccessTypes = {
  STUDENT: 'student',
  TRIAL: 'trial',
  INTERNAL: 'internal'
}
export const AccessTypeLabels = {
  student: 'Alumno',
  trial: 'Prueba',
  internal: 'Prueba mundoestudiante'
}

export const TicketTypes = {
  REGISTER: 'register',
  APP_HELP: 'appHelp',
  BUDGET: 'budget',
  QUESTION: 'question'
}

export const TicketTypeLabels = {
  register: 'Registro',
  appHelp: 'Ayuda',
  budget: 'Propuesta',
  question: 'Duda general'
}

export const EvaluationServices = {
  NORMAL: 'normal',
  MORNINGS: 'mornings',
  PACK: 'pack',
  OTHERS: 'others'
}
export const EvaluationServiceLabels = {
  normal: 'Tarifa normal',
  mornings: 'Tarifa mañanas',
  pack: 'Pack de horas',
  others: 'Otros'
}

//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { assoc, isEmpty, omit, propEq } from 'ramda'
import {
  createTestAttendance,
  findAttendanceDTOs,
  getTestAttendanceCount,
  saveAttendance,
  updateAttendance
} from 'api/attendances'
import { getStudentDetails, findSubscriptionsByStudent } from 'api/students'
import {
  getMonthDaysInfo,
  isSameDay,
  MONTHS,
  DAY_NAME_BY_NUMBER,
  toTimeString,
  dateToString,
  makeCustomDate
} from 'utils/date'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import Spinner from '../../../elements/Spinner/Spinner'
import DefaultSelect from '../../../shared/DefaultSelect'
import {
  AccessTypes,
  AttendanceStatusLabels,
  AttendanceTypeLabels,
  AttendanceTypes
} from '../../../shared/constants'
import AttendanceSaveModal from './ui/AttendanceSaveModal'
import TestAttendanceSaveModal from './ui/TestAttendanceSaveModal'
import './Attendances.css'

const STUDENT_TEST_ATTENDANCE_LIMIT = 2
const MAX_VISIBLE_LENGTH = 12
function getVisibleSubjectName(name = '-') {
  if (name.length < MAX_VISIBLE_LENGTH) return name
  return name.substring(0, MAX_VISIBLE_LENGTH) + '...'
}

const DayCell = ({ day, selectedMonth, attendances, onEdit }) => {
  let dayClassName = 'date_identifier'
  let cellClassName = 'calendar_day'
  if (day.getMonth() === selectedMonth) dayClassName += ' active'
  if (isSameDay(new Date(), day)) {
    dayClassName += ' today'
    cellClassName += ' today'
  }
  return (
    <td className={cellClassName}>
      <div className={dayClassName}>{day.getDate()}</div>

      {attendances.map((attendance, i) => {
        const isSelectable = attendance.type !== AttendanceTypes.TEST
        if (isSameDay(new Date(attendance.classStart), day)) {
          return (
            <div
              key={i}
              className={`calendar_day --${attendance.type} ${
                isSelectable ? 'is_selectable' : ''
              }`}
              onClick={() => isSelectable && onEdit(attendance.id)}
            >
              <p className='calendar_day-title'>
                <Tooltip title={AttendanceStatusLabels[attendance.status]}>
                  <span
                    className={`attendance_circle --${attendance.status}`}
                  />
                </Tooltip>
                <Tooltip
                  title={`${attendance.subjectName || '-'} (${
                    attendance.subjectLevel || '-'
                  })`}
                >
                  <span>{getVisibleSubjectName(attendance.subjectName)}</span>
                </Tooltip>
                <span className='calendar_day-title-level'>
                  ({attendance.subjectLevel?.substring(0, 4 || '-')})
                </span>
              </p>
              <Tooltip
                title={<span>{AttendanceTypeLabels[attendance.type]}</span>}
              >
                <p className='calendar_day-subtitle'>
                  {toTimeString(attendance.classStart)}-
                  {toTimeString(attendance.classEnd)}
                </p>
              </Tooltip>
            </div>
          )
        }
        return null
      })}
    </td>
  )
}
const WeekRow = ({ week, selectedMonth, attendances, onEdit }) => {
  return (
    <tr className='calendar_week'>
      {week.map((day, i) => (
        <DayCell
          onEdit={onEdit}
          key={i}
          day={day}
          selectedMonth={selectedMonth}
          attendances={attendances}
        />
      ))}
    </tr>
  )
}

function Attendances() {
  const [state, setState] = useState({
    student: {},
    testAttendanceCount: 0,
    selectedYear: new Date().getFullYear(),
    selectedMonth: new Date().getMonth(),
    isFetching: false,
    attendances: [],
    subscriptions: [],
    subscriptionId: '',
    selectedAttendanceId: null,
    showModal: false,
    showTestModal: false,
    isSaving: false
  })
  // @ts-ignore
  const { id: studentId } = useParams()
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()
  const {
    student,
    testAttendanceCount,
    selectedYear,
    selectedMonth,
    attendances,
    showModal,
    showTestModal,
    isSaving,
    isFetching,
    subscriptions,
    subscriptionId,
    selectedAttendanceId
  } = state

  const weeks = useMemo(
    () => getMonthDaysInfo(selectedYear, selectedMonth),
    [selectedYear, selectedMonth]
  )
  const selectedAttendance = useMemo(() => {
    if (!selectedAttendanceId) return {}
    return attendances.find(propEq('id', selectedAttendanceId))
  }, [attendances, selectedAttendanceId])

  const fetchData = useCallback(async () => {
    try {
      setState(assoc('isFetching', true))
      const attendances = await findAttendanceDTOs({
        // @ts-ignore
        visibleByDate: new Date(selectedYear, selectedMonth),
        studentId,
        subscriptionId
      })
      const student = await getStudentDetails({ studentId })
      const testAttendanceCount = await getTestAttendanceCount(studentId)
      setState(state => ({
        ...state,
        attendances,
        student,
        testAttendanceCount,
        isFetching: false
      }))
    } catch (error) {
      console.error('Error fetching attendances: ', error)
      setState(assoc('isFetching', false))
    }
  }, [selectedMonth, selectedYear, studentId, subscriptionId])

  const changeMonth = useCallback(
    amount => {
      let newYear = selectedYear
      let newMonth = selectedMonth + amount
      if (newMonth === 12) {
        newMonth = 0
        newYear++
      } else if (newMonth === -1) {
        newMonth = 11
        newYear--
      }
      setState(state => ({
        ...state,
        selectedYear: newYear,
        selectedMonth: newMonth
      }))
    },
    [selectedMonth, selectedYear]
  )

  const toggleShowTestModal = useCallback(() => {
    setState(s => ({
      ...s,
      showTestModal: !s.showTestModal
    }))
  }, [])

  const toggleShowModal = useCallback(() => {
    setState(s => ({
      ...s,
      showModal: !s.showModal,
      selectedAttendanceId: !isEmpty(selectedAttendance)
        ? null
        : s.selectedAttendanceId
    }))
  }, [selectedAttendance])

  const handleSaveTestAttendance = useCallback(
    testAttendanceData => {
      setState(assoc('isSaving', true))

      createTestAttendance({
        ...testAttendanceData,
        studentId
      })
        .then(attendance => {
          setSuccessMessage('Asistencia guardada correctamente')
          setState(state => ({
            ...state,
            isSaving: false,
            showTestModal: false
          }))
          fetchData()
        })
        .catch(e => {
          setState(assoc('isSaving', false))
          setErrorMessage(e.message)
          console.error('Error saving attendance: ', e)
        })
    },
    [fetchData, setErrorMessage, setSuccessMessage, studentId]
  )

  const handleSave = useCallback(
    attendanceData => {
      setState(assoc('isSaving', true))
      const data = {
        ...omit(['date', 'startTime', 'endTime'], attendanceData),
        classStart: makeCustomDate(
          attendanceData.date,
          attendanceData.startTime
        ),
        classEnd: makeCustomDate(attendanceData.date, attendanceData.endTime),
        studentId
      }
      const saveFunction = selectedAttendanceId
        ? updateAttendance(selectedAttendanceId, data)
        : saveAttendance(data)
      saveFunction
        .then(attendance => {
          setSuccessMessage('Asistencia guardada correctamente')
          setState(state => ({
            ...state,
            isSaving: false,
            showModal: false,
            selectedAttendanceId: null
          }))
          fetchData()
        })
        .catch(e => {
          setState(assoc('isSaving', false))
          setErrorMessage(e.message)
          console.error('Error saving attendance: ', e)
        })
    },
    [
      fetchData,
      selectedAttendanceId,
      setErrorMessage,
      setSuccessMessage,
      studentId
    ]
  )
  const handleEdit = useCallback(
    attendanceId => {
      setState(assoc('selectedAttendanceId', attendanceId))
      toggleShowModal()
    },
    [toggleShowModal]
  )
  useEffect(() => {
    findSubscriptionsByStudent(studentId)
      .then(subscriptions =>
        setState(state => ({
          ...state,
          subscriptions: subscriptions.map(s => {
            s.customLabel = `${s.subscriptionName} (${dateToString(
              s.startDate
            )})`
            return s
          })
        }))
      )
      .catch(e => {
        console.error('Error getting subscriptions by student: ', e)
      })
  }, [studentId])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const testButtonDisabled =
    testAttendanceCount >= STUDENT_TEST_ATTENDANCE_LIMIT

  if (isFetching) return <Spinner />

  return (
    <>
      <div className='calendar_header'>
        <DefaultSelect
          name='subscriptionId'
          value={subscriptionId || ''}
          title='Suscripción'
          options={subscriptions}
          labelKey='customLabel'
          onChange={e =>
            setState(state => ({ ...state, subscriptionId: e.target.value }))
          }
        />
        <div className='calendar_toolbar'>
          {student.accessType === AccessTypes.TRIAL && (
            <Tooltip
              title={
                testButtonDisabled ? 'Ha alcanzado el límite de pruebas' : ''
              }
            >
              <div>
                <LoadingButton
                  label='Añadir prueba'
                  startIcon={<i className='material-icons'>add</i>}
                  onClick={toggleShowTestModal}
                  disabled={testButtonDisabled}
                />
              </div>
            </Tooltip>
          )}
          <LoadingButton
            label='Añadir asistencia'
            startIcon={<i className='material-icons'>add</i>}
            onClick={toggleShowModal}
          />
        </div>
      </div>
      <div className='calendar_container'>
        <div>
          <div>
            <IconButton onClick={() => changeMonth(-1)}>
              <i className='material-icons'>arrow_back</i>
            </IconButton>
            <h2 className='calendar_month_name'>{`${MONTHS[selectedMonth]} de ${selectedYear}`}</h2>
            <IconButton onClick={() => changeMonth(1)}>
              <i className='material-icons'>arrow_forward</i>
            </IconButton>
          </div>
          <table className='calendar_element'>
            <thead>
              <tr>
                {DAY_NAME_BY_NUMBER.map(({ label, day }) => {
                  return (
                    <th key={day} className='calendar_element_header'>
                      {label}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, i) => (
                <WeekRow
                  onEdit={handleEdit}
                  key={i}
                  week={week}
                  selectedMonth={selectedMonth}
                  attendances={attendances}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <AttendanceSaveModal
          studentId={studentId}
          isCreateMode={isEmpty(selectedAttendance)}
          attendance={selectedAttendance}
          onToggleShowModal={toggleShowModal}
          isSaving={isSaving}
          onSave={handleSave}
        />
      )}
      {showTestModal && (
        <TestAttendanceSaveModal
          attendance={selectedAttendance}
          onToggleShowModal={toggleShowTestModal}
          isSaving={isSaving}
          isSecondTestAttendance={
            testAttendanceCount == STUDENT_TEST_ATTENDANCE_LIMIT - 1
          }
          onSave={handleSaveTestAttendance}
        />
      )}
    </>
  )
}

export default Attendances

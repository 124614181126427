//@ts-check
import React, { useCallback, useState } from 'react'
import { assoc } from 'ramda'
import { useHistory } from 'react-router-dom'
import { createAcademicSchedule } from 'api/academicSchedules'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import ScheduleTable from '../ui/ScheduleTable'

function ScheduleCreate() {
  const history = useHistory()
  const [state, setState] = useState({ isSaving: false })
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleSave = useCallback(
    schedule => {
      setState(assoc('isSaving', true))
      createAcademicSchedule(schedule)
        .then(() => {
          setState(assoc('isSaving', false))
          history.push('/schedules')
          setSuccessMessage()
        })
        .catch(e => {
          setState(assoc('isSaving', false))
          console.error('Error creating schedule: ', e)
          setErrorMessage(e.message)
        })
    },
    [history, setErrorMessage, setSuccessMessage]
  )

  return (
    <ScheduleTable
      title='Añadir nuevo horario'
      onSave={handleSave}
      isSaving={state.isSaving}
    />
  )
}

export default ScheduleCreate

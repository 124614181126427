//@ts-check
import React from 'react'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import { SubjectTypes, SubjectLevels } from '../../shared/constants'
import styles from './AttendanceHourSelector.module.css'
const DAY_HOURS = Array.from({ length: 24 }, (v, i) => i)

function AttendanceHourSelector({
  selectedHour,
  attendancesByHour = {},
  onSelectHour
}) {
  const targetHours = Object.keys(attendancesByHour).sort()
  return (
    <AppBar color='secondary' position='static'>
      <Tabs
        value={selectedHour}
        onChange={(_, value) => onSelectHour(value)}
        variant='scrollable'
        scrollButtons='auto'
        indicatorColor='primary'
        centered={true}
      >
        {targetHours.map(stringHour => {
          const attendances = attendancesByHour[stringHour] || []
          const sciences = attendances.filter(
            a => a.subjectType === SubjectTypes.CIENCIAS
          ).length
          const literature = attendances.filter(
            a => a.subjectType === SubjectTypes.LETRAS
          ).length
          const languages = attendances.filter(
            a => a.subjectLevel === SubjectLevels.IDIOMAS
          ).length
          const [_hour, _minutes] = stringHour.split(':')
          return (
            <Tab
              key={stringHour}
              value={stringHour}
              label={
                <>
                  <div className={styles.tabLabel}>{`${stringHour} - ${
                    Number(_hour) + 1
                  }:${_minutes} ${
                    attendances.length ? `(${attendances.length})` : ''
                  } `}</div>
                  <div className={styles.avatarTypeContainer}>
                    {sciences > 0 && (
                      <div
                        className={[styles.avatarType, styles.sciences].join(
                          ' '
                        )}
                      >
                        {sciences}
                      </div>
                    )}
                    {literature > 0 && (
                      <div
                        className={[styles.avatarType, styles.literature].join(
                          ' '
                        )}
                      >
                        {literature}
                      </div>
                    )}
                    {languages > 0 && (
                      <div
                        className={[styles.avatarType, styles.languages].join(
                          ' '
                        )}
                      >
                        {languages}
                      </div>
                    )}
                  </div>
                </>
              }
            />
          )
        })}
      </Tabs>
    </AppBar>
  )
}

export default AttendanceHourSelector

//@ts-check
import React from 'react'
import { Card, CardHeader, Grid } from '@material-ui/core'
import './SubjectCard.css'

function SubjectCard({ title, subheader, onSelectSubject, isSelected }) {
  return (
    <Grid item xs={3} onClick={onSelectSubject}>
      <Card elevation={3}>
        <div className={isSelected ? 'selected_card' : 'subject_card'}>
          <CardHeader title={title} subheader={subheader} />
        </div>
      </Card>
    </Grid>
  )
}

export default SubjectCard

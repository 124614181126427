//@ts-check
import React from 'react'
import { Card, CardHeader, CardContent, Chip, Divider } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Draggable } from 'react-beautiful-dnd'
import './ContentColumnList.css'

function ContentColumnList({ innerRef, leads, isDraggingOver, ...rest }) {
  return (
    <>
      <CardContent
        innerRef={innerRef}
        className={
          isDraggingOver
            ? 'card_content_style mark_column_as_selected'
            : 'card_content_style'
        }
        {...rest}
      >
        {leads.map((lead, index) => {
          return (
            <Draggable key={lead._id} draggableId={lead._id} index={index}>
              {(provided, snapshot) => {
                return (
                  <Card
                    elevation={4}
                    className={
                      snapshot.isDragging
                        ? `custom_card mark_card_as_selected ${lead.lastStatus}`
                        : `custom_card ${lead.lastStatus}`
                    }
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    innerRef={provided.innerRef}
                  >
                    <CardHeader
                      title={
                        <Link className='link_hover' to={`/leads/${lead._id}`}>
                          {lead.name}
                          <Divider />
                        </Link>
                      }
                      className={lead.cssState}
                    />
                    <CardContent>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          Teléfono:
                        </span>
                        <p>{lead.phoneNumber}</p>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          {lead.dateLabel}
                        </span>
                        <br />
                        <p>{lead.date}</p>
                      </div>
                      {lead.confirmedTest && (
                        <Chip
                          icon={<i className='material-icons'>check</i>}
                          label='Prueba confirmada'
                          clickable
                          color='primary'
                        />
                      )}
                    </CardContent>
                  </Card>
                )
              }}
            </Draggable>
          )
        })}
      </CardContent>
      {rest.placeholder}
    </>
  )
}

export default ContentColumnList

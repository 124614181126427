//@ts-check
import React from 'react'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { Droppable } from 'react-beautiful-dnd'
import ContentColumnList from '../ContentColumnList/ContentColumnList'
import './Column.css'

function Column({ column, leads }) {
  return (
    <Card className='custom_column' variant='outlined'>
      <CardHeader title={column.title} />
      <CardContent>
        <Droppable droppableId={column.id}>
          {(provided, snapshot) => (
            <ContentColumnList
              innerRef={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
              placeholder={provided.placeholder}
              leads={leads}
            />
          )}
        </Droppable>
      </CardContent>
    </Card>
  )
}

export default Column

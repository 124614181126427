import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@material-ui/core'
import {
  createTeacherSchedule,
  getTeacherSchedule,
  updateTeacherSchedule
} from 'api/teacherSchedules'
import {
  getCurrentMonth,
  mergeHours,
  dateFromString,
  dateToString
} from 'utils/date'
import { extractRangeHoursAndMinutes } from 'utils'
import InformationTable from '../../../components/InformationTable/InformationTable'
import MonthSelector from '../../../elements/MonthSelector/MonthSelector'
import YearSelector from '../../../elements/YearSelector/YearSelector'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import DateSelector from '../../../elements/DateSelector/DateSelector'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import ConfirmModal from '../../../shared/ConfirmModal'
import './HorariosProfesor.css'

const posiblesHoras = [
  '07:00 - 08:00',
  '08:00 - 09:00',
  '09:00 - 10:00',
  '10:00 - 11:00',
  '11:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 14:00',
  '14:00 - 15:00',
  '15:00 - 16:00',
  '16:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 19:00',
  '19:00 - 20:00',
  '20:00 - 21:00',
  '21:00 - 22:00',
  '22:00 - 23:00'
]

const posiblesMedias = [
  '07:30 - 08:30',
  '08:30 - 09:30',
  '09:30 - 10:30',
  '10:30 - 11:30',
  '11:30 - 12:30',
  '12:30 - 13:30',
  '13:30 - 14:30',
  '14:30 - 15:30',
  '15:30 - 16:30',
  '16:30 - 17:30',
  '17:30 - 18:30',
  '18:30 - 19:30',
  '19:30 - 20:30',
  '20:30 - 21:30',
  '21:30 - 22:30',
  '22:30 - 23:30'
]

const NuevoHorarioModal = ({
  currentDate = new Date(),
  currentHours = [],
  isEditMode = false,
  onClose,
  onSubmit
}) => {
  const [date, setDate] = useState(currentDate)
  const [hours, setHours] = useState(currentHours)

  const handleSubmit = useCallback(() => {
    onSubmit({ date: dateToString(date), hours })
  }, [date, hours, onSubmit])

  const handleHoursChange = useCallback(
    e => {
      const { name, checked } = e.target
      if (checked) {
        const { minHour: hourToCheck } = extractRangeHoursAndMinutes(name)

        for (let i = 0; i < hours.length; i++) {
          const { minHour } = extractRangeHoursAndMinutes(hours[i])
          if (minHour === hourToCheck) return
        }

        return setHours(currentHours => [...currentHours, name])
      }
      return setHours(currentHours => currentHours.filter(h => h !== name))
    },
    [hours]
  )

  const handleDateChange = useCallback(date => setDate(date), [])

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby='form-nuevo-horario'>
      <DialogTitle id='form-nuevo-horario'>Nuevo horario</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecciona las horas en que estará disponible el profesor. Ten cuidado
          para no solapar horarios!
        </DialogContentText>
        <div className='HorariosProfesor-fecha-container'>
          <DateSelector
            value={date}
            onChange={handleDateChange}
            disabled={isEditMode}
          />
        </div>
        <div className='HorariosProfesor-horas-container'>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Horas en punto</FormLabel>
            <FormGroup>
              {posiblesHoras.map(hora => (
                <FormControlLabel
                  key={hora}
                  control={
                    <Checkbox
                      size='small'
                      checked={hours.includes(hora)}
                      onChange={handleHoursChange}
                      name={hora}
                    />
                  }
                  label={hora}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Horas y media</FormLabel>
            <FormGroup>
              {posiblesMedias.map(hora => (
                <FormControlLabel
                  key={hora}
                  control={
                    <Checkbox
                      size='small'
                      checked={hours.includes(hora)}
                      onChange={handleHoursChange}
                      name={hora}
                    />
                  }
                  label={hora}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={!hours.length}>
          {isEditMode ? 'Editar horario' : 'Añadir horario'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CustomTeacherSchedule = () => {
  const { setErrorMessage, setSuccessMessage } = useMessageSnackbarActions()

  // @ts-ignore
  const { id } = useParams()
  const [classes, setClasses] = useState([])
  const [date, setDate] = useState(getCurrentMonth())
  const [open, setOpen] = useState(false)
  const [selectedCustomSchedule, setSelectedCustomSchedule] = useState({})
  const [targetEditData, setTargetEditData] = useState({})
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const handleDateChange = useCallback(
    key => e => {
      const value = e.target.value
      setDate(currentDate => ({
        ...currentDate,
        [key]: value
      }))
    },
    []
  )
  const fetchList = useCallback(() => {
    getTeacherSchedule({ teacherId: id, ...date })
      .then(setClasses)
      .catch(() => setErrorMessage('No se pudo ver el horario'))
  }, [date, id, setErrorMessage])

  const handleNewSchedule = useCallback(
    ({ date, hours }) => {
      const formattedDate = Date.parse(dateFromString(date))
      if (isNaN(formattedDate)) {
        setErrorMessage('No se pudo cambiar el estado, fecha inválida')
        console.error('Invalid date: ', formattedDate)
        return
      }

      createTeacherSchedule({ teacherId: id, date: formattedDate, hours })
        .then(() => {
          setSuccessMessage('Nuevo horario creado')
          fetchList()
          setOpen(false)
        })
        .catch(e =>
          setErrorMessage('No se pudo cambiar el estado: ' + e.message)
        )
    },
    [fetchList, id, setErrorMessage, setSuccessMessage]
  )

  const handleEditSchedule = useCallback(() => {
    const { date, hours } = targetEditData
    const formattedDate = Date.parse(dateFromString(date))
    if (isNaN(formattedDate)) {
      setErrorMessage('No se pudo cambiar el estado, fecha inválida')
      console.error('Invalid date: ', formattedDate)
      return
    }
    updateTeacherSchedule(selectedCustomSchedule.id, {
      teacherId: id,
      date: formattedDate,
      hours
    })
      .then(data => {
        setSuccessMessage('Horario actualizado')
        fetchList()
        setOpen(false)
        setSelectedCustomSchedule({})
        setTargetEditData({})
        setShowConfirmModal(false)
      })
      .catch(e => {
        setErrorMessage('No se pudo editar el estado: ' + e.message)
        setShowConfirmModal(false)
      })
  }, [
    fetchList,
    id,
    selectedCustomSchedule,
    setErrorMessage,
    setSuccessMessage,
    targetEditData
  ])

  const handleSelectCustomSchedule = useCallback(schedule => {
    setSelectedCustomSchedule(schedule)
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setSelectedCustomSchedule({})
    setOpen(false)
  }, [])

  useEffect(() => {
    setClasses([])
    fetchList()
  }, [fetchList])

  const isEditMode = !!selectedCustomSchedule.date
  return (
    <>
      <div className='HorariosProfesor'>
        <div className='HorariosProfesor-filter'>
          <MonthSelector
            value={date.month}
            onChange={handleDateChange('month')}
          />
          <YearSelector value={date.year} onChange={handleDateChange('year')} />
          <LoadingButton
            label='Añadir horario'
            startIcon={<i className='material-icons'>add</i>}
            onClick={() => setOpen(true)}
          />
        </div>
        <InformationTable
          details={[
            {
              title: 'Día',
              key: 'day',
              align: 'left',
              sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
            },
            { title: 'Horario', key: 'hours', sortDisabled: true },
            { title: 'Horas', key: 'total', sortDisabled: true },
            {
              title: 'Opciones',
              key: 'options',
              sortDisabled: true,
              align: 'center'
            }
          ]}
          data={classes.map(schedule => ({
            _id: schedule._id,
            day: dateToString(schedule.date),
            total: schedule.hours.length,
            hours: mergeHours(schedule.hours.sort()).join(', '),
            options: (
              <i
                className='material-icons'
                onClick={() => handleSelectCustomSchedule(schedule)}
              >
                edit
              </i>
            )
          }))}
        />
      </div>
      {open && (
        <NuevoHorarioModal
          onClose={handleClose}
          onSubmit={
            isEditMode
              ? data => {
                  setTargetEditData(data)
                  setShowConfirmModal(true)
                }
              : handleNewSchedule
          }
          currentDate={selectedCustomSchedule.date}
          currentHours={selectedCustomSchedule.hours}
          isEditMode={isEditMode}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          subtitle='Se cancelarán las asistencias planificadas asociadas a las horas eliminadas, si aplicase'
          onOk={handleEditSchedule}
          onCancel={() => setShowConfirmModal(false)}
          showModal
        />
      )}
    </>
  )
}

export default CustomTeacherSchedule

//@ts-check
import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { fetchDiscounts } from 'api/discounts'

function DiscountSelect({
  value = [],
  onChange = () => {},
  showLabel = true,
  disabled = false
}) {
  const [discounts, setDiscounts] = useState([])

  const getChipLabel = useCallback(
    discountId => {
      const discount = discounts.find(({ id }) => id === discountId)
      if (!discount) return '???'
      return discount.label
    },
    [discounts]
  )
  useEffect(() => {
    fetchDiscounts()
      .then(setDiscounts)
      .catch(e => {
        console.error('Error fectching discounts: ', e)
      })
  }, [])

  return !discounts.length ? null : (
    <FormControl margin='dense' fullWidth>
      {showLabel && <InputLabel>Descuento</InputLabel>}
      <Select
        value={value}
        onChange={onChange}
        input={<Input />}
        renderValue={(selected = []) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {selected.map(value => (
                <Chip
                  key={value}
                  label={getChipLabel(value)}
                  className='SubjectMultipleSelector-chip'
                />
              ))}
            </div>
          )
        }}
        multiple
        disabled={disabled}
      >
        {discounts.map(discount => (
          <MenuItem key={discount.id} value={discount.id}>
            {discount.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DiscountSelect

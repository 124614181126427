import { GET, POST, PUT } from 'api'

export const getAvailableHoursBy = (date, teacherId) =>
  GET({
    url: `admin/teacher-schedule/available-hours-by?date=${new Date(
      date
    ).toDateString()}&teacherId=${teacherId}`
  })

export const getDefaultTeacherSchedule = teacherId =>
  GET({
    url: `admin/teacher-schedule/default-schedule?teacherId=${teacherId}`
  })

export const getTeacherSchedule = ({ teacherId, month, year }) =>
  GET({
    url: `admin/teacher-schedule/list?teacherId=${teacherId}&month=${month}&year=${year}`
  })

export const createTeacherSchedule = (data = {}) =>
  POST({
    url: 'admin/teacher-schedule/new',
    data
  })

export const updateTeacherSchedule = (id, data = {}) =>
  PUT({
    url: `admin/teacher-schedule/${id}`,
    data
  })

export const getTeacherSchedulesByRange = ({
  teacherId,
  startDate = Date.now(),
  endDate = Date.now()
}) =>
  GET({
    url: `admin/teacher-schedule/by-range?teacherId=${teacherId}&startDate=${startDate}&endDate=${endDate}`
  })

//@ts-check
import React, { useMemo } from 'react'
import {
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { useSubscriptionManager } from './context/SubscriptionManagerProvider'
import PacksTable from './Tables/PacksTable'
import PrepareMorningsTable from './Tables/PrepareMorningsTable'
import UnlimitedTable from './Tables/UnlimitedTable'
import ReinforcesTable from './Tables/ReinforcesTable'
import {
  SubscriptionConfigTypes,
  SubscriptionTypeLabels
} from '../../../shared/constants'

function SubscriptionSelector() {
  const {
    selectedType,
    selectedSubscriptionConfigId,
    selectedConfig,
    especificConfigs,
    handleChangeSelectType,
    handleSelectSubscriptionConfig
  } = useSubscriptionManager()
  const tableToShow = useMemo(() => {
    if (!selectedSubscriptionConfigId) return null
    switch (selectedConfig.type) {
      case SubscriptionConfigTypes.pack:
      case SubscriptionConfigTypes.extraHours:
        return <PacksTable />
      case SubscriptionConfigTypes.prepareMornings:
        return <PrepareMorningsTable />
      case SubscriptionConfigTypes.unlimited:
        return <UnlimitedTable />
      case SubscriptionConfigTypes.reinforces:
        return <ReinforcesTable />
      case SubscriptionConfigTypes.agora:
        return (
          <Card style={{ margin: '50px auto', width: '60%' }} elevation={6}>
            <CardHeader
              title='Tarifa offline'
              subheader={selectedConfig.description}
            />
          </Card>
        )
      default: {
        console.warn('Unknown type:', selectedConfig.type)
        return null
      }
    }
  }, [selectedSubscriptionConfigId, selectedConfig])

  return (
    <>
      <div
        style={{
          width: '65%',
          margin: '0 auto 10px auto',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '15px'
        }}
      >
        <FormControl>
          <InputLabel>Tipo</InputLabel>
          <Select value={selectedType} onChange={handleChangeSelectType}>
            {Object.keys(SubscriptionTypeLabels).map(type => (
              <MenuItem key={type} value={type}>
                {SubscriptionTypeLabels[type]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Configuración</InputLabel>
          <Select
            value={selectedSubscriptionConfigId}
            onChange={handleSelectSubscriptionConfig}
          >
            {especificConfigs.map(c => (
              <MenuItem key={c.name} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {tableToShow}
    </>
  )
}

export default SubscriptionSelector

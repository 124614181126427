//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  TextField,
  Typography,
  FormGroup
} from '@material-ui/core'
import { assoc } from 'ramda'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import LeadStatusSelect from '../../../elements/LeadStatusSelect/LeadStatusSelect'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import useFormManager from '../../../hooks/common/useFormManager'
import { LeadLabelByStatus, LeadStatus } from '../../../shared/constants'
import StudentSelect from '../../../elements/StudentSelect/StudentSelect'
import ContactTypeSelect from '../../../elements/ContactTypeSelect/ContactTypeSelect'
import DefaultSelect from '../../../shared/DefaultSelect'
import TestAttendanceForm from '../../../shared/TestAttendanceForm'

const REQUIRED_FIELDS_FOR_LAST_STATUS = ['date']
const COMMON_REQUIRED_FIELDS = ['date', 'nextDate']
const REQUIRED_FIELDS_TEST = [
  'test.attendance.subjectId',
  'test.attendance.teacherId',
  'test.attendance.classStart',
  'test.attendance.classEnd'
]
function ContactFormByLeadStatus({
  title = 'Añadir nuevo contacto',
  onSave,
  onClose,
  contact = {},
  isSaving = false,
  changeStatusDisabled = false,
  statusOptions = Object.keys(LeadLabelByStatus)
}) {
  const {
    form,
    handleFormChange,
    handleDateChange,
    handleCheckChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    clearForm,
    clearRequiredFields
  } = useFormManager([], contact)
  const [selectedStatus, setSelectedStatus] = useState(
    // @ts-ignore
    contact?.lastStatus || statusOptions[0]
  )
  const handleChangeSelectedStatus = useCallback(e => {
    setSelectedStatus(e.target.value)
  }, [])

  const handleSaveContact = useCallback(() => {
    onSave(assoc('lastStatus', selectedStatus, form))
  }, [form, onSave, selectedStatus])

  const children = useMemo(() => {
    switch (selectedStatus) {
      case 'lead': {
        return null
      }
      case 'test': {
        return (
          <>
            <fieldset style={{ margin: '10px auto' }}>
              <legend>Asistencia</legend>
              <TestAttendanceForm
                onSave={value =>
                  handleFormChange({
                    target: { name: 'test.attendance', value }
                  })
                }
                attendance={form.test?.attendance || {}}
              />
            </fieldset>
            <FormGroup>
              <div style={{ margin: '10px 0 0 0' }}>
                Confirma asistencia
                <Typography component='span'>
                  <Grid
                    component='label'
                    container
                    alignContent='center'
                    alignItems='center'
                    spacing={1}
                  >
                    <Grid item>No </Grid>
                    <Grid item>
                      <Switch
                        name='test.confirmed'
                        checked={form.test?.confirmed || false}
                        onChange={handleCheckChange}
                      />
                    </Grid>
                    <Grid item> Sí</Grid>
                  </Grid>
                </Typography>
              </div>
            </FormGroup>
          </>
        )
      }
      case 'trained':
        return (
          <>
            <KeyboardDateTimePicker
              label='Fecha de incorporación'
              margin='dense'
              format='dd/MM/yyyy - HH:mm'
              value={form.trained?.startDate || null}
              onChange={value => handleDateChange('trained.startDate', value)}
              fullWidth
            />
            <TextField
              name='trained.budget'
              margin='dense'
              label='Presupuesto'
              value={form.trained?.budget || ''}
              onChange={handleFormChange}
              fullWidth
            />
          </>
        )
      case 'lost': {
        return (
          <DefaultSelect
            name='lost.cause'
            margin='dense'
            title='Causa de la perdida'
            options={[
              {
                id: 'No podemos contactar con él',
                name: 'No podemos contactar con él'
              },
              { id: 'No le interesa', name: 'No dice porqué' },
              { id: 'No le interesa porque es caro', name: 'Es caro' },
              {
                id: 'No le interesa porque ha encontrado una más barata',
                name: 'Ha encontrado una más barata'
              },
              {
                id: 'No le interesa porque pensaba que eran clases particulares',
                name: 'Pensaba que eran clases particulares'
              },
              {
                id: 'No le interesa porque ha encontrado una más cercana',
                name: 'Ha encontrado una más cercana'
              },
              {
                id: 'No le interesa porque está lejos de su domicilio',
                name: 'Está lejos de su domicilio'
              },
              {
                id: 'No le interesa porque no le convence el método',
                name: 'No le convence el método'
              },
              {
                id: 'No le interesa porque la prueba no le ha gustado',
                name: 'La prueba no le ha gustado'
              },
              {
                id: 'No le interesa porque no es lo que esperaba',
                name: 'No es lo que esperaba'
              },
              {
                id: 'No le interesa porque los profesores no le han gustado',
                name: 'Los profesores no le han gustado'
              },
              {
                id: 'No le interesa porque la atención no ha sido buena',
                name: 'La atención no ha sido buena'
              },
              {
                id: 'No le podemos dar el servicio',
                name: 'No le podemos dar servicio'
              },
              { id: 'No le interesa por otros motivos', name: 'Otra' }
            ]}
            value={form.lost?.cause || ''}
            onChange={handleFormChange}
            fullWidth
          />
        )
      }
      case 'invalid': {
        return (
          <DefaultSelect
            name='invalid.cause'
            margin='dense'
            title='Causa'
            options={[
              {
                id: 'Pregunta por otro negocio',
                name: 'Pregunta por otro negocio'
              },
              {
                id: 'Se ha equivocado de teléfono',
                name: 'Se ha equivocado de teléfono'
              },
              {
                id: 'Lead duplicado',
                name: 'Lead duplicado'
              }
            ]}
            value={form.invalid?.cause || ''}
            onChange={handleFormChange}
            fullWidth
          />
        )
      }
      case 'converted': {
        return (
          <StudentSelect
            name='converted.studentId'
            margin='dense'
            label='Alumno'
            value={form.converted?.studentId || ''}
            onChange={handleFormChange}
            fullWidth
          />
        )
      }
      default: {
        console.warn('Unknown status')
        return null
      }
    }
  }, [
    selectedStatus,
    form,
    handleFormChange,
    handleCheckChange,
    handleDateChange
  ])

  useEffect(() => clearForm, [clearForm, selectedStatus])
  useEffect(() => {
    switch (selectedStatus) {
      case LeadStatus.lead: {
        clearRequiredFields(COMMON_REQUIRED_FIELDS)
        break
      }
      case LeadStatus.test: {
        clearRequiredFields(REQUIRED_FIELDS_TEST)
        break
      }
      case LeadStatus.trained: {
        clearRequiredFields(COMMON_REQUIRED_FIELDS)
        break
      }
      case LeadStatus.lost:
      case LeadStatus.invalid:
      case LeadStatus.converted: {
        clearRequiredFields(REQUIRED_FIELDS_FOR_LAST_STATUS)
        break
      }
      default:
        break
    }
  }, [clearRequiredFields, selectedStatus])
  useEffect(() => {
    !form.date && handleDateChange('date', new Date())
  }, [form, handleDateChange])
  return (
    <>
      <Dialog
        open={true}
        aria-labelledby='form-dialog-title'
        disableBackdropClick
      >
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent
          style={{ width: '80%', minWidth: '500px', margin: '0 auto' }}
        >
          <LeadStatusSelect
            value={selectedStatus}
            onChange={handleChangeSelectedStatus}
            disabled={changeStatusDisabled}
            options={statusOptions}
            fullWidth
          />
          <KeyboardDateTimePicker
            label='Fecha de contacto'
            margin='dense'
            format='dd/MM/yyyy - HH:mm'
            value={form.date || null}
            onChange={value => handleDateChange('date', value)}
            fullWidth
            {...getErrorPropsByRequiredField('date')}
          />
          {[LeadStatus.lead, LeadStatus.trained].includes(selectedStatus) && (
            <KeyboardDateTimePicker
              label='Próximo contacto'
              margin='dense'
              format='dd/MM/yyyy - HH:mm'
              value={form.nextDate || null}
              onChange={value => handleDateChange('nextDate', value)}
              {...getErrorPropsByRequiredField('nextDate')}
              fullWidth
            />
          )}
          <ContactTypeSelect
            name='contactType'
            margin='dense'
            label='Tipo de contacto'
            value={form.contactType || ''}
            onChange={handleFormChange}
            fullWidth
          />
          {children && children}
          <TextField
            name='description'
            margin='dense'
            label='Observaciones'
            value={form.description || ''}
            onChange={handleFormChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='secondary'>
            Cancel
          </Button>

          <LoadingButton
            label='Guardar'
            onClick={handleSaveContact}
            isLoading={isSaving}
            disabled={hasPendingRequiredFields}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ContactFormByLeadStatus

import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const options = ['1º', '2º', '3º', '4º', '5º', '6º']

function CourseSelect({
  value = '',
  onChange,
  label = 'Curso',
  name,
  fullWidth,
  margin = 'dense'
}) {
  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        <MenuItem key='empty' value=''>
          Sin valor
        </MenuItem>
        {options.map(centre => (
          <MenuItem key={centre} value={centre}>
            {centre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CourseSelect

//@ts-check
import React, { useEffect, useState } from 'react'
import { prop, sortBy } from 'ramda'
import AutocompleteSelect from '../../shared/AutocompleteSelect'
import { findStudents } from 'api/students'
import { AccessTypes } from '../../shared/constants'

function StudentSelect({ name, value, onChange, ...rest }) {
  const [students, setStudents] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  useEffect(() => {
    setIsFetching(true)
    findStudents({ accessType: AccessTypes.STUDENT }, ['name', 'id'])
      .then(({ students }) => students)
      .then(sortBy(prop('name')))
      .then(setStudents)
      .catch(console.error)
      .finally(() => setIsFetching(false))
  }, [setIsFetching])
  return (
    <AutocompleteSelect
      name={name}
      value={value}
      title='Alumno'
      onChange={onChange}
      options={students}
      isLoading={isFetching}
      {...rest}
    />
  )
}

export default StudentSelect

import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const options = ['Teléfono', 'Persona', 'E-mail']

function ContactTypeSelect({
  value = '',
  onChange,
  label = 'Tipo de contacto',
  name = '',
  fullWidth,
  margin = 'dense'
}) {
  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        <MenuItem key='empty' value=''>
          Sin valor
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ContactTypeSelect

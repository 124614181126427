//@ts-check
import React, { useCallback, useMemo, useState } from 'react'
import { Card, CardHeader } from '@material-ui/core'
import { includes, reject, uniq } from 'ramda'
import { SubscriptionConfigTypes } from '../../../shared/constants'
import SubjectList from './SubjectCards/SubjectList'
import PrepareMorningsSubjects from './SubjectCards/PrepareMorningsSubjects'
import { useSubscriptionManager } from './context/SubscriptionManagerProvider'
import { SubjectLevels } from '../../../shared/constants'

function SubjectSubscriptionSelector() {
  const {
    selectedSubscriptionConfigRow,
    subjects,
    selectedConfig,
    selectedSubjectIds,
    selectedSupplementIds,
    getSupplementsInfo,
    handleSelectSubject,
    handleSelectSupplement
  } = useSubscriptionManager()

  const [search, setSearch] = useState('')
  const availableSubjects = useMemo(() => {
    if (!selectedSubscriptionConfigRow) return []
    const filteredSubjects = subjects.filter(s => {
      if (!search) return s
      const foundName = s.name.toLowerCase().includes(search.toLowerCase())
      const foundType = s.type.toLowerCase().includes(search.toLowerCase())
      const foundLevel = s.level.toLowerCase().includes(search.toLowerCase())
      return foundName || foundType || foundLevel
    })
    const getSubjectsByLevelForPacksAndPrepareMornings = () =>
      filteredSubjects.filter(({ level }) =>
        includes(level, selectedConfig?.subjectRestrictions?.applyToLevel || [])
      )

    const getSubjectsForUnlimitedRate = () =>
      filteredSubjects.filter(({ level }) =>
        includes(
          level,
          selectedSubscriptionConfigRow?.subjectRestrictions?.applyToLevel || []
        )
      )

    const getSubjectsForReinforcesRate = () => {
      const types =
        selectedSubscriptionConfigRow?.subjectRestrictions.map(
          ({ type }) => type
        ) || []
      return filteredSubjects.filter(subject => {
        if (
          SubjectLevels.UNIVERSIDAD === subject.level &&
          !types.includes(subject.level)
        )
          return
        if (!types.includes(subject.type) && !types.includes(subject.level))
          return
        return subject
      })
    }
    switch (selectedConfig.type) {
      case SubscriptionConfigTypes.extraHours:
      case SubscriptionConfigTypes.pack:
        return getSubjectsByLevelForPacksAndPrepareMornings()
      case SubscriptionConfigTypes.prepareMornings:
        return reject(
          s => Object.keys(selectedConfig.supplements).includes(s.id),
          getSubjectsByLevelForPacksAndPrepareMornings()
        )
      case SubscriptionConfigTypes.unlimited:
        return getSubjectsForUnlimitedRate()
      case SubscriptionConfigTypes.reinforces:
        return getSubjectsForReinforcesRate()
      case SubscriptionConfigTypes.agora:
        return filteredSubjects
      default: {
        console.warn('Unknown type:', selectedConfig.type)
        return []
      }
    }
  }, [selectedSubscriptionConfigRow, selectedConfig, subjects, search])

  const handleChangeSearch = useCallback(e => setSearch(e.target.value), [])

  const children = useMemo(() => {
    if (!selectedSubscriptionConfigRow)
      return <CardHeader title='Ayuda' subheader='Seleccione una suscripción' />
    const CommonComponentForPacksAndPrepareMornigns = (
      <SubjectList
        search={search}
        title={
          'Nivel: ' +
          (selectedConfig?.subjectRestrictions
            ? selectedConfig?.subjectRestrictions?.applyToLevel?.join(', ')
            : '-')
        }
        onChangeSearch={handleChangeSearch}
        availableSubjects={availableSubjects}
        selectedSubjectIds={selectedSubjectIds}
        onSelectSubject={handleSelectSubject}
        subjectsLimitTotal={selectedSubscriptionConfigRow?.subjectsLimit}
      />
    )
    switch (selectedConfig.type) {
      case SubscriptionConfigTypes.extraHours:
      case SubscriptionConfigTypes.pack:
        return CommonComponentForPacksAndPrepareMornigns
      case SubscriptionConfigTypes.prepareMornings:
        return (
          <PrepareMorningsSubjects
            firstTab={CommonComponentForPacksAndPrepareMornigns}
            suplementSubjects={getSupplementsInfo(selectedConfig.supplements)}
            selectedSupplementIds={selectedSupplementIds}
            onSelectSupplement={handleSelectSupplement}
          />
        )
      case SubscriptionConfigTypes.unlimited:
        return (
          <SubjectList
            search={search}
            title={
              'Nivel: ' +
              (selectedSubscriptionConfigRow?.subjectRestrictions
                ? selectedSubscriptionConfigRow?.subjectRestrictions?.applyToLevel?.join(
                    ', '
                  )
                : '-')
            }
            onChangeSearch={handleChangeSearch}
            availableSubjects={availableSubjects}
            selectedSubjectIds={selectedSubjectIds}
            onSelectSubject={handleSelectSubject}
          />
        )

      case SubscriptionConfigTypes.reinforces:
        return (
          <SubjectList
            search={search}
            title={
              'Incluye: ' +
              uniq(
                selectedSubscriptionConfigRow?.subjectRestrictions.map(
                  ({ type }) => type
                )
              ).join(', ')
            }
            onChangeSearch={handleChangeSearch}
            availableSubjects={availableSubjects}
            selectedSubjectIds={selectedSubjectIds}
            onSelectSubject={handleSelectSubject}
            subjectsLimitTotal={selectedSubscriptionConfigRow?.subjectRestrictions.reduce(
              (acc, { subjectsLimit }) => {
                acc += subjectsLimit
                return acc
              },
              0
            )}
          />
        )
      case SubscriptionConfigTypes.agora:
        return (
          <SubjectList
            search={search}
            title='Todas las asignaturas'
            onChangeSearch={handleChangeSearch}
            availableSubjects={availableSubjects}
            selectedSubjectIds={selectedSubjectIds}
            onSelectSubject={handleSelectSubject}
          />
        )
      default: {
        console.warn('Unknown type:', selectedConfig.type)
        return null
      }
    }
  }, [
    selectedSubscriptionConfigRow,
    selectedConfig,
    search,
    handleChangeSearch,
    availableSubjects,
    selectedSubjectIds,
    selectedSupplementIds,
    getSupplementsInfo,
    handleSelectSubject,
    handleSelectSupplement
  ])

  return <Card>{children}</Card>
}

export default SubjectSubscriptionSelector

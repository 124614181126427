import { GET, POST, PUT } from 'api'
import { makeCustomQueryAndProject } from 'utils'

export const findLeads = (query = {}, project = []) =>
  GET({
    url: makeCustomQueryAndProject({
      query,
      project,
      url: 'admin/leads/'
    })
  })

export const getLead = id =>
  GET({
    url: `admin/leads/${id}`
  })

export const updateLead = (id, data = {}) =>
  PUT({
    url: `admin/leads/${id}/full`,
    data
  })

export const createLead = (data = {}) =>
  POST({
    url: 'admin/leads/',
    data
  })

export const createLeadContact = (id, data = {}) =>
  PUT({
    url: `admin/leads/${id}/add_contact`,
    data
  })

export const editLeadContact = (id, pos, data = {}) =>
  PUT({
    url: `admin/leads/${id}/edit_contact/${pos}`,
    data
  })

export const deleteLeadContact = id =>
  PUT({
    url: `admin/leads/${id}/delete_last_contact`
  })

export const checkLead = ({ email, phoneNumber }) =>
  GET({
    url: `admin/leads/check_lead?phoneNumber=${phoneNumber}&email=${email}`
  })

/**
 * @param {number} weeks
 */
export const findCRMLeads = (weeks = 1) =>
  GET({
    url: `admin/leads/crm?weeks=${weeks}`
  })

//@ts-check
import React, { useCallback, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@material-ui/core'
import { assocPath } from 'ramda'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import './ScheduleTable.css'

const defaultWeekdayHours = [
  { hour: '00:00', active: false },
  { hour: '01:00', active: false },
  { hour: '02:00', active: false },
  { hour: '03:00', active: false },
  { hour: '04:00', active: false },
  { hour: '05:00', active: false },
  { hour: '06:00', active: false },
  { hour: '07:00', active: false },
  { hour: '08:00', active: false },
  { hour: '09:00', active: false },
  { hour: '10:00', active: false },
  { hour: '11:00', active: false },
  { hour: '12:00', active: false },
  { hour: '13:00', active: false },
  { hour: '14:00', active: false },
  { hour: '15:00', active: false },
  { hour: '16:00', active: false },
  { hour: '17:00', active: false },
  { hour: '18:00', active: false },
  { hour: '19:00', active: false },
  { hour: '20:00', active: false },
  { hour: '21:00', active: false },
  { hour: '22:00', active: false },
  { hour: '23:00', active: false }
]
const defaultWeekendHours = [
  { hour: '00:00', active: false },
  { hour: '01:00', active: false },
  { hour: '02:00', active: false },
  { hour: '03:00', active: false },
  { hour: '04:00', active: false },
  { hour: '05:00', active: false },
  { hour: '06:00', active: false },
  { hour: '07:00', active: false },
  { hour: '08:00', active: false },
  { hour: '09:00', active: false },
  { hour: '10:00', active: false },
  { hour: '11:00', active: false },
  { hour: '12:00', active: false },
  { hour: '13:00', active: false },
  { hour: '14:00', active: false },
  { hour: '15:00', active: false },
  { hour: '16:00', active: false },
  { hour: '17:00', active: false },
  { hour: '18:00', active: false },
  { hour: '19:00', active: false },
  { hour: '20:00', active: false },
  { hour: '21:00', active: false },
  { hour: '22:00', active: false },
  { hour: '23:00', active: false }
]

const defaultRows = [
  { day: 'Lunes', hours: defaultWeekdayHours },
  { day: 'Martes', hours: defaultWeekdayHours },
  { day: 'Miércoles', hours: defaultWeekdayHours },
  { day: 'Jueves', hours: defaultWeekdayHours },
  { day: 'Viernes', hours: defaultWeekdayHours },
  { day: 'Sábado', hours: defaultWeekendHours },
  { day: 'Domingo', hours: defaultWeekendHours }
]

function ScheduleTable({ title, data = {}, onSave, isSaving = false }) {
  const [state, setState] = useState({
    schedule: data.schedule || defaultRows,
    name: data.name,
    active: data.active
  })
  const { schedule, name, active } = state
  const handleCheck = useCallback(({ dayPosition, hourPosition, active }) => {
    setState(
      assocPath(
        ['schedule', dayPosition, 'hours', hourPosition, 'active'],
        active
      )
    )
  }, [])
  const handleSave = useCallback(() => {
    onSave(state)
  }, [onSave, state])
  const handleNameChange = useCallback(e => {
    const { name, value } = e.target
    setState(assocPath([name], value))
  }, [])
  const handleCheckChange = useCallback(e => {
    const { name, checked } = e.target
    setState(assocPath([name], checked))
  }, [])
  return (
    <Card>
      <CardHeader title={title} />
      <div className='custom_toolbar'>
        <TextField
          className='input_name'
          name='name'
          margin='dense'
          label='Nombre'
          value={name || ''}
          onChange={handleNameChange}
        />

        <FormControlLabel
          className='input_checkbox'
          name='active'
          control={
            <Checkbox checked={active || false} onChange={handleCheckChange} />
          }
          label='Marcar como horario por defecto'
        />
      </div>
      <CardContent className='card_content'>
        <TableContainer>
          <Table>
            <TableBody>
              {schedule.map(({ day, hours }, dayPosition) => (
                <TableRow key={day}>
                  <TableCell>{day}</TableCell>
                  <TableCell>
                    <div style={{ display: 'table' }}>
                      {hours.map(({ hour, active }, hourPosition) => (
                        <FormControlLabel
                          key={hour}
                          name='visible'
                          control={
                            <Checkbox
                              checked={active}
                              onChange={e =>
                                handleCheck({
                                  dayPosition,
                                  hourPosition,
                                  active: e.target.checked
                                })
                              }
                            />
                          }
                          label={hour}
                        />
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className='card_wrapper_action'>
        <LoadingButton
          label='Guardar'
          onClick={handleSave}
          isLoading={isSaving}
          disabled={isSaving || !name}
          startIcon={<i className='material-icons'>save</i>}
        />
      </CardActions>
    </Card>
  )
}

export default ScheduleTable

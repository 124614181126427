import { AppBar, Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import Attendances from './tabs/Attendances'
import ClasesAlumno from './tabs/ClasesAlumno'
import CuadernillosAlumno from './tabs/CuadernillosAlumno'
import DatosAlumno from './tabs/DatosAlumno'
import FotosAlumno from './tabs/FotosAlumno'
import Multiplanner from './tabs/Multiplanner'
import Historic from './tabs/Historic'
import Evaluations from './tabs/Evaluations'
import styles from './FichaAlumno.module.css'

const TabPanel = ({ active, children }) => {
  if (!active) return null
  return children
}
const AttendancesTabs = () => {
  const [tab, setTab] = useState(0)

  const handleChange = (_e, newValue) => setTab(newValue)

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleChange}
        variant='fullWidth'
        indicatorColor='primary'
      >
        <Tab label='Calendario' />
        <Tab label='Multiplanificador' />
      </Tabs>
      <TabPanel active={tab === 0}>
        <Attendances />
      </TabPanel>
      <TabPanel active={tab === 1}>
        <Multiplanner />
      </TabPanel>
    </>
  )
}
const ITEMS = {
  Ficha: DatosAlumno,
  Evaluaciones: Evaluations,
  Histórico: Historic,
  Clases: ClasesAlumno,
  Cuadernillos: CuadernillosAlumno,
  Fotos: FotosAlumno,
  Asistencias: AttendancesTabs
}
const FichaAlumno = () => {
  const [tab, setTab] = useState(0)

  const handleChange = (_e, newValue) => setTab(newValue)

  return (
    <div className={styles.main}>
      <AppBar color='secondary' position='static'>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant='fullWidth'
          indicatorColor='primary'
        >
          {Object.keys(ITEMS)
            .filter(
              label =>
                !process.env.REACT_APP_HIDE_STUDENT_TAB_ITEMS?.split(
                  ','
                ).includes(label)
            )
            .map((label, i) => {
              return <Tab key={i} label={label} />
            })}
        </Tabs>
      </AppBar>
      {Object.entries(ITEMS)
        .filter(
          ([label]) =>
            !process.env.REACT_APP_HIDE_STUDENT_TAB_ITEMS?.split(',').includes(
              label
            )
        )
        .map(([label, Component], i) => {
          return (
            <TabPanel key={i} active={tab === i}>
              <Component />
            </TabPanel>
          )
        })}
    </div>
  )
}

export default FichaAlumno

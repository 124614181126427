//@ts-check
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core'
import { assoc } from 'ramda'
import { getEvaluationsByStudentIds } from 'api/evaluations'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import Spinner from 'elements/Spinner/Spinner'
import { EvaluationServiceLabels } from 'shared/constants'
import styles from './Evaluations.module.css'

function makeSubjectLabel({ subjectName, subjectLevel, subjectSublevel }) {
  let label = subjectName
  if (subjectLevel) label += ' de ' + subjectLevel
  if (subjectSublevel) label += '(' + subjectSublevel + ')'

  return label
}

const initialState = {
  isFetching: false,
  evaluation: {}
}
function Evaluations() {
  const { id } = useParams()

  const [{ isFetching, evaluation }, setState] = useState(initialState)
  const { setErrorMessage } = useMessageSnackbarActions()
  useEffect(() => {
    setState(assoc('isFetching', true))
    getEvaluationsByStudentIds({ studentIds: [id] })
      .then(data => setState(assoc('evaluation', data[0] || {})))
      .catch(e => setErrorMessage(e.message))
      .finally(() => setState(assoc('isFetching', false)))
  }, [id, setErrorMessage])

  if (isFetching) return <Spinner />
  return (
    <div className={styles.card}>
      <Card variant='outlined'>
        <CardHeader
          title={
            evaluation.studentName ? (
              <Typography variant='h5' color='primary'>
                {evaluation.studentName}
              </Typography>
            ) : (
              'Sin evaluaciones'
            )
          }
        />
        <CardContent>
          <List>
            {evaluation.teacherEvaluations?.map(
              (
                {
                  teacherName,
                  subjectName,
                  subjectLevel,
                  subjectSublevel,
                  level,
                  service
                },
                i
              ) => {
                return (
                  <div key={i}>
                    <ListItem alignItems='center' className={styles.grid}>
                      <ListItemText
                        primary={
                          <Typography variant='subtitle1' color='textPrimary'>
                            {teacherName || 'Pofesor desconocido'}
                          </Typography>
                        }
                        secondary={
                          <Typography variant='subtitle2' color='textSecondary'>
                            {makeSubjectLabel({
                              subjectName,
                              subjectLevel,
                              subjectSublevel
                            })}
                          </Typography>
                        }
                      />
                      <ListItemText
                        secondary={
                          <p>
                            <span className={styles.strong}>
                              {EvaluationServiceLabels[service]}
                            </span>{' '}
                          </p>
                        }
                      />

                      <ListItemText
                        secondary={
                          <p>
                            <span className={styles.strong}>{level}</span>
                            /10
                          </p>
                        }
                      />
                    </ListItem>
                    {evaluation.teacherEvaluations.length !== i + 1 && (
                      <Divider variant='fullWidth' component='li' />
                    )}
                  </div>
                )
              }
            )}
          </List>
        </CardContent>
      </Card>
    </div>
  )
}

export default Evaluations

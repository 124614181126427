//@ts-check
import React, { useMemo, useState } from 'react'
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { allPass, any, assoc, equals, isEmpty } from 'ramda'
import InformationTable from '../../../../components/InformationTable/InformationTable'
import { SubjectTypes, SubjectLevels } from '../../../../shared/constants'
import { useSubscriptionManager } from '../context/SubscriptionManagerProvider'

function ReinforcesTable() {
  const { selectedConfig, selectedSubscriptionConfigRow, handleCheckConfig } =
    useSubscriptionManager()
  const [filters, setFilters] = useState({ type: '', subjectsLimit: '' })
  const filteredData = useMemo(() => {
    const data = Object.values(selectedConfig?.config ?? [])
    if (!filters) return data
    const typeEq = ({ type }) => type === filters.type
    const subjectsLimitEq = ({ subjectsLimit }) =>
      subjectsLimit === filters.subjectsLimit
    const filtersToApply = []
    filters.type && filtersToApply.push(typeEq)
    filters.subjectsLimit && filtersToApply.push(subjectsLimitEq)
    if (isEmpty(filtersToApply)) return data
    return data.filter(row => {
      const canBeIncluded = allPass(filtersToApply)
      return any(equals(true))(row.subjectRestrictions.map(canBeIncluded))
    })
  }, [filters, selectedConfig])

  return (
    <>
      <div
        style={{
          width: '60%',
          margin: '0 auto 10px auto',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '15px'
        }}
      >
        <FormControl>
          <InputLabel>Filtrar por</InputLabel>
          <Select
            name='type'
            value={filters.type}
            onChange={e => setFilters(assoc('type', e.target.value))}
          >
            <MenuItem value=''>
              <em>Sin filtro</em>
            </MenuItem>
            {[
              SubjectTypes.CIENCIAS,
              SubjectTypes.LETRAS,
              SubjectLevels.UNIVERSIDAD
            ].map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Nº de asignaturas'
          type='number'
          value={filters.subjectsLimit}
          onChange={e =>
            setFilters(
              assoc(
                'subjectsLimit',
                !e.target.value || e.target.value === '0'
                  ? ''
                  : Number(e.target.value)
              )
            )
          }
        />
      </div>
      <InformationTable
        details={[
          { title: ' ', key: 'action', style: { width: '30%' } },
          // { title: 'Código', key: 'code' },
          {
            title: 'Incluye',
            key: 'details'
          },

          { title: 'Precio (€)', key: 'uniqPrice', style: { width: '30%' } }
        ]}
        data={filteredData.map(c => ({
          ...c,
          details: (
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <ul>
                {c.subjectRestrictions.map((r, i) => (
                  <li key={i}>
                    <p>
                      <strong>{r.type}:</strong> {r.subjectsLimit}{' '}
                      {r.subjectsLimit === 1 ? 'asignatura' : 'asignaturas'} ,{' '}
                      {r.hoursLimitByWeek}
                      h/semana
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          ),
          action: (
            <Checkbox
              checked={selectedSubscriptionConfigRow?.code === c.code}
              onClick={() => handleCheckConfig(c)}
            />
          )
        }))}
        size='small'
      />
    </>
  )
}

export default ReinforcesTable

//@ts-check
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react'
import { isEmpty, propEq } from 'ramda'
import { useReactToPrint } from 'react-to-print'
import { findSubjects } from 'api/subjects'
import { findSubscriptionConfigs } from 'api/subscriptionConfigs'
import { createBudget } from 'api/budgets'
import { makeCalculatedDiscount } from 'api/discounts'
import { useMessageSnackbarActions } from '../../../../elements/MessageContext/MessageContext'
import {
  SubscriptionConfigTypes,
  SubscriptionTypes
} from '../../../../shared/constants'
import Spinner from '../../../../elements/Spinner/Spinner'
import subscriptionsReducer, { makeInitialState } from './reducer'

export const SubscriptionManagerContext = createContext(null)

function SubscriptionManagerProvider({ children, currentConfig = {} }) {
  const refPrint = useRef()
  const handlePrint = useReactToPrint({
    content: () => refPrint.current
  })
  const [state, dispatch] = useReducer(
    subscriptionsReducer,
    currentConfig,
    makeInitialState
  )
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()
  const {
    selectedType,
    selectedSubscriptionConfigId,
    selectedSubscriptionConfigRow,
    selectedSubjectIds,
    selectedSupplementIds,
    selectedDiscountIds,
    isFetching,
    subscriptionConfigs,
    subjects,
    error
  } = state
  //Needed to filter config selector
  const especificConfigs = useMemo(() => {
    if (!selectedType) return []
    return subscriptionConfigs.filter(sc => {
      switch (selectedType) {
        case SubscriptionTypes.pack:
          return (
            sc.type === selectedType ||
            sc.type === SubscriptionConfigTypes.extraHours
          )
        case SubscriptionTypes.rate:
          return (
            sc.type !== SubscriptionTypes.pack &&
            sc.type !== SubscriptionConfigTypes.extraHours
          )
        default:
          return false
      }
    })
  }, [selectedType, subscriptionConfigs])
  const selectedConfig = useMemo(
    () =>
      especificConfigs.find(propEq('id', selectedSubscriptionConfigId)) || [],
    [especificConfigs, selectedSubscriptionConfigId]
  )
  const handleChangeSelectType = useCallback(e => {
    dispatch({
      type: 'SelectedTypeChanged',
      payload: e.target.value
    })
  }, [])
  const handleSelectSubscriptionConfig = useCallback(e => {
    dispatch({
      type: 'SelectedSubscriptionConfigChanged',
      payload: e.target.value
    })
  }, [])
  const handleCheckConfig = useCallback(currentSelectedConfig => {
    dispatch({
      type: 'SubscriptionConfigRowChanged',
      payload: currentSelectedConfig
    })
  }, [])
  const handleSelectSubject = useCallback(subjectId => {
    dispatch({
      type: 'SelectedSubjectsChanged',
      payload: subjectId
    })
  }, [])
  const getSupplementsInfo = useCallback(
    supplements => {
      if (
        !supplements ||
        !subjects ||
        isEmpty(supplements) ||
        isEmpty(subjects)
      )
        return []
      return Object.keys(supplements).reduce((acc, subjectId) => {
        const subject = subjects.find(propEq('id', subjectId))
        if (subject)
          acc.push({
            id: subject.id,
            name: subject.name,
            level: subject.level,
            type: subject.type,
            price: supplements[subjectId]
          })
        return acc
      }, [])
    },
    [subjects]
  )
  const handleSetActiveStep = useCallback(index => {
    dispatch({ type: 'ActiveStepChanged', payload: index })
  }, [])

  const handleSelectSupplement = useCallback(subjectId => {
    dispatch({ type: 'SupplementIdsChanged', payload: subjectId })
  }, [])
  const handleSelectDiscount = useCallback(discountId => {
    dispatch({ type: 'SelectedDiscountChanged', payload: discountId })
  }, [])
  const toggleShowModal = useCallback(() => {
    dispatch({ type: 'ToggleShowModal' })
  }, [])
  const calculateTotalPrice = useCallback(() => {
    switch (selectedConfig.type) {
      case SubscriptionConfigTypes.extraHours: {
        const targetSubject = subjects.find(
          ({ id }) => id === selectedSubjectIds[0]
        )
        return targetSubject?.price * selectedSubscriptionConfigRow.hours
      }
      case SubscriptionConfigTypes.pack:
        return (
          selectedSubscriptionConfigRow.uniqPrice ??
          selectedSubscriptionConfigRow.calculatedPrice
        )
      case SubscriptionConfigTypes.prepareMornings: {
        return !isEmpty(selectedSupplementIds)
          ? selectedSupplementIds.reduce((acc, id) => {
              if (Object.keys(selectedConfig.supplements).includes(id))
                acc += selectedConfig.supplements[id]
              return acc
            }, 0) + selectedSubscriptionConfigRow.uniqPrice
          : selectedSubscriptionConfigRow.uniqPrice
      }
      case SubscriptionConfigTypes.unlimited:
      case SubscriptionConfigTypes.reinforces:
        return selectedSubscriptionConfigRow?.uniqPrice
      case SubscriptionConfigTypes.agora:
        return selectedConfig.config.uniqConfig.uniqPrice
      default:
        return
    }
  }, [
    selectedConfig,
    selectedSubjectIds,
    selectedSubscriptionConfigRow,
    selectedSupplementIds,
    subjects
  ])
  const handleSaveBudget = useCallback(
    async (form = {}) => {
      await createBudget({
        ...form,
        subscriptionConfig: {
          ...selectedConfig,
          config: selectedSubscriptionConfigRow
        },
        selectedSubjectIds: selectedSubjectIds,
        selectedSupplementIds: selectedSupplementIds,
        discountIds: selectedDiscountIds,
        totalPrice: calculateTotalPrice()
      })
        .then(() => {
          refPrint.current && handlePrint()
          dispatch({ type: 'ToggleShowModal' })
          setSuccessMessage('Presupuesto guardado correctamente')
        })
        .catch(e => {
          console.error('Error saving budget:', e)
          setErrorMessage(e.message)
        })
    },
    [
      handlePrint,
      selectedConfig,
      selectedSubjectIds,
      selectedSubscriptionConfigRow,
      selectedSupplementIds,
      selectedDiscountIds,
      setErrorMessage,
      setSuccessMessage,
      calculateTotalPrice
    ]
  )

  const calculateDiscount = useCallback(
    async hasActiveEnrollment => {
      const totalPrice = calculateTotalPrice()
      return await makeCalculatedDiscount({
        hasActiveEnrollment,
        totalPrice,
        discountIds: selectedDiscountIds
      }).catch(e => {
        throw e
      })
    },
    [calculateTotalPrice, selectedDiscountIds]
  )
  useEffect(() => {
    dispatch({
      type: 'FetchConfigsRequest'
    })
    findSubscriptionConfigs()
      .then(subscriptionConfigs =>
        dispatch({
          type: 'FetchConfigsSuccess',
          payload: subscriptionConfigs
        })
      )
      .catch(e => {
        dispatch({
          type: 'FetchConfigsFailed'
        })
        console.error('Error fecthing:', e)
        setErrorMessage('Error buscando configuraciones')
      })
  }, [setErrorMessage])
  useEffect(() => {
    dispatch({
      type: 'FetchSubjectsRequest'
    })
    findSubjects()
      .then(subjects =>
        dispatch({
          type: 'FetchSubjectsSuccess',
          payload: subjects
        })
      )
      .catch(e => {
        dispatch({
          type: 'FetchSubjectsFailed'
        })
        console.error('Error fecthing:', e)
        setErrorMessage('Error buscando asignaturas')
      })
  }, [setErrorMessage])
  if (error) return <p>Se ha producido un error</p>
  if (isFetching) return <Spinner />
  return (
    <SubscriptionManagerContext.Provider
      value={{
        //State
        ...state,
        //Print
        refPrint,
        //functions and memos
        especificConfigs,
        selectedConfig,
        getSupplementsInfo,
        handleChangeSelectType,
        handleSelectSubscriptionConfig,
        handleCheckConfig,
        handleSetActiveStep,
        handleSelectSubject,
        handleSelectSupplement,
        handleSelectDiscount,
        handleSaveBudget,
        handlePrint,
        toggleShowModal,
        calculateTotalPrice,
        calculateDiscount
      }}
    >
      {children}
    </SubscriptionManagerContext.Provider>
  )
}

export default SubscriptionManagerProvider

/**
 *
 * @returns {import('./reducer').SubscriptionManagerState & {
 * refPrint: React.RefObject<HTMLDivElement>
 * especificConfigs: object[]
 * selectedConfig: object
 * getSupplementsInfo:(supplemets: object) => {id: string, name: string, level: string, type: string, price: number }[]
 * handleChangeSelectType:(e: object) => void
 * handleSelectSubscriptionConfig:(e: object) => void
 * handleCheckConfig:(config: object) => void
 * handleSetActiveStep:(index: number) => void
 * handleSelectSubject:(subjectId: string) => void
 * handleSelectSupplement:(subjectId: string) => void
 * handleSelectDiscount:(discountId: string[]) => void
 * handleSaveBudget:(form: object) => void
 * handlePrint:() => void
 * toggleShowModal:() => void
 * calculateTotalPrice:() => number
 * calculateDiscount:(hasActiveEnrollment: boolean) => Promise<number>
 * }}
 */
export const useSubscriptionManager = () =>
  // @ts-ignore
  useContext(SubscriptionManagerContext)

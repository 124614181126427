//@ts-check
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, Snackbar } from '@material-ui/core'
import { isEmpty } from 'ramda'
import { createLead, checkLead } from 'api/leads'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import LeadForm from '../ui/LeadForm'

const LeadCreate = () => {
  const { push } = useHistory()
  const [isSaving, setIsSaving] = useState(false)
  const [isChecking, setIsChecking] = useState(false)
  const [otherLeads, setOtherLeads] = useState([])
  const { setSuccessMessage, setErrorMessage, setWarningMessage } =
    useMessageSnackbarActions()

  const handleSave = useCallback(
    newLead => {
      setIsSaving(true)
      createLead(newLead)
        .then(() => {
          setSuccessMessage(`Nuevo lead (${newLead.name}) creado correctamente`)
          push('/leads')
        })
        .catch(e => {
          console.error('Error creating lead: ' + e)
          if (e.message.includes('already exists'))
            return setWarningMessage(
              'El email o número de teléfono ya están asociados a otro lead'
            )
          setErrorMessage(e.message)
        })
        .finally(() => setIsSaving(false))
    },
    [push, setErrorMessage, setSuccessMessage, setWarningMessage]
  )
  const handleCheckLead = useCallback(({ email, phoneNumber }) => {
    setIsChecking(true)
    checkLead({ email, phoneNumber })
      .then(setOtherLeads)
      .catch(e => {
        console.error('Error checking lead: ', e)
      })
      .finally(() => setIsChecking(false))
  }, [])

  return (
    <>
      <LeadForm
        isSaving={isSaving}
        isChecking={isChecking}
        onSubmit={handleSave}
        onCheckLead={handleCheckLead}
        isCreateMode
      />
      <Snackbar
        open={!isEmpty(otherLeads)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={
          <>
            Existen otros lead con el mismo contacto:
            <ul>
              {otherLeads.map((lead, i) => {
                return (
                  <li key={lead.id}>
                    <a
                      target='_blank'
                      style={{ color: '#8cc63f' }}
                      href={`/leads/${lead.id}`}
                    >
                      {lead.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </>
        }
        action={
          <>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={() => setOtherLeads([])}
            >
              <i className='material-icons'>close</i>
            </IconButton>
          </>
        }
      />
    </>
  )
}

export default LeadCreate

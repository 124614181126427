import { GET, POST } from 'api'

export const fetchDiscounts = () =>
  GET({
    url: 'admin/discounts/'
  })

export const makeCalculatedDiscount = (data = {}) =>
  POST({
    url: 'admin/discounts/calculate-discount',
    data
  })

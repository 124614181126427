//@ts-check
import React, { useMemo } from 'react'
import { CardHeader, Grid } from '@material-ui/core'
import SubjectCard from './SubjectCard'
import SearchInput from '../../../../elements/SearchInput/SearchInput'
import { SubjectLevels } from '../../../../shared/constants'

function SubjectList({
  search,
  title,
  onChangeSearch,
  availableSubjects,
  selectedSubjectIds,
  onSelectSubject,
  subjectsLimitTotal = 0
}) {
  const orderedSubjects = useMemo(() => {
    const selectedSubjects = []
    const rest = []
    availableSubjects.map(subject => {
      if (selectedSubjectIds.includes(subject.id))
        return selectedSubjects.push(subject)
      return rest.push(subject)
    })
    return selectedSubjects.concat(...rest)
  }, [availableSubjects, selectedSubjectIds])
  return (
    <>
      <CardHeader
        title={title}
        subheader={
          <>
            <p>
              Según la configuración elegida estas son las asignaturas
              disponibles, indique las de su interés
            </p>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr 1fr'
              }}
            >
              <SearchInput value={search} onChange={onChangeSearch} />
              <div />
              {subjectsLimitTotal > 0 && (
                <p>
                  Selecccionadas {selectedSubjectIds.length}/
                  {subjectsLimitTotal}
                </p>
              )}
            </div>
          </>
        }
      />
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{
          width: '95%',
          margin: '0 auto',
          cursor: 'pointer'
        }}
        spacing={2}
      >
        {orderedSubjects.map(subject => (
          <SubjectCard
            key={subject.id}
            title={
              subject.level === SubjectLevels.IDIOMAS && subject.sublevel
                ? `${subject.name} (${subject.sublevel})`
                : subject.name
            }
            isSelected={selectedSubjectIds.includes(subject.id)}
            subheader={`${subject.type} (${subject.level})`}
            onSelectSubject={() => onSelectSubject(subject.id)}
          />
        ))}
      </Grid>
    </>
  )
}

export default SubjectList

//@ts-check
import React, { useMemo } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import { isEmpty } from 'ramda'
import { SubscriptionConfigTypes } from '../../../shared/constants'
import { useSubscriptionManager } from './context/SubscriptionManagerProvider'
import './ResumeInfo.css'

const WEEKS = 4
const OPEN_DAYS = 6

const CommonResumeView = ({
  subTitle = 'Tipo tarifa',
  subscriptionTitle = 'Tipo de suscripción',
  subscriptionName,
  subjectsLimitTitle = 'Límite de asignaturas',
  subjectsLimit,
  daysToConsumeTitle = 'Días disfrute',
  daysToConsume,
  hourLimitTitle = 'Límite de horas',
  hoursLimit,
  hourPriceTitle = 'Precio/hora',
  hourPrice,
  totalPriceTitle = 'Precio',
  totalPrice,
  selectedSubjectsTitle = 'Asignaturas de interés',
  selectedSubjects
}) => {
  return (
    <>
      <CardHeader title='Resumen' subheader={subTitle} />
      <CardContent>
        <Grid
          container
          spacing={2}
          justifyContent='center'
          className='main_grid_container'
        >
          <Grid item xs={4} className='grid_item'>
            <h4>{subscriptionTitle}</h4> <span>{subscriptionName}</span>
          </Grid>
          <Grid item xs={4} className='grid_item'>
            <h4>{subjectsLimitTitle}</h4>
            <span>{subjectsLimit}</span>
          </Grid>
          <Grid item xs={4} className='grid_item'>
            <h4>{daysToConsumeTitle}</h4> <span>{daysToConsume}</span>
          </Grid>
          <Grid item xs={4} className='grid_item'>
            <h4>{hourLimitTitle}</h4> <span>{hoursLimit}</span>
          </Grid>
          <Grid item xs={4} className='grid_item'>
            <h4>{hourPriceTitle}</h4> <span>{hourPrice}</span>
          </Grid>
          <Grid item xs={4} className='grid_item'>
            <h4>{totalPriceTitle}</h4> <strong>{totalPrice}€</strong>
          </Grid>
          <Grid item xs={12} className='grid_item'>
            <h4>{selectedSubjectsTitle}</h4> <span>{selectedSubjects}</span>
          </Grid>
        </Grid>
      </CardContent>
    </>
  )
}

function ResumeInfo({ useCard = true }) {
  const {
    subjects,
    selectedConfig,
    selectedSubjectIds,
    selectedSupplementIds,
    selectedSubscriptionConfigRow,
    calculateTotalPrice
  } = useSubscriptionManager()
  const selectedSubjects = useMemo(() => {
    return subjects
      .reduce((acc, { id, name }) => {
        if (
          selectedSubjectIds.includes(id) ||
          selectedSupplementIds.includes(id)
        )
          acc.push(name)
        return acc
      }, [])
      .join(', ')
  }, [selectedSubjectIds, selectedSupplementIds, subjects])

  const children = useMemo(() => {
    if (!selectedSubscriptionConfigRow || !selectedSubjectIds.length)
      return (
        <CardHeader
          title='Ayuda'
          subheader='Seleccione una suscripción y asignaturas'
        />
      )
    switch (selectedConfig.type) {
      case SubscriptionConfigTypes.extraHours: {
        const targetSubject = subjects.find(
          ({ id }) => id === selectedSubjectIds[0]
        )
        return (
          <CommonResumeView
            subTitle='Tipo pack'
            subscriptionName={selectedConfig?.name}
            subjectsLimit={selectedSubscriptionConfigRow.subjectsLimit}
            daysToConsume={selectedSubscriptionConfigRow.daysLimit}
            hoursLimit={selectedSubscriptionConfigRow.hours}
            hourPrice={targetSubject?.price}
            totalPrice={
              targetSubject?.price * selectedSubscriptionConfigRow.hours
            }
            selectedSubjects={selectedSubjects}
          />
        )
      }
      case SubscriptionConfigTypes.pack:
        return (
          <CommonResumeView
            subTitle='Tipo pack'
            subscriptionName={selectedConfig?.name}
            subjectsLimit={selectedSubscriptionConfigRow.subjectsLimit}
            daysToConsume={selectedSubscriptionConfigRow.daysLimit}
            hoursLimit={selectedSubscriptionConfigRow.hours}
            hourPrice={selectedSubscriptionConfigRow.hourPrice}
            totalPrice={
              selectedSubscriptionConfigRow.uniqPrice ??
              selectedSubscriptionConfigRow.calculatedPrice
            }
            selectedSubjects={selectedSubjects}
          />
        )
      case SubscriptionConfigTypes.prepareMornings: {
        const hasSupplements = !isEmpty(selectedSupplementIds)
        const hourPrice = (
          calculateTotalPrice() /
          (selectedSubscriptionConfigRow.hoursLimitByWeek * WEEKS)
        ).toFixed(2)
        return (
          <>
            <CommonResumeView
              subscriptionName={selectedConfig?.name}
              subjectsLimit={
                hasSupplements
                  ? selectedSubscriptionConfigRow.subjectsLimit +
                    selectedSupplementIds.length
                  : selectedSubscriptionConfigRow.subjectsLimit
              }
              daysToConsume='Mensual'
              hourLimitTitle='Límite de horas/semana'
              hoursLimit={selectedSubscriptionConfigRow.hoursLimitByWeek}
              hourPriceTitle='Precio/hora aprox.'
              hourPrice={`${hourPrice}€`}
              totalPriceTitle={
                hasSupplements ? 'Precio con suplementos' : 'Precio'
              }
              totalPrice={calculateTotalPrice()}
              selectedSubjects={selectedSubjects}
            />
          </>
        )
      }
      case SubscriptionConfigTypes.unlimited:
        return (
          <CommonResumeView
            subscriptionName={
              selectedSubscriptionConfigRow &&
              `${selectedConfig?.name} (${selectedSubscriptionConfigRow.code})`
            }
            subjectsLimit='Sin límite'
            daysToConsume='Mensual'
            hourLimitTitle='Límite de horas/día'
            hoursLimit={selectedSubscriptionConfigRow.hoursLimitByDay}
            hourPriceTitle='Precio/hora aprox.'
            hourPrice={`${(
              selectedSubscriptionConfigRow.uniqPrice /
              (selectedSubscriptionConfigRow.hoursLimitByDay *
                OPEN_DAYS *
                WEEKS)
            ).toFixed(2)}
              €`}
            totalPrice={calculateTotalPrice()}
            selectedSubjects={selectedSubjects}
          />
        )
      case SubscriptionConfigTypes.reinforces:
        return (
          <CommonResumeView
            subscriptionName={selectedConfig?.name}
            subjectsLimit={selectedSubscriptionConfigRow?.subjectRestrictions.map(
              (r, i) => (
                <p key={i} style={{ width: '100%' }}>
                  {r.subjectsLimit}{' '}
                  {r.subjectsLimit === 1 ? 'asignatura ' : 'asignaturas '}
                  de {r.type}
                </p>
              )
            )}
            daysToConsume='Mensual'
            hourLimitTitle='Límite de horas/semana'
            hoursLimit={selectedSubscriptionConfigRow?.subjectRestrictions.map(
              (r, i) => (
                <p key={i} style={{ width: '100%' }}>
                  {r.hoursLimitByWeek}{' '}
                  {r.hoursLimitByWeek === 1 ? 'hora ' : 'horas '}
                  de {r.type}
                </p>
              )
            )}
            hourPriceTitle='Precio/hora aprox.'
            hourPrice={`${(
              selectedSubscriptionConfigRow?.uniqPrice /
              (selectedSubscriptionConfigRow?.subjectRestrictions.reduce(
                (acc, { hoursLimitByWeek }) => {
                  acc += hoursLimitByWeek
                  return acc
                },
                0
              ) *
                WEEKS)
            ).toFixed(2)}
              €`}
            totalPrice={calculateTotalPrice()}
            selectedSubjects={selectedSubjects}
          />
        )
      case SubscriptionConfigTypes.agora:
        return (
          <CommonResumeView
            subscriptionName={selectedConfig?.name}
            subjectsLimit='-'
            daysToConsume='-'
            hoursLimit='-'
            hourPrice='-'
            totalPrice={calculateTotalPrice()}
            selectedSubjects={selectedSubjects}
          />
        )
      default: {
        console.warn('Unknown type:', selectedConfig.type)
        return null
      }
    }
  }, [
    calculateTotalPrice,
    selectedConfig,
    selectedSubjectIds,
    selectedSubjects,
    selectedSubscriptionConfigRow,
    selectedSupplementIds,
    subjects
  ])
  return useCard ? <Card>{children}</Card> : children
}

export default ResumeInfo

//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { generateStudentFromLead } from 'api/students'
import { findBudgets } from 'api/budgets'
import { textFilter } from 'utils/table'
import InformationTable from '../../../components/InformationTable/InformationTable'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import SearchInput from '../../../elements/SearchInput/SearchInput'
import ConfirmModal from '../../../shared/ConfirmModal'
import StatusIcon from '../../../shared/StatusIcon'
import styles from './BudgetList.module.css'

const UserType = {
  lead: 'Lead',
  student: 'Alumno'
}
const LEAD = 'lead'
function BudgetList() {
  const { setErrorMessage, setSuccessMessage, setWarningMessage } =
    useMessageSnackbarActions()
  const [budgets, setBudgets] = useState([])
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const history = useHistory()

  const filterSearch = budget => {
    return textFilter({
      object: budget,
      fields: ['userName', 'phone', 'email'],
      search: search
    })
  }

  const selectedBudget = useMemo(() => {
    return budgets.find(budget => budget.id === selectedId)
  }, [budgets, selectedId])
  const fetchBudgets = useCallback(() => {
    findBudgets()
      .then(setBudgets)
      .catch(e => {
        console.error('Error fectching budgets: ', e)
        setErrorMessage('No se puede mostrar el listado')
      })
  }, [setErrorMessage])
  const handleShowModal = useCallback(({ show, id }) => {
    setShowModal(show)
    setSelectedId(id)
  }, [])
  const handleConfirmUpdateBudget = useCallback(async () => {
    try {
      setIsRedirecting(true)
      const isLead = selectedBudget.userType === LEAD

      if (isLead) {
        const { alreadyExists } = await generateStudentFromLead({
          leadId: selectedBudget.userId
        })
        if (alreadyExists)
          setWarningMessage('Ya existe un alumno con los datos del lead')
        else setSuccessMessage('Abierta ficha del alumno correctamente')
      }
      history.push('/budgets/pay/' + selectedId)
    } catch (error) {
      console.error('Error redirecting:', error)
      setErrorMessage(error.message)
    } finally {
      setIsRedirecting(false)
    }
  }, [
    history,
    selectedBudget,
    selectedId,
    setErrorMessage,
    setWarningMessage,
    setSuccessMessage
  ])

  useEffect(() => {
    fetchBudgets()
  }, [fetchBudgets])
  return (
    <>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <Link to='/budgets/create'>
          <LoadingButton
            label='Nuevo presupuesto'
            startIcon={<i className='material-icons'>add</i>}
          />
        </Link>
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          { title: 'Nombre', key: 'userName', align: 'left' },
          { title: 'Info', key: 'additionalInfo' },
          {
            title: 'Creación',
            key: 'createdAt'
          },
          { title: 'Contratación', key: 'subscriptionName' },
          { title: 'Total', key: 'totalPrice' },
          {
            title: 'Aceptado',
            key: 'accepted'
          },
          {
            title: 'Cobrar',
            key: 'charge'
          },
          {
            title: 'Detalles',
            key: 'viewDetails'
          }
        ]}
        data={budgets.filter(filterSearch).map(budget => ({
          userName: (
            <Link to={`/${getPartialUrl(budget)}/${budget.userId}`}>
              {budget.userName}
            </Link>
          ),
          createdAt: new Date(budget.createdAt).toLocaleDateString(),
          additionalInfo: (
            <div>
              <p>{UserType[budget.userType]}</p>
              <p>{budget.phone}</p>
              <p>{budget.email}</p>
            </div>
          ),
          subscriptionName: budget.subscriptionConfig?.name || '??',
          totalPrice: (budget.totalPrice || 0) + '€',
          accepted: (
            <StatusIcon
              active={budget.accepted}
              positiveText='Sí'
              negativeText='No'
            />
          ),
          charge: (
            <i
              title='Ver'
              className='material-icons'
              style={{ cursor: !budget.accepted ? 'pointer' : 'not-allowed' }}
              onClick={() =>
                !budget.accepted &&
                handleShowModal({ show: true, id: budget.id })
              }
            >
              credit_card
            </i>
          ),
          viewDetails: (
            <Link to={`/budgets/${budget.id}`}>
              <i title='Ver' className='material-icons'>
                remove_red_eye
              </i>
            </Link>
          )
        }))}
      />

      {showModal && (
        <ConfirmModal
          title='¿Desea proceder al cobro del presupuesto?'
          subtitle={getSubtitle(selectedBudget)}
          onOk={handleConfirmUpdateBudget}
          onCancel={() => handleShowModal({ show: false, id: null })}
          isLoading={isRedirecting}
          showModal
        />
      )}
    </>
  )
}

export default BudgetList

function getSubtitle(budget) {
  return budget.userType === LEAD
    ? 'Se le redirigirá a la página de cobro y se creará la ficha del alumno si no existiese'
    : 'Se le redirigirá a la página de cobro al alumno'
}
function getPartialUrl(budget) {
  return budget.userType === LEAD ? 'leads' : 'students'
}

//@ts-check
import React, { useMemo } from 'react'
import PrintContainer from './PrintContainer'
import { useSubscriptionManager } from './context/SubscriptionManagerProvider'
import { SubscriptionConfigTypes } from '../../../shared/constants'
import ResumeInfo from './ResumeInfo'

function BudgetPrint({ userName = null }) {
  const {
    refPrint,
    selectedSubscriptionConfigRow,
    selectedSubjectIds,
    selectedConfig
  } = useSubscriptionManager()
  const footer = useMemo(() => {
    if (!selectedSubscriptionConfigRow || !selectedSubjectIds.length)
      return null

    switch (selectedConfig.type) {
      case SubscriptionConfigTypes.extraHours:
      case SubscriptionConfigTypes.pack:
        return (
          <p>
            * Las horas de clase consumidas fuera de un pack contratado serán
            abonadas a precio de <strong>clase extra</strong> de la asignatura
            solicitada.
          </p>
        )
      case SubscriptionConfigTypes.prepareMornings:
      case SubscriptionConfigTypes.unlimited:
      case SubscriptionConfigTypes.reinforces:
        return (
          <p>
            * Promoción personal e intransferible no acumulable a otras ofertas
            en curso.
          </p>
        )
      default: {
        console.warn('Unknown type:', selectedConfig.type)
        return null
      }
    }
  }, [selectedConfig, selectedSubjectIds, selectedSubscriptionConfigRow])
  return (
    <div style={{ display: 'none' }}>
      <PrintContainer
        userName={userName}
        // @ts-ignore
        ref={refPrint}
        children={<ResumeInfo useCard={false} />}
        footer={footer}
      />
    </div>
  )
}

export default BudgetPrint

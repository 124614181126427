import React from 'react'
import LateralImage from '../../../shared/resources/lateral.png'
import MainLogo from '../../../shared/resources/classfyVertical.png'
import './PrintContainer.css'

class PrintContainer extends React.Component {
  render() {
    const { children, footer = null, userName = '' } = this.props
    return (
      <div className='b-print-container'>
        <img src={LateralImage} alt='lateral' className='b-lateral_image' />
        <div className='b-main_content'>
          <div className='b-header_content'>
            <h1 className='b-main_title'>HOLA!</h1>
            <img src={MainLogo} alt='main logo' className='b-main_logo' />
          </div>
          <div>
            {userName && <h2>{userName}</h2>}
            <p>
              Estamos encantados de que hayas confiado en{' '}
              <strong>Classfy</strong> para que te ayudemos con tus estudios,
              estamos seguros de que vas a notarlo en tus resultados desde el
              primer momento.
            </p>
            <p>
              Tanto el equipo de profesores, asi como el personal de
              administración de <strong>Classfy</strong> estamos a tu
              disposición para solucionarte cualquier duda o inquietud.
            </p>
            <p>¡No dudes en contactarnos si tienes cualquier duda!</p>
            <p>A continuación te mostramos tu selección:</p>
          </div>
          {children}
          <div className='b-footer'>
            {footer}
            <p>
              * El precio indicado no incluye la
              <strong> matricula de 45€</strong> que se abona el primer mes.
            </p>
            <p>
              * Este presupuesto se ha emitido el{' '}
              {new Date().toLocaleDateString()} y tiene una validez de 7 días.
            </p>
            <p>
              SERVICIOS ESTUDIANTILES INTEGRALES S.L. con domicilio fiscal en
              Avenida de Bonn 12 Bajo 4. 28028 (MADRID)
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default PrintContainer
